
import useFormContext from "../hooks/useFormContext"

const Form = () => {

    const {
        page,
        setPage,
        data,
        title,
        canSubmit,
        disablePrev,
        disableNext,
        prevHide,
        nextHide,
        submitHide
    } = useFormContext()
    const handlePrev = () => setPage(prev => prev - 1)
    const handleNext = () => setPage(prev => prev + 1)
    const handleSubmit = e => {
        e.preventDefault()
        console.log(JSON.stringify(data))
    }
    const content = (
        <div>
        {
            page === 1 ? (
                <div class="form-buttons">
                <button type="button" className="next-btn"
                    onClick={handlePrev} disabled={disablePrev}>Prev</button>
                    <button type="submit" className="next-btn"
                    disabled={disablePrev}>Submit</button>
            </div>
       
                    ) : (
                        <div class="form-buttons">
                        <button type="button" className="next-btn"
                            onClick={handlePrev} disabled={disablePrev}>Prev</button>
                            <button  className="next-btn"
                            onClick={handleNext}>Next</button>
                            <button  type="submit" className="next-btn"
                            onClick={handleNext} disabled={!canSubmit}>Submit</button>
                    </div>
                      )
                    }
        </div>          
    )

    return content
}
export default Form