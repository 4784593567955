import React,{ useEffect, useState,useMemo } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import { getDistance, getPreciseDistance,geolib,findNearest,getAreaOfPolygon } from 'geolib';
import Parser from 'html-react-parser';
import {useAuthValue} from './AuthContext';
import { FaStar } from "react-icons/fa";
import { useDispatch,useSelector } from 'react-redux';
import{currentlatitude,currentlongitude} from '../../features/counter/apiSlice';
export const Medicose = (props) => { 
  const [medical, setMedical] = useState([]); 
  const lt = useSelector(currentlatitude);
  const lng = useSelector(currentlongitude); 
  const url1 = async () => { 
    if(lt !='' && lng !='')
    {
   await fetch(`${process.env.REACT_APP_BASE_URL}/neary_by/${lt}/${lng}`)
  .then(function (response) {    
    if (!response.ok) {
        return Promise.reject('some reason');
    }

    return response.json();

})
  .then(res => {
    var places = [];
     //console.log(res.data.results);
    for (let googlePlace of res.data.results) {
      var place = {};
      
      var myLat = googlePlace.geometry.location.lat;
      var myLong = googlePlace.geometry.location.lng;
      var coordinate = {
        latitude: myLat,
        longitude: myLong,
      };               
      place['placeTypes'] = googlePlace.types;
      place['link'] = (googlePlace.photos !='' && googlePlace.photos !=undefined && googlePlace.photos !=null) ? googlePlace.photos[0].html_attributions :'';
      place['vicinity'] = googlePlace.vicinity;
      place['open_now'] = (googlePlace.opening_hours !='' && googlePlace.opening_hours !=undefined && googlePlace.opening_hours !=null && googlePlace.opening_hours.open_now == true) ? 'Open':'Closed';
      place['coordinate'] = coordinate;
      place['placeId'] = googlePlace.place_id;
      place['placeName'] = googlePlace.name;
      place['icon'] = googlePlace.icon;
      place['rating'] = googlePlace.rating;
      place['reviews'] = googlePlace.reviews;
      place['number'] = googlePlace.number;
      place['website'] = googlePlace.website;
      place['business_status'] = googlePlace.business_status;
      place['lat'] = myLat;
      place['long'] = myLong;
      //console.log(place);
      places.push(place);
      //console.log(places);
     
    }
    //console.log(places);
    // Show all the places around 4 km from San Francisco.
    setMedical(places);    
  })
  .catch(error => { 
    console.log(error);
  });
}
}  
useEffect
(() => {  
  url1();    
    //fetchData2();
}, [lt,lng])
    const openLinkInNewTab = ( url ) => {
      const newTab = window.open(url, '_blank', 'noopener,noreferrer');
      if ( newTab ) newTab.opener = null;
    }
    const [medical1, setMedical1] = useState([medical]); 
  return (
    <div>
	<Header />  
  <div className="main-about-us">
  <div className="banner-image">
    <img className="banner-img" src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/inner-banner.webp"} alt="" />
  </div>
  <div className="container after-banner">
    <div className="after-banner-path">
      <span className="active-page">Home</span>
      <span>/</span>
      <span>About Us</span>
    </div>
  </div>
  </div>
  <section className="clinic-listing">
  <div className="container">
    <div className="row">
      {typeof medical != 'undefined' && medical.length > 0 && medical && (
						<>
						{medical.map((item) => {
				    let dis=getDistance(               
              { latitude: item.lat, longitude: item.long },
              { latitude: lt, longitude: lng }
                 );	
                 const background=item.background;
                 var url = "https://www.google.com/maps/dir/?api=1";
var origin = "&origin=" + lt + "," + lng;
var destination = "&destination=" + item.lat + "," + item.long;
var newUrl = new URL(url + origin + destination);
let ratings=item.rating;
//var photo_url = "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference="+item.photo_reference+"&key=AIzaSyC0JhCZR3ilmCXiacjW4ME1Nd72lW76D_g";
//console.log(photo_url);
				   return (
      <div className="col-md-10 m-auto" key={item.placeId}>
        <div className="clinic-list-box">
          <div className="clinic-image-box">
            <img src={item.icon} alt="imge" />
          </div>
          <div className="clinic-content">
            <h3>{Parser(item.placeName)}</h3>
            <p><i className="fa fa-map-marker">{item.vicinity}</i></p>
            <div dangerouslySetInnerHTML={{__html: item.link}} />
            <p>{item.number}</p>
            <p>{item.business_status}</p>
            <p>{item.open_now}</p>
            <p>{item.website}</p>
            {[...Array(5)].map((star, i) => {
   const ratingValue = i + 1;                 
  return (                
    <FaStar
className="star"
color={(i < item.rating) ? "gold" : "lightgray"}
size={20} key={i}             
/>           
          );
      })}
            <p>{item.reviews}</p>
            <p><i className="fa fa-map-marker">{`Distance\n\n${dis} Meter\nOR\n${dis / 1000} KM`}</i></p>
            <button onClick={ () => openLinkInNewTab(newUrl)} className="cllinic-btn ">
              Direction
            </button>
          </div>
        </div>
      </div>
      );
    })}
      </>
      )}
    </div>
  </div>
</section>

	   <Footer />
    </div>
  );
};
   