import React,{ useState } from "react";
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import ReactWhatsapp from 'react-whatsapp';
import { Button} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
export const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [comments, setComments] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState(""); 
  let handleSubmit1 = async (e) => {
    //e.preventDefault();
    try {
      let res = await fetch(`${process.env.REACT_APP_BASE_URL}/contact_us`, {
        method: "POST",
        body: JSON.stringify({
          contact_name: name,
          contact_email: email,
          contact_subject: subject,
          contact_comments: comments,
          contact_phone: phone,
          contact_type: 'Contact'
        }),
      });
      let resJson = await res.json();
      //console.log(resJson);
      if (resJson.status === 1) {
        setName("");
        setEmail("");
        setSubject("");
        setComments("");
        setPhone("");
        setMessage(resJson.message);
      } else {
        setMessage(resJson.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  return (
    <div>
      <Header />
      <div className="main-body main-contact">
  <div className="banner-image contact-banner">
    <img className="banner-img" src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/contact us.webp"} alt="" />
  </div>
  <section className="container-fluid container-xl margin_top">
    <div className="row justify-content-center align-items-center common_padding">
      <div className="col-12 col-lg-6 pe-0 pe-lg-3">
        <div className="contact_main_control">
          <div className="row">
            <div className="col-12 mb-1 mb-sm-3">
              <p className="footer_title contact_title2">Contact Us</p>
              <h3 className="common_title2">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Quidem?
              </h3>
              <p className="mt-2 font_15 d-none d-sm-block">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
                est accusamus aperiam nulla expedita iste illo eum consequuntur!
                Quibusdam, consequuntur?
              </p>
            </div>
          </div>
          <div className="row form_holder_main">
            <ReactWhatsapp className="whatsup_contact" number="8115876145" message="Hello World!!!"><img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/124034.png"} style={{ height: 40, width: 40 }}/>Whatsapp Enquiry</ReactWhatsapp>
            <form onSubmit={handleSubmit((data) => handleSubmit1(data))}>
              <div className="row">
            <div className="col-md-6">
              <div className="form_main_c">
                <div className="form_holder_c">
                  <label htmlFor="">Name</label>
                  <input className="class_all_input" name="name" value={name} type="text" id="name" placeholder="Your Name" {...register('name', { required: true })} onChange={(e) => setName(e.target.value)}  />
                  <span style={{ color: 'red' }}>{errors.name && 'Name is required.'}</span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_main_c">
                <div className="form_holder_c">
                  <label htmlFor="">Email</label>
                  <input className="class_all_input" name="email" value={email} type="email" id="email" placeholder="Email Address" {...register('email', { required: true })} onChange={(e) => setEmail(e.target.value)} />
                  <span style={{ color: 'red' }}>{errors.email && 'Email is required.'}</span>
                </div>
              </div>
            </div>
            </div>
            <div className="col-md-12 mt-2 mt-sm-3">
              <div className="form_main_c">
                <div className="form_holder_c">
                  <label htmlFor="">Number</label>
                  <input className="class_all_input" name="phone" value={phone} type="number" {...register('phone', { required: true })} id="phone" placeholder="Your Phone Number" onChange={(e) => setPhone(e.target.value)}  />
                  <span style={{ color: 'red' }}>{errors.phone && 'Phone is required.'}</span>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-2 mt-sm-3">
              <div className="form_main_c">
                <label htmlFor="">Subject</label>
                <input className="class_all_input" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} type="text" id="subject" placeholder="Subject" />
              </div>
            </div>
            <div className="col-md-12 mt-2 mt-sm-3">
              <div className="form_main_c">
                <label htmlFor="">Comments</label>
                <textarea className="class_all_input" name="comments" value={comments} onChange={(e) => setComments(e.target.value)} cols="40" rows="5" id="comments" placeholder="Message"></textarea>
              </div>
            </div>
            <div className="col-md-12 mt-3 mt-sm-4">
              <div className="sub_cont">
                <button type="submit" className="sub_btn">Submit Now</button>
              </div>
            </div>
            {message.length > 0 ?
              <div className="message contact-address-headline success">{message ? <p>{message}</p> : null}</div>
               :''}
          </form>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-5 ps-0 ps-lg-3 mt-3 mt-lg-0">
        <div className="contact_img flex_prop justify-content-center">
          <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/contact-img.png"} />
        </div>
        <div className="mt-4">
          <h3 className="common_title2">Connect Us Through</h3>
          <ul className="connect_ul">
            <li>
              <div className="contact_img2">
                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/email-icon.png"} />
              </div>
              <div className="contact_content">
                <p className="contact_title">Email Us</p>
                <p>info@booklabonline.com</p>
              </div>
            </li>
            <li>
              <div className="contact_img2">
                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/phone-call.png"} />
              </div>
              <div className="contact_content">
                <p className="contact_title">Phone Number</p>
                <p>+1-xxxx-xxx-xxx</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</div>
    <Footer />
    </div>
  );
};
