import React, { useState,useContext,useEffect } from "react";
import "bootstrap/js/src/collapse.js";
import { NavLink,useNavigate,Link } from "react-router-dom";
import { signOut } from 'firebase/auth' 
import { CartContext } from '../../context/cart';
import { auth } from './firebase';
import { useDispatch,useSelector } from 'react-redux';
import {currentcity} from '../../features/counter/apiSlice';
export const Header = () => {
const [click, setClick] = useState(false);
const navigate = useNavigate(); 
const token = localStorage.getItem('user-info-login');
const { cartItems,quantity,total1} = useContext(CartContext);
const city=useSelector(currentcity);
const logOut = async () => {
    await auth.signOut().then(console.log("signed out."));
    localStorage.clear('user-info-login');
    navigate('/');
}
  const handleClick = () => setClick(!click);  
  const logout = (e) => {
    e.preventDefault();
    //console.log('Logout');

    // CLEAR DATA FROM STORAGE
    localStorage.clear();
    sessionStorage.clear();

    navigate("/");
}
const navLinkStyles = ({isActive}) => {
    return {        
        color: isActive ? "red" : "white"        
    };
};
useEffect(() => {
  const headerLoad = () => {
  if(document.querySelector(".main-body") !== null)
    {
    document.querySelector(".main-body").style.paddingTop =
  document.querySelector("header").offsetHeight + "px";
    }
  };
  const handleLoad = () => {

    const counters = document.querySelectorAll(".count"); 
    const speed = 200; // Adjust the speed here
  
    counters.forEach((counter) => {
      const target = +counter.getAttribute("data-target");
      let count = 0;
  
      const updateCount = () => {
        const increment = target / speed;
  
        if (count < target) {
          count += increment;
          counter.innerText = formatCount(count);
          setTimeout(updateCount, 1);
        } else {
          counter.innerText = formatCount(target);
        }
      };
  
      updateCount();
    });
   
    function formatCount(num) {
      if (num >= 1000) {
        return (num / 1000).toFixed(1) + "K";
      }
      return Math.floor(num);
    }
  
  };

  if (document.readyState === 'complete') {
    handleLoad();
    headerLoad();
  } else {
    window.addEventListener('load', handleLoad);
    window.addEventListener('load', headerLoad);
  }

  return () => {
    window.removeEventListener('load', handleLoad);
  };
}, []);
useEffect(() => {
const menu_btn = document.querySelector(".menu_btn");
const nav_cust = document.querySelector(".nav_cust");
const close_btn = document.querySelector(".close_btn");

menu_btn.addEventListener("click", () => {
nav_cust.classList.add("nav_cust_active");
});
close_btn.addEventListener("click", () => {
nav_cust.classList.remove("nav_cust_active");
});
window.addEventListener('scroll', function() {
var t = window.scrollY;
if (t > 60) {
 document.querySelector('header').classList.add('sticky-header');
 // document.querySelector('.svg-big').style.display = 'block';
 // document.querySelector('.svg-small').style.display = 'none';

} else {
 document.querySelector('header').classList.remove('sticky-header');
 // document.querySelector('.svg-big').style.display = 'none';
 // document.querySelector('.svg-small').style.display = 'block';

}
}); 
document.addEventListener('scroll', function() {
const footer = document.querySelector('footer');
const hideElement = document.querySelector('header');
const footerRect = footer.getBoundingClientRect();
const windowHeight = window.innerHeight;

if (footerRect.top <= windowHeight && footerRect.bottom >= 0) {
 hideElement.style.display = 'none';
} else {
 hideElement.style.display = 'block';
}
});
}, []);
//console.log(auth.currentUser.email);
return(
    <header>
    <div className="sub_header_main py-1 white main-index">
      <div className="container-fluid container-xl common_padding">
        <div className="flex_prop justify-content-between">
          <div className="logo_img">
            <div className="logo-inner" />
          </div>
          <div className="sub_side flex_prop gap-3">
            <div className="flex_prop gap-3 sub_res_none">
              <a className="sub_cont">
                <div className="sub_img">
                  <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/phone-call-icon.png"} />
                </div>
                <div>
                  <p className="sub_title">
                    <b>Email:</b>demo@gmail.com
                  </p>
                </div>
              </a>
              <a className="sub_cont">
                <div className="sub_img">
                  <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/phone-call-icon.png"} />
                </div>
                <div>
                  <p className="sub_title">
                    <b>Phone:</b>99-99-9999
                  </p>
                </div>
              </a>
            </div>
            
            <div className="sub_cont2  flex_prop gap-3">
            {
          total1 > 0 ? (
                    <li><Link className='px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700' exact='true' to="/cart"><i className="fa fa-shopping-cart">Cart ({total1})</i></Link></li>
                    ) : (
                        ''
                      )
                    }
            {token && token!=null  ? (
                    <button onClick={logOut} className="login_btn">Logout</button>
                    ) : (                            
                            <Link exact='true' to="/login" onClick={handleClick}><button type="button" className="login_btn">Login</button></Link>    
                            )}
              <a href="#" className="login_btn none_res">
                Book An Appointment
              </a>
              <button className="menu_btn">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/menu-icon.png"} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav className="nav_cust container-fluid white py-2">
      <div className="container-fluid container-xl flex_prop justify-content-between common_padding nav_cust_main">
        <div className="mobile-burger-menu">
          <div className="sidebar-header">
            <svg
              className="user-icon"
              fill="#74757a"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              {/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z" />
            </svg>
            <p>Login/Signup</p>
          </div>
        </div>
        <div className="links_cust">
          <ul>
            <div className="close_btn">
              <svg
                fill="#fff"
                className="close-button-menu"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                {/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </div>
            <li>
                                        <NavLink  style={navLinkStyles} exact='true' to="/"  className="nav-link" onClick={handleClick}>Home</NavLink>
                                    </li>
                                    
                                    <li>
                                        <NavLink  style={navLinkStyles} exact='true' to="/hospital"  onClick={handleClick} className="nav-link">Hospitals</NavLink>
                                    </li>
                                    <li>
                                        <Link  exact='true' to="/lab"  onClick={handleClick} className="nav-link">Our Labs</Link>
                                    </li>
                                    <li>
                                        <Link  exact='true' to="/specialist"  onClick={handleClick} className="nav-link">Doctor</Link>
                                    </li>
                                    <li>
                                        <Link  exact='true' to="/contact"  onClick={handleClick} className="nav-link">Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link  exact='true' to="/team"  onClick={handleClick} className="nav-link">Clients</Link>
                                    </li>
									<li>
                                        <Link  exact='true' to="/about"  onClick={handleClick} className="nav-link">About Us</Link>
                                    </li>
									
									<li>
                                        <Link  exact='true' to="/blog"  onClick={handleClick} className="nav-link">Blog</Link>
                                    </li>
                                    <li>
                                        <Link  exact='true' to="/how-it-works"  onClick={handleClick} className="nav-link">How It Works</Link>
                                    </li>
                                    <li>
                                        <Link  exact='true' to="/partner"  onClick={handleClick} className="nav-link">Our Partners</Link>
                                    </li>
          </ul>
        </div>
        <div className="call_btn">
          <a href="#">
            <div className="call_img">
              <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/phone-call-icon-1.png"} />
            </div>
            <div className="call_cont">
              <p className="font_14">Call us now for Discounts</p>
              <p className="num_title">99-99-9999</p>
            </div>
          </a>
        </div>
      </div>
    </nav>
  </header>  
);

}

