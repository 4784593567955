import React,{ useEffect, useState } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch,useSelector } from 'react-redux';
import{register,signUp} from '../../features/counter/apiSlice';
import { booking,booking1 } from "../../helper/formdata";
export const Healthians = (props) => {  
  //console.log(new Date());
  const token = JSON.parse(localStorage.getItem('user-info-login'));  
  const dispatch=useDispatch();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [comments, setComments] = useState("");
    const [message, setMessage] = useState("");
    const [phone, setPhone] = useState(""); 
    const [password, setPassword] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [inputValue,setInputValue]=useState({name:'',email:(typeof token != 'undefined' && token != null) ? token.currentUser.email:'',phone:'',password:'',startDate:startDate,subject:'',comments:'',age:'',gender:'',time:'',reference_id:'3',register_id:(typeof token != 'undefined' && token != null ) ? token.currentUser.uid:'',booking_status:'booked',type:'healthians'})
   
    const handleInput=(e)=>
    {
      
      setInputValue({...inputValue,[e.target.name]:e.target.value});
      //console.log(inputValue);
    }
    const handleSubmit=async(e)=>
    {
      e.preventDefault();
      if(token === null)
        {
          dispatch(signUp(inputValue));
          
        }
        else
        {
          const wait = toast.loading("Please wait...");
          //console.log(inputValue);
          const booked=await booking1(
            inputValue
          );
          toast.update(wait, {
            render: `Welcome ${booked.message}`,
            type: "success",
            isLoading: false,
            autoClose: 10000,
            closeOnClick: true,
            pauseOnHover: true,
          }); 
          window.location.href = '/schedule'
      //dispatch(booking(inputValue))
        }
    }
    useEffect(() => {  
    props.ChangeType('healthians');
    props.ChangeId('3'); 
  }, []);
  return (
    <div>
	<Header /> 
  <ToastContainer /> 
  <div className="banner">
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(assets/img/banner_city.jpg)`,marginTop: `92px`
    }}>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>     
    </div>
   <br/>
   <main>
  <div className='about-inner'>
  <div className='container'>
  <div className='row'>
  <div className='col-md-12'>
   <div className="col-md-9 cntleft">
        <article id="post-35" className="post-35 post type-post status-publish format-standard has-post-thumbnail hentry category-services">
          <div className="post-thumbnail">
           	</div>
          <header className="entry-header"><h1 className="entry-title">Healthians</h1></header>
          <div className="entry-content">
            <p>BLOOD TEST: Full Body Checkup online with Free Home Sample Collection within 24-hours of booking & Free Report Counseling. HEALTH SCAN: Advanced, painless, affordable MRI, CT Scan, Ultrasound, X-Ray, PET Scan, ECG, DEXA Scan, ECHO, EEG, TMT and more.</p>
          </div>
        </article>
        <img width={400} height={210} src="https://www.vedantaairambulance.com/wp-content/uploads/2017/05/air-ambulance-1.jpg" className="attachment- img-responsive size- img-responsive wp-post-image" alt="" srcSet="https://www.vedantaairambulance.com/wp-content/uploads/2017/05/air-ambulance-1.jpg 400w, https://www.vedantaairambulance.com/wp-content/uploads/2017/05/air-ambulance-1-300x158.jpg 300w" sizes="(max-width: 400px) 100vw, 400px" />
      </div>      
      <div className="col-md-3 comment-bottom">       
      <form onSubmit={handleSubmit}>
      <input className="with-border" name="name" value={(token != null) ? token['name'] : inputValue.name} type="text" id="name" placeholder="Your Name" onChange={handleInput} required="required" />
      <input className="with-border" name="email" value={(typeof token != 'undefined' && token != null) ? token.currentUser.email:inputValue.email} type="email" id="email" placeholder="Email Address" onChange={handleInput} required="required" />
      <input className="with-border" name="phone"  value={(token != null) ? token['phone'] : inputValue.email} type="number" id="phone" placeholder="Your Phone Number" onChange={handleInput} required="required" /> 
      {(token == null) ?                                      
      <input type="password" name="password" placeholder="Password"  value={password} onChange={handleInput} required="required"/>
                                       
                                        : ''}   
      <div className="form-group">
      <DatePicker selected={startDate} minDate={startDate} value={startDate} onChange={(date) => setStartDate(date)} required="required"/>
      <i className="fa fa-calendar-alt"></i>
      </div>                                   
      <input className="with-border" name="subject" value={inputValue.subject} onChange={handleInput} type="text" id="subject" placeholder="Subject" required="required" />   
      <textarea className="with-border" name="comments" value={inputValue.comments} onChange={handleInput} cols="40" rows="5" id="comments" placeholder="Query"  required="required"></textarea>     
      <input type="submit" className="submit button margin-top-15" id="submit" value="Submit Message" />                
           
            {props.message.length > 0 ?
            <div className="message contact-address-headline success">{props.message ? <p>{props.message}</p> : null}</div>
             :''}
          </form> 
            </div>
        </div>
        </div>
        </div>
      </div>
   </main>
	   <Footer />
    </div>
  );
};
