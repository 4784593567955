import React,{ useEffect, useState,useContext,useMemo } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Product } from './Product';
import { Footer } from './Footer';
import axios from "axios";
import Parser from 'html-react-parser';
import Select from 'react-select';
import { CartContext } from '../../context/cart.js'
import {  useSelector,useDispatch } from "react-redux";
import {
  productlistt,productlisting1,productlist_status
} from "../../features/counter/productSlice";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
  export const ProductListing = (props) => { 
    const { cartItems, addToCart, removeFromCart, clearCart, getCartTotal,quantity,setQuantity} = useContext(CartContext)
    //console.log(productList);
    const dispatch=useDispatch();
    const productlist111 = useSelector(productlistt);
     const status = useSelector((state) => state.product.status);
    const error = useSelector((state) => state.product.error);
    const [suggestions, setSuggestions] = useState([]);
    const [productList, setProductList] = useState([]);
    const [inputValue, setInputValue] = useState('');
//console.log(productlist);
    useEffect(() => {
      if (inputValue) {
        const filteredSuggestions = productList.filter(product =>
          product.product_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setProductList(filteredSuggestions);
        //console.log(filteredSuggestions);
        setSuggestions(filteredSuggestions);
      } else {
        setSuggestions([]);
      }
    }, [inputValue]);
  
    const handleChange = (e) => {
      setInputValue(e.target.value);
    };
  
    const handleSuggestionClick = (suggestion) => {
      setInputValue(suggestion.product_name);
      setSuggestions([]);
    };
   
    useEffect(() => {

      if (status === 'idle') {
        dispatch(productlisting1());
      }
      if(status === 'succeeded')
        {
          setProductList(productlist111);
        }
    }, [status, dispatch]);
   
  //let product_item=this.props.product;
  return (
    <div>
	<Header />  
  <div className="main-body main-product-listing">
  <div>
    <div className="container-fluid container-xl">
      <div className="main-product-listing">
        {/* Sidebar (25%) */}
        <div className="mobile-filter">
  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li className="nav-item" role="presentation">
      <button
        className="nav-link active"
        id="pills-home-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-home"
        type="button"
        role="tab"
        aria-controls="pills-home"
        aria-selected="true"
      >
        Category
      </button>
    </li>
    <li className="nav-item" role="presentation">
      <button
        className="nav-link"
        id="pills-profile-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-profile"
        type="button"
        role="tab"
        aria-controls="pills-profile"
        aria-selected="false"
      >
        Brand
      </button>
    </li>
    <li className="nav-item" role="presentation">
      <button
        className="nav-link"
        id="pills-contact-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-contact"
        type="button"
        role="tab"
        aria-controls="pills-contact"
        aria-selected="false"
      >
        Price
      </button>
    </li>
    <li className="nav-item" role="presentation">
      <button
        className="nav-link"
        id="pills-sort-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-sort"
        type="button"
        role="tab"
        aria-controls="pills-sort"
        aria-selected="false"
      >
        {" "}
        Sort By
      </button>
    </li>
  </ul>
  <div className="tab-content" id="pills-tabContent">
    <div
      className="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div className=" side-dropdown">
        <label className="block mb-2 check-box">
          <input type="checkbox" className="mr-2" /> Category 1
        </label>
        <label className="block mb-2 check-box">
          <input type="checkbox" className="mr-2" /> Category 2
        </label>
        <label className="block mb-2 check-box">
          <input type="checkbox" className="mr-2" /> Category 3
        </label>
      </div>
    </div>
    <div
      className="tab-pane fade"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div className=" side-dropdown">
        <label className="block mb-2 check-box">
          <input type="checkbox" className="mr-2" /> Category 1
        </label>
        <label className="block mb-2 check-box">
          <input type="checkbox" className="mr-2" /> Category 2
        </label>
        <label className="block mb-2 check-box">
          <input type="checkbox" className="mr-2" /> Category 3
        </label>
      </div>
    </div>
    <div
      className="tab-pane fade"
      id="pills-contact"
      role="tabpanel"
      aria-labelledby="pills-contact-tab"
    >
      <div className=" side-dropdown">
        <label className="block mb-2 check-box">
          <input type="checkbox" className="mr-2" /> Category 1
        </label>
        <label className="block mb-2 check-box">
          <input type="checkbox" className="mr-2" /> Category 2
        </label>
        <label className="block mb-2 check-box">
          <input type="checkbox" className="mr-2" /> Category 3
        </label>
      </div>
    </div>
    <div
      className="tab-pane fade"
      id="pills-sort"
      role="tabpanel"
      aria-labelledby="pills-sort-tab"
    >
      <div className=" side-dropdown">
        <label className="block mb-2 check-box">
          <input type="checkbox" className="mr-2" /> Category 1
        </label>
        <label className="block mb-2 check-box">
          <input type="checkbox" className="mr-2" /> Category 2
        </label>
        <label className="block mb-2 check-box">
          <input type="checkbox" className="mr-2" /> Category 3
        </label>
      </div>
    </div>
  </div>
        </div>
        <div className="side-filter">
          <button className="close-btn">×</button>
          <div className="col-12">
            <div className="accordion accordion_cust" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button side-dropdown collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Category
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse  collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body side-dropdown">
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Category 1
                    </label>
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Category 2
                    </label>
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Category 3
                    </label>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button side-dropdown collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Brand
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body side-dropdown">
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Category 1
                    </label>
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Category 2
                    </label>
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Category 3
                    </label>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button side-dropdown collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Price
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body side-dropdown">
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Category 1
                    </label>
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Category 2
                    </label>
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Category 3
                    </label>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button side-dropdown collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo4"
                    aria-expanded="false"
                    aria-controls="collapseTwo4"
                  >
                    Sort By
                  </button>
                </h2>
                <div
                  id="collapseTwo4"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body side-dropdown">
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Price: Low to
                      High
                    </label>
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Price: High to
                      Low
                    </label>
                    <label className="block mb-2 check-box popularity">
                      <input type="checkbox" className="mr-2" /> Popularity
                    </label>
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Newest
                    </label>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header ">
                  <button
                    className="accordion-button  collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree5"
                    aria-expanded="false"
                    aria-controls="collapseThree5"
                  >
                    Accessories
                  </button>
                </h2>
                <div
                  id="collapseThree5"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body ">
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Category 1
                    </label>
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Category 2
                    </label>
                    <label className="block mb-2 check-box">
                      <input type="checkbox" className="mr-2" /> Category 3
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Products (75%) */}
        <div className="lg:w-3/4 w-full banner-listing">
          <div className="row">
            <div className="col-12 hero_section2_img relative">
              <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/listing-page-banner.jpg"}/>
            </div>
          </div>
        </div>
        <div className="filter-toggle ">
          <span className="filter-icon">
            <i className="fa-solid fa-filter" />
          </span>
        </div>
      </div>
    </div>
  </div>
  {/* Slider-Section */}
  <div className="container">
    <div className="col-12 mt-3 hospi-slider swiper">
      <Swiper
      modules={[Autoplay]}
      autoplay={{
        delay: 3000, // delay between transitions in ms
        disableOnInteraction: false, // autoplay will not be disabled after user interactions
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
    >
      <SwiperSlide key={1}>
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-4">
              <div className="card">
                <img
                  src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/apollo.webp"}
                  className="card-img-top"
                  alt="Product Image"
                />
                <div className="top-left-badges">
                  <div className="badge badge-discount">-20%</div>
                  <div className="badge badge-new">New</div>
                </div>
                <div className="card-body ">
                  <h5 className="card-title">Activated Charcoal Soap </h5>
                  <div className="rating mb-2">
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                  </div>
                  <p className="card-text">
                    Brand:
                    <span>Ayo Mart</span>
                  </p>
                  <p className="card-text-price">
                    <span className="price">₹207.48</span>
                    <span className="original-price">₹399.00</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        
        </SwiperSlide>
          <SwiperSlide key={2}>
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-4">
              <div className="card">
                <img
                  src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/apollo.webp"}
                  className="card-img-top"
                  alt="Product Image"
                />
                <div className="top-left-badges">
                  <div className="badge badge-discount">-20%</div>
                  <div className="badge badge-new">New</div>
                </div>
                <div className="card-body ">
                  <h5 className="card-title">Activated Charcoal Soap </h5>
                  <div className="rating mb-2">
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                  </div>
                  <p className="card-text">
                    Brand:
                    <span>Ayo Mart</span>
                  </p>
                  <p className="card-text-price">
                    <span className="price">₹207.48</span>
                    <span className="original-price">₹399.00</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        
        </SwiperSlide>
        <SwiperSlide key={3}>
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-4">
              <div className="card">
                <img
                  src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/apollo.webp"}
                  className="card-img-top"
                  alt="Product Image"
                />
                <div className="top-left-badges">
                  <div className="badge badge-discount">-20%</div>
                  <div className="badge badge-new">New</div>
                </div>
                <div className="card-body ">
                  <h5 className="card-title">Activated Charcoal Soap </h5>
                  <div className="rating mb-2">
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                  </div>
                  <p className="card-text">
                    Brand:
                    <span>Ayo Mart</span>
                  </p>
                  <p className="card-text-price">
                    <span className="price">₹207.48</span>
                    <span className="original-price">₹399.00</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        
        </SwiperSlide>
        <SwiperSlide key={4}>
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-4">
              <div className="card">
                <img
                  src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/apollo.webp"}
                  className="card-img-top"
                  alt="Product Image"
                />
                <div className="top-left-badges">
                  <div className="badge badge-discount">-20%</div>
                  <div className="badge badge-new">New</div>
                </div>
                <div className="card-body ">
                  <h5 className="card-title">Activated Charcoal Soap </h5>
                  <div className="rating mb-2">
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                  </div>
                  <p className="card-text">
                    Brand:
                    <span>Ayo Mart</span>
                  </p>
                  <p className="card-text-price">
                    <span className="price">₹207.48</span>
                    <span className="original-price">₹399.00</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        
        </SwiperSlide>
        <SwiperSlide key={5}>
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-4">
              <div className="card">
                <img
                  src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/apollo.webp"}
                  className="card-img-top"
                  alt="Product Image"
                />
                <div className="top-left-badges">
                  <div className="badge badge-discount">-20%</div>
                  <div className="badge badge-new">New</div>
                </div>
                <div className="card-body ">
                  <h5 className="card-title">Activated Charcoal Soap </h5>
                  <div className="rating mb-2">
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                  </div>
                  <p className="card-text">
                    Brand:
                    <span>Ayo Mart</span>
                  </p>
                  <p className="card-text-price">
                    <span className="price">₹207.48</span>
                    <span className="original-price">₹399.00</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
       
        </SwiperSlide>
        <SwiperSlide key={6}>
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-4">
              <div className="card">
                <img
                  src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/apollo.webp"}
                  className="card-img-top"
                  alt="Product Image"
                />
                <div className="top-left-badges">
                  <div className="badge badge-discount">-20%</div>
                  <div className="badge badge-new">New</div>
                </div>
                <div className="card-body ">
                  <h5 className="card-title">Activated Charcoal Soap </h5>
                  <div className="rating mb-2">
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                  </div>
                  <p className="card-text">
                    Brand:
                    <span>Ayo Mart</span>
                  </p>
                  <p className="card-text-price">
                    <span className="price">₹207.48</span>
                    <span className="original-price">₹399.00</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
       
        </SwiperSlide>
        <SwiperSlide key={7}>
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-4">
              <div className="card">
                <img
                  src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/apollo.webp"}
                  className="card-img-top"
                  alt="Product Image"
                />
                <div className="top-left-badges">
                  <div className="badge badge-discount">-20%</div>
                  <div className="badge badge-new">New</div>
                </div>
                <div className="card-body ">
                  <h5 className="card-title">Activated Charcoal Soap </h5>
                  <div className="rating mb-2">
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                    <span className="fa-regular fa-star checked" />
                  </div>
                  <p className="card-text">
                    Brand:
                    <span>Ayo Mart</span>
                  </p>
                  <p className="card-text-price">
                    <span className="price">₹207.48</span>
                    <span className="original-price">₹399.00</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
       
        </SwiperSlide>
        </Swiper>
      
    </div>
  </div>
  {/* Listing-Section */}
  <div className="container main-listing-section">
    <div className="grid ">
      {
        productList.map(product => {
          return <Product key={product.product_id} item={product}  />
        })}   
    </div>
  </div>
</div>

	   <Footer />
       </div> 
  );
};