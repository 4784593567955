import React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { useNavigate } from 'react-router-dom';
export const Error = () => {	
    const navigate=useNavigate();
  return (
    <div>
	<Header />    
  <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">                       
                    </div>
                </div>
            </div>
        </div>
    </div>         
   <main>   
    <br></br>
    <br></br>    
    <h1 style={{textAlign:'center'}}>Page Not Found</h1>
    <div className="col text-center">
    <button className="specialist-btn"  onClick={()=>navigate(-1)}>Go Back</button>
    </div>
    <br></br>
    <br></br>
</main>
	   <Footer />
       </div> 
  );
};
