import React from "react";
import { Header } from './Header';
import { Footer } from './Footer';
import { Link } from "react-router-dom";
export const HowItWorks = () => {
  return (
    <div>
	<Header />
  <div className="main-body main-how-its-work">
  <div className="banner-image">
    <img className="banner-img" src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/hoe it work.webp"} alt="" />
  </div>
  <div className="container after-banner">
    <div className="after-banner-path">
      <span className="active-page">Home</span>
      <span>/</span>
      <span>About Us</span>
    </div>
  </div>
  <div className="container">
    <div className="content-how-its-work">
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </p>
    </div>
    <div>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </p>
    </div>
  </div>
  <div className="title-how-its-work container">
    <span>How it work, Step by step.</span>
  </div>
  <section className="how-it-works">
    <h1>How it works</h1>
    <div className="step">
      <div className="image">
        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-1.png"} alt="Choose your product" />
      </div>
      <div className="content">
        <h2>step-1</h2>
        <p>
          Search for products in our store. Choose the colors, sizes, and styles
          you need. The chosen items will appear in the cart immediately on the
          top right corner.
        </p>
      </div>
    </div>
    <div className="step">
      <div className="image">
        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-2.png"} alt="Add your design" />
      </div>
      <div className="content">
        <h2> Step-2</h2>
        <p>
          Add your own designs to the products you choose. You can choose from
          available colors and text options, and your order is instantly
          updated.
        </p>
      </div>
    </div>
    <div className="step">
      <div className="image">
        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"} alt="Processing" />
      </div>
      <div className="content">
        <h2> Step-3</h2>
        <p>
          Once you have placed the order online, you will receive an email
          confirmation with your order details. If there are any issues with
          your order, we will contact you within 24 hours to resolve them.
        </p>
      </div>
    </div>
    <div className="step">
      <div className="image">
        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-1.png"} alt="Shipping" />
      </div>
      <div className="content">
        <h2> Step-4</h2>
        <p>
          Within 24 hours after your order, you will receive a final
          confirmation email with the shipping details. Your order will be
          shipped within the next 2-3 business days. You can track the shipment
          with the provided link once it is shipped.
        </p>
      </div>
    </div>
    <div className="step">
      <div className="image">
        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-2.png"} alt="Customer Support" />
      </div>
      <div className="content">
        <h2> Step-5</h2>
        <p>
          If you have any questions or issues, our support team is here to help.
          You can contact us anytime, and we will assist you as soon as
          possible.
        </p>
      </div>
    </div>
  </section>
</div>
	   <Footer />
    </div>
  );
};
