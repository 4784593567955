import React,{ Fragment,useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import Paginate from './Paginate'; 
import { fetchDataWithRetry } from "../../helper/formdata";   
export const Blog = (props) => {
    const [article, setArticle] = useState([]);
    const [articlelist, setArticlelist] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage] = useState(5);
   const [error, setError] = useState(false);
   const indexOfLastPost = currentPage * postsPerPage;
   const indexOfFirstPost = indexOfLastPost - postsPerPage;
   const currentPosts = articlelist.slice(indexOfFirstPost, indexOfLastPost);
	//console.log(articlelist);
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
}  
    //const [justslug, setJustslug] = useState("");
    
      
    function handleClick(slug)
    {
        const currentPosts = articlelist.slice(indexOfFirstPost, indexOfLastPost);
    //setJustslug(slug.art_cat_slug);
    fetchData2(slug.art_cat_slug);
    }
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/article_cat_list`).then((article) => {
        setArticle(article.data);
    
      }).catch((error) => {
     setError(error);
     });
//   const fetchData1 = async() => {
//     await fetch(`${process.env.REACT_APP_BASE_URL}/article_cat_list`)
//       .then(response => {
//         return response.json()
//       })
//       .then(data1 => {
		  
//         setArticle(data1.data)
// 		//console.log(category);
//       })
//   }
  
  const fetchData2 = async(props) => {
   //console.log(props);
    let url='';
    if (props === undefined) {
        
    url=`${process.env.REACT_APP_BASE_URL}/article_list`;
    }
    else
    {
        
        
    url=`${process.env.REACT_APP_BASE_URL}/article_list/${props}`;
    }
    fetchDataWithRetry(url).then((article) => {
        setArticlelist(article.data);
    
      }).catch((error) => {
     setError(error);
     });

  }
  useEffect(() => {    
    fetchData2();
  
  }, []);
  
  return (
    <div>
	<Header />
    <div className="main-body main-blog-listing">
  <div className="main-body">
    <div className="banner-image">
      <img className="banner-img" src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dashboard-banner.webp"} alt="" />
    </div>
    <div className="container after-banner">
      <div className="after-banner-path">
        <span className="active-page">Home</span>
        <span>/</span>
        <span>About Us</span>
      </div>
    </div>
    <div className="container container-fluid">
      <div className="row">
      <div className="col-md-9">
  {typeof articlelist != 'undefined' && articlelist.length > 0 && articlelist && (
						<>
						{currentPosts.map((item, i) => {
				   const { id, art_title,art_banner} = item;
				   return (
  <div className="main-blog-card">
    <div className="blog-image-card">
      <img
        src={art_banner}
        alt="Blog Image"
      />
    </div>
    <div className="blog-feed-back">
      <div className="blog-like">
        <ul>
          <li>
            <i className="fa-solid fa-message" />0
          </li>
          <li>
            <i className="fa-solid fa-fire" />
            772
          </li>
          <li>
            <i className="fa-solid fa-clock" />2
          </li>
        </ul>
        <div className="blog-show-more">
          <Link to={`/blog/${id}`} className="btn-blog">
            continue Reading
          </Link>
        </div>
      </div>
      <div className="blog-name-heading">
        <div className="blog-heading-content">
          <h4>{art_title}</h4>
          <p>
            <i className="fa-solid fa-heart-pulse" />
            Children's Health,&nbsp;Mental Health
          </p>
        </div>
        <div className="blog-auther-info">
          <ul>
            <li>
              <i className="fa-solid fa-user" />
              Healthians Team
            </li>
            <li>
              <i className="fa-solid fa-calendar-days" />
              June 5, 2024
            </li>
          </ul>
        </div>
      </div>
      <div className="blog-content">
        <p>
          Does your child seem like a whirlwind of energy, constantly on the
          move and struggling to focus on tasks? Perhaps they blurt out answers
          before questions are finished, or their…
        </p>
      </div>
    </div>
  </div>
  );
      })}
                    </>
                    )}
</div>

      <div className="col-md-3">
      <div className="blog-categroe">
  <div className="blog-cat-heading">
    <h4>Categories</h4>
  </div>
  <ul>
  {typeof article != 'undefined' && article.length > 0 && article && (
						<>
						{article.map((item, i) => {
				   const { id, art_cat_title, art_cat_slug} = item;
				   return (
    <li key={id} className="nav-item" role="presentation">
      <button
        className="nav-link active"
        id="home-tab"
        data-bs-toggle="tab"
        data-bs-target="#home"
        type="button"
        role="tab"
        aria-controls="home"
        aria-selected="true"
        onClick={() => handleClick({art_cat_slug})}
      >
        <p>{art_cat_title}</p>
      </button>
    </li>
   );
  })}
    </>
    )}
  </ul>
</div>

      </div>
      </div>
      <Paginate
      postsPerPage={postsPerPage}
      totalPosts={articlelist.length}
      paginate={paginate}
   />
      <div>
        <div className="main-btn">
          <div className="btn-holder">
            <button className="btn btn-3 hover-border-4">
              <span>View More</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

	   <Footer />
    </div>
  );
};
