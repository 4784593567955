import React from "react";
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
export const FavoriteDoctor = (props) => {
  const token = JSON.parse(localStorage.getItem('user-info-login'));
  return (
    <div>
	<Header />
  <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
  <div className="section">
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="#">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
        </ol>
      </nav>
    </div>
  </div>

<section className="dashboard-section bg-color-3">
        <div className="left-panel">
          <div className="profile-box patient-profile">
            <div className="upper-box">
              <figure className="profile-image"><img src={(props.length > 0) ? props.profileDetails.image: ''} alt="" /></figure>
              <div className="title-box centred">
                <div className="inner">
                  <h3>{token !== null ? token['name'] : ''}</h3>
                  <p><i className="fas fa-calendar-alt" />Oct 05, 2083,  38 Years</p>
                </div>
              </div>
            </div>
            <div className="profile-info">
            <ul className="list clearfix">
                <li><Link to="/dashboard" className="current"><i className="fas fa-columns" />Dashboard</Link></li>
                <li><Link to="/favorite_doctor"><i className="fas fa-heart" />Favourite Doctors</Link></li>                
                <li><Link to="/profile"><i className="fas fa-user" />My Profile</Link></li>
                <li><Link to="/change_password"><i className="fas fa-unlock-alt" />Change Password</Link></li>
                <li><Link href="/logout"><i className="fas fa-sign-out-alt" />Logout</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="right-panel">
          <div className="content-container">
            <div className="outer-container">
              <div className="favourite-doctors">
                <div className="title-box">
                  <h3>Favourite List</h3>
                </div>
                <div className="doctors-list">
                  <div className="row clearfix">
                    <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
                      <div className="team-block-three">
                        <div className="inner-box">
                          <figure className="image-box">
                            <img src="assets/img/team/team-21.jpg" alt="" />
                            <Link to=""><i className="far fa-heart" /></Link>
                          </figure>
                          <div className="lower-content">
                            <ul className="name-box clearfix">
                              <li className="name"><h3><Link to="">Dr. Mary Astor</Link></h3></li>
                              <li><i className="icon-Trust-1" /></li>
                              <li><i className="icon-Trust-2" /></li>
                            </ul>
                            <span className="designation">MDS - Periodontology and BDS</span>
                            <div className="rating-box clearfix">
                              <ul className="rating clearfix">
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><Link to="">(28)</Link></li>
                              </ul>
                            </div>
                            <div className="location-box">
                              <p><i className="fas fa-map-marker-alt" />G87P, Birmingham, UK</p>
                            </div>
                            <div className="lower-box clearfix">
                              <span className="text">24/7 Available</span>
                              <Link to="">Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
                      <div className="team-block-three">
                        <div className="inner-box">
                          <figure className="image-box">
                            <img src="assets/img/team/team-22.jpg" alt="" />
                            <Link to=""><i className="far fa-heart" /></Link>
                          </figure>
                          <div className="lower-content">
                            <ul className="name-box clearfix">
                              <li className="name"><h3><Link to="">Dr. Terry Bradshaw</Link></h3></li>
                              <li><i className="icon-Trust-1" /></li>
                              <li><i className="icon-Trust-2" /></li>
                            </ul>
                            <span className="designation">MDS - Periodontology and BDS</span>
                            <div className="rating-box clearfix">
                              <ul className="rating clearfix">
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><Link to="">(20)</Link></li>
                              </ul>
                            </div>
                            <div className="location-box">
                              <p><i className="fas fa-map-marker-alt" />G87P, Birmingham, UK</p>
                            </div>
                            <div className="lower-box clearfix">
                              <span className="text not-available">Not Available</span>
                              <Link to="">Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
                      <div className="team-block-three">
                        <div className="inner-box">
                          <figure className="image-box">
                            <img src="assets/img/team/team-23.jpg" alt="" />
                            <Link to=""><i className="far fa-heart" /></Link>
                          </figure>
                          <div className="lower-content">
                            <ul className="name-box clearfix">
                              <li className="name"><h3><Link to="">Dr. Julia Jhones</Link></h3></li>
                              <li><i className="icon-Trust-1" /></li>
                              <li><i className="icon-Trust-2" /></li>
                            </ul>
                            <span className="designation">MDS - Periodontology and BDS</span>
                            <div className="rating-box clearfix">
                              <ul className="rating clearfix">
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><Link to="">(32)</Link></li>
                              </ul>
                            </div>
                            <div className="location-box">
                              <p><i className="fas fa-map-marker-alt" />G87P, Birmingham, UK</p>
                            </div>
                            <div className="lower-box clearfix">
                              <span className="text">24/7 Available</span>
                              <Link to="">Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
                      <div className="team-block-three">
                        <div className="inner-box">
                          <figure className="image-box">
                            <img src="assets/img/team/team-24.jpg" alt="" />
                            <Link to=""><i className="far fa-heart" /></Link>
                          </figure>
                          <div className="lower-content">
                            <ul className="name-box clearfix">
                              <li className="name"><h3><Link to="">Dr. Fred Allen</Link></h3></li>
                              <li><i className="icon-Trust-1" /></li>
                              <li><i className="icon-Trust-2" /></li>
                            </ul>
                            <span className="designation">MDS - Periodontology and BDS</span>
                            <div className="rating-box clearfix">
                              <ul className="rating clearfix">
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><Link to="">(30)</Link></li>
                              </ul>
                            </div>
                            <div className="location-box">
                              <p><i className="fas fa-map-marker-alt" />G87P, Birmingham, UK</p>
                            </div>
                            <div className="lower-box clearfix">
                              <span className="text not-available">Not Available</span>
                              <Link to="">Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
                      <div className="team-block-three">
                        <div className="inner-box">
                          <figure className="image-box">
                            <img src="assets/img/team/team-25.jpg" alt="" />
                            <Link to=""><i className="far fa-heart" /></Link>
                          </figure>
                          <div className="lower-content">
                            <ul className="name-box clearfix">
                              <li className="name"><h3><Link to="doctors-details.html">Dr. Leroy Anderson</Link></h3></li>
                              <li><i className="icon-Trust-1" /></li>
                              <li><i className="icon-Trust-2" /></li>
                            </ul>
                            <span className="designation">MDS - Periodontology and BDS</span>
                            <div className="rating-box clearfix">
                              <ul className="rating clearfix">
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><Link to="">(54)</Link></li>
                              </ul>
                            </div>
                            <div className="location-box">
                              <p><i className="fas fa-map-marker-alt" />G87P, Birmingham, UK</p>
                            </div>
                            <div className="lower-box clearfix">
                              <span className="text">24/7 Available</span>
                              <Link to="">Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
                      <div className="team-block-three">
                        <div className="inner-box">
                          <figure className="image-box">
                            <img src="assets/img/team/team-26.jpg" alt="" />
                            <Link to=""><i className="far fa-heart" /></Link>
                          </figure>
                          <div className="lower-content">
                            <ul className="name-box clearfix">
                              <li className="name"><h3><Link to="">Dr. Ray Aniston</Link></h3></li>
                              <li><i className="icon-Trust-1" /></li>
                              <li><i className="icon-Trust-2" /></li>
                            </ul>
                            <span className="designation">MDS - Periodontology and BDS</span>
                            <div className="rating-box clearfix">
                              <ul className="rating clearfix">
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><Link to="">(36)</Link></li>
                              </ul>
                            </div>
                            <div className="location-box">
                              <p><i className="fas fa-map-marker-alt" />G87P, Birmingham, UK</p>
                            </div>
                            <div className="lower-box clearfix">
                              <span className="text">24/7 Available</span>
                              <Link to="">Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
                      <div className="team-block-three">
                        <div className="inner-box">
                          <figure className="image-box">
                            <img src="assets/img/team/team-27.jpg" alt="" />
                            <Link to=""><i className="far fa-heart" /></Link>
                          </figure>
                          <div className="lower-content">
                            <ul className="name-box clearfix">
                              <li className="name"><h3><Link to="">Dr. Criss Angel</Link></h3></li>
                              <li><i className="icon-Trust-1" /></li>
                              <li><i className="icon-Trust-2" /></li>
                            </ul>
                            <span className="designation">MDS - Periodontology and BDS</span>
                            <div className="rating-box clearfix">
                              <ul className="rating clearfix">
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><Link to="">(17)</Link></li>
                              </ul>
                            </div>
                            <div className="location-box">
                              <p><i className="fas fa-map-marker-alt" />G87P, Birmingham, UK</p>
                            </div>
                            <div className="lower-box clearfix">
                              <span className="text">24/7 Available</span>
                              <Link to="">Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
                      <div className="team-block-three">
                        <div className="inner-box">
                          <figure className="image-box">
                            <img src="assets/img/team/team-28.jpg" alt="" />
                            <Link to=""><i className="far fa-heart" /></Link>
                          </figure>
                          <div className="lower-content">
                            <ul className="name-box clearfix">
                              <li className="name"><h3><Link to="">Dr. Eve Arden</Link></h3></li>
                              <li><i className="icon-Trust-1" /></li>
                              <li><i className="icon-Trust-2" /></li>
                            </ul>
                            <span className="designation">MDS - Periodontology and BDS</span>
                            <div className="rating-box clearfix">
                              <ul className="rating clearfix">
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><Link to="">(30)</Link></li>
                              </ul>
                            </div>
                            <div className="location-box">
                              <p><i className="fas fa-map-marker-alt" />G87P, Birmingham, UK</p>
                            </div>
                            <div className="lower-box clearfix">
                              <span className="text">24/7 Available</span>
                              <Link to="">Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
                      <div className="team-block-three">
                        <div className="inner-box">
                          <figure className="image-box">
                            <img src="assets/img/team/team-29.jpg" alt="" />
                            <Link to=""><i className="far fa-heart" /></Link>
                          </figure>
                          <div className="lower-content">
                            <ul className="name-box clearfix">
                              <li className="name"><h3><Link to="">Dr. Cate Blanchett</Link></h3></li>
                              <li><i className="icon-Trust-1" /></li>
                              <li><i className="icon-Trust-2" /></li>
                            </ul>
                            <span className="designation">MDS - Periodontology and BDS</span>
                            <div className="rating-box clearfix">
                              <ul className="rating clearfix">
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><Link to="">(15)</Link></li>
                              </ul>
                            </div>
                            <div className="location-box">
                              <p><i className="fas fa-map-marker-alt" />G87P, Birmingham, UK</p>
                            </div>
                            <div className="lower-box clearfix">
                              <span className="text">24/7 Available</span>
                              <Link to="">Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
                      <div className="team-block-three">
                        <div className="inner-box">
                          <figure className="image-box">
                            <img src="assets/img/team/team-30.jpg" alt="" />
                            <Link to=""><i className="far fa-heart" /></Link>
                          </figure>
                          <div className="lower-content">
                            <ul className="name-box clearfix">
                              <li className="name"><h3><Link to="">Dr. Rex Allen</Link></h3></li>
                              <li><i className="icon-Trust-1" /></li>
                              <li><i className="icon-Trust-2" /></li>
                            </ul>
                            <span className="designation">MDS - Periodontology and BDS</span>
                            <div className="rating-box clearfix">
                              <ul className="rating clearfix">
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><Link to="">(40)</Link></li>
                              </ul>
                            </div>
                            <div className="location-box">
                              <p><i className="fas fa-map-marker-alt" />G87P, Birmingham, UK</p>
                            </div>
                            <div className="lower-box clearfix">
                              <span className="text">24/7 Available</span>
                              <Link to="">Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
                      <div className="team-block-three">
                        <div className="inner-box">
                          <figure className="image-box">
                            <img src="assets/img/team/team-31.jpg" alt="" />
                            <Link to=""><i className="far fa-heart" /></Link>
                          </figure>
                          <div className="lower-content">
                            <ul className="name-box clearfix">
                              <li className="name"><h3><Link to="">Dr. Agnes Ayres</Link></h3></li>
                              <li><i className="icon-Trust-1" /></li>
                              <li><i className="icon-Trust-2" /></li>
                            </ul>
                            <span className="designation">MDS - Periodontology and BDS</span>
                            <div className="rating-box clearfix">
                              <ul className="rating clearfix">
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><Link to="">(25)</Link></li>
                              </ul>
                            </div>
                            <div className="location-box">
                              <p><i className="fas fa-map-marker-alt" />G87P, Birmingham, UK</p>
                            </div>
                            <div className="lower-box clearfix">
                              <span className="text not-available">Not Available</span>
                              <Link to="">Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
                      <div className="team-block-three">
                        <div className="inner-box">
                          <figure className="image-box">
                            <img src="assets/img/team/team-32.jpg" alt="" />
                            <Link to=""><i className="far fa-heart" /></Link>
                          </figure>
                          <div className="lower-content">
                            <ul className="name-box clearfix">
                              <li className="name"><h3><Link to="">Dr. Edward Asner</Link></h3></li>
                              <li><i className="icon-Trust-1" /></li>
                              <li><i className="icon-Trust-2" /></li>
                            </ul>
                            <span className="designation">MDS - Periodontology and BDS</span>
                            <div className="rating-box clearfix">
                              <ul className="rating clearfix">
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><i className="icon-Star" /></li>
                                <li><Link to="">(32)</Link></li>
                              </ul>
                            </div>
                            <div className="location-box">
                              <p><i className="fas fa-map-marker-alt" />G87P, Birmingham, UK</p>
                            </div>
                            <div className="lower-box clearfix">
                              <span className="text">24/7 Available</span>
                              <Link to="">Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pagination-wrapper">
                  <ul className="pagination">
                    <li><Link to="" className="current">1</Link></li>
                    <li><Link to="">2</Link></li>
                    <li><Link to="">3</Link></li>
                    <li><Link to=""><i className="icon-Arrow-Right" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};