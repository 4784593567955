import React, { useRef, useState } from 'react';

export const CheckOut1 = () => {
  // Create refs for the input fields
  const billingAddressRef = useRef(null);
  const billingCityRef = useRef(null);
  const billingStateRef = useRef(null);
  const billingZipRef = useRef(null);

  const shippingAddressRef = useRef(null);
  const shippingCityRef = useRef(null);
  const shippingStateRef = useRef(null);
  const shippingZipRef = useRef(null);

  const [sameAsBilling, setSameAsBilling] = useState(false);
  const [validationMessages, setValidationMessages] = useState({});

  const handleCheckboxChange = () => {
    setSameAsBilling(!sameAsBilling);
    if (!sameAsBilling) {
      // Copy billing address to shipping address
      shippingAddressRef.current.value = billingAddressRef.current.value;
      shippingCityRef.current.value = billingCityRef.current.value;
      shippingStateRef.current.value = billingStateRef.current.value;
      shippingZipRef.current.value = billingZipRef.current.value;
    } else {
      // Clear shipping address fields
      shippingAddressRef.current.value = '';
      shippingCityRef.current.value = '';
      shippingStateRef.current.value = '';
      shippingZipRef.current.value = '';
    }
  };

  const validateFields = () => {
    const messages = {};
    const billingAddress = billingAddressRef.current.value;
    const billingCity = billingCityRef.current.value;
    const billingState = billingStateRef.current.value;
    const billingZip = billingZipRef.current.value;

    const shippingAddress = shippingAddressRef.current.value;
    const shippingCity = shippingCityRef.current.value;
    const shippingState = shippingStateRef.current.value;
    const shippingZip = shippingZipRef.current.value;

    // Billing address validation
    if (!billingAddress) messages.billingAddress = 'Billing address is required.';
    if (!billingCity) messages.billingCity = 'Billing city is required.';
    if (!billingState) messages.billingState = 'Billing state is required.';
    if (!billingZip) messages.billingZip = 'Billing zip is required.';

    // Shipping address validation
    if (!shippingAddress) messages.shippingAddress = 'Shipping address is required.';
    if (!shippingCity) messages.shippingCity = 'Shipping city is required.';
    if (!shippingState) messages.shippingState = 'Shipping state is required.';
    if (!shippingZip) messages.shippingZip = 'Shipping zip is required.';

    return messages;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const messages = validateFields();
    setValidationMessages(messages);

    if (Object.keys(messages).length === 0) {
      // No validation errors, proceed with form submission logic
      console.log('Form submitted successfully');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Billing Address</h2>
      <div>
        <label>
          Address:
          <input type="text" ref={billingAddressRef} />
          {validationMessages.billingAddress && (
            <p style={{ color: 'red' }}>{validationMessages.billingAddress}</p>
          )}
        </label>
      </div>
      <div>
        <label>
          City:
          <input type="text" ref={billingCityRef} />
          {validationMessages.billingCity && (
            <p style={{ color: 'red' }}>{validationMessages.billingCity}</p>
          )}
        </label>
      </div>
      <div>
        <label>
          State:
          <input type="text" ref={billingStateRef} />
          {validationMessages.billingState && (
            <p style={{ color: 'red' }}>{validationMessages.billingState}</p>
          )}
        </label>
      </div>
      <div>
        <label>
          Zip:
          <input type="text" ref={billingZipRef} />
          {validationMessages.billingZip && (
            <p style={{ color: 'red' }}>{validationMessages.billingZip}</p>
          )}
        </label>
      </div>

      <div>
        <label>
          <input type="checkbox" checked={sameAsBilling} onChange={handleCheckboxChange} />
          Shipping address same as billing address
        </label>
      </div>

      <h2>Shipping Address</h2>
      <div>
        <label>
          Address:
          <input type="text" ref={shippingAddressRef} disabled={sameAsBilling} />
          {validationMessages.shippingAddress && (
            <p style={{ color: 'red' }}>{validationMessages.shippingAddress}</p>
          )}
        </label>
      </div>
      <div>
        <label>
          City:
          <input type="text" ref={shippingCityRef} disabled={sameAsBilling} />
          {validationMessages.shippingCity && (
            <p style={{ color: 'red' }}>{validationMessages.shippingCity}</p>
          )}
        </label>
      </div>
      <div>
        <label>
          State:
          <input type="text" ref={shippingStateRef} disabled={sameAsBilling} />
          {validationMessages.shippingState && (
            <p style={{ color: 'red' }}>{validationMessages.shippingState}</p>
          )}
        </label>
      </div>
      <div>
        <label>
          Zip:
          <input type="text" ref={shippingZipRef} disabled={sameAsBilling} />
          {validationMessages.shippingZip && (
            <p style={{ color: 'red' }}>{validationMessages.shippingZip}</p>
          )}
        </label>
      </div>

      <button type="submit">Submit</button>
    </form>
  );
};

