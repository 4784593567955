import React,{useEffect, useState,useRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-bootstrap-time-picker';
import { Header } from './Header';
import { Footer } from './Footer';
import {ReviewForm} from "./ReviewForm";
import { Link } from "react-router-dom";
import {useParams} from "react-router-dom";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { ToastContainer, toast } from 'react-toastify';
import{register,signUp} from '../../features/counter/apiSlice';
import { booking,booking1 } from "../../helper/formdata";
import { useDispatch, useSelector } from "react-redux";
import Parser from 'html-react-parser';
import { Tooltip } from "bootstrap";
import axios from "axios";
//console.log(Events);
export const DoctorDetails = (props) => {
  //console.log(props);
  const token = JSON.parse(localStorage.getItem('user-info-login')); 
  let firstDaty = 1; 
  let params = useParams();
const [startDate, setStartDate] = useState(new Date());
const [booking_status, setBooking_status] = useState("");
const [age, setAge] = useState("");
const [gender, setGender] = useState("");
const [time, setTime] = useState("");
const [appoinment, setAppoinment] = useState("");
const [count1, setCount1] = useState(0);
const [activeTab, setActiveTab] = useState("tab1"); 
const [message, setMessage] = useState("");
const [overviews, setOverviews] = useState(""); 
const dispatch=useDispatch();
const [inputValue,setInputValue]=useState({name:'',email:(typeof token != 'undefined' && token != null) ? token.currentUser.email:'',phone:'',password:'',startDate:startDate,subject:'',comments:'',age:'',gender:'',time:'',reference_id:params.id,register_id:(typeof token != 'undefined' && token != null ) ? token.currentUser.uid:'',booking_status:'booked',type:'doctor'})

//console.log(token['email']);
const formRef=useRef();
let tooltipInstance = null;

  const [doctordetail, setDoctorDetail] = useState([]);
  useEffect(() => {  
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/doctor_detail/${params.id}`)
        .then((response) => { setDoctorDetail(response.data.data[0]);
          setOverviews(response.data.data[0].doctor_overview);
        });
        
  }, [params.id])
  let array = [];
  let array1 = appoinment;
  
  array.push(appoinment); 
  
  const Events = [
    array
  ];
 // console.log(appoinment);
  //console.log(Events);
    //const [isActive, setIsActive] = useState(false);
    const handleTab1 = () => {
        // update the state to tab1
        setActiveTab("tab1");
      };
     
      const handleTab2 = () => {
        // update the state to tab2
        setActiveTab("tab2");
      };
      const handleTab3 = () => {
        // update the state to tab2
        setActiveTab("tab3");
      };
      const handleTab4 = () => {
        // update the state to tab2
        setActiveTab("tab4");
      }; 
      const handleInput=(e)=>
        {
          
          setInputValue({...inputValue,[e.target.name]:e.target.value});
          //console.log(inputValue);
        }
      const handleSubmit=async(e)=>
        {
          //console.log('test');
          e.preventDefault();
          if(token === null)
            {
              dispatch(signUp(inputValue));
              
            }
            else
            {
              const wait = toast.loading("Please wait...");
              console.log(inputValue);
              const booked=await booking1(
                inputValue
              );
              toast.update(wait, {
                render: `Welcome ${booked.message}`,
                type: "success",
                isLoading: false,
                autoClose: 10000,
                closeOnClick: true,
                pauseOnHover: true,
              }); 
              window.location.href = '/schedule'
          //dispatch(booking(inputValue))
            }
        }
      const handleMouseEnter = (info) => {
        //console.log(info.event.extendedProps.description);
        if (info.event.extendedProps.description) {
          tooltipInstance = new Tooltip(info.el, {
            title: info.event.extendedProps.description,
            html: true,
            placement: "top",
            trigger: "hover",
            container: "body"
          });
    
          tooltipInstance.show();
        }
      };
    
      const handleMouseLeave = (info) => {
        if (tooltipInstance) {
          tooltipInstance.dispose();
          tooltipInstance = null;
        }
      };
  return (    
    <>       
	<Header />
 
  <div className="main-body main-doctor-details">
  <div className="banner-image">
    <img className="banner-img" src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/dashboard-banner.webp"} alt="" />
  </div>
  <div className="sp_cont relative">
    <section className="container">
      <div className="main-doctor-details">
        <div className="main-doctor-part">
          <div className="row">
            <div className="col-4 doctor-image">
              <div className="pro_img_cust">
                <img src={(doctordetail.doctor_image ? doctordetail.doctor_image:'') } />
              </div>
            </div>
            <div className="col-8 ps-4 doctor-details">
              <div className="pro-c-content pro-c-content2">
                <p className="pr-title">{doctordetail.first_name} {doctordetail.last_name}</p>
                <p className="font_14 mt-2">
                  {doctordetail.doctor_qualification}
                </p>
                <div className="starting_c mt-3">
                  <p>Starting From: 500.00</p>
                </div>
                <ul className="do-ul do-ul2 mt-4">
                  <li>
                    <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/mark.png"} />
                    General Paediatrics
                  </li>
                  <li>
                    <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/mark.png"} />
                    Paediatric Care
                  </li>
                  <li>
                    <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/mark.png"} />
                    General Paediatrics
                  </li>
                  <li>
                    <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/mark.png"} />
                    Paediatric Care
                  </li>
                  <li>
                    <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/mark.png"} />
                    General Paediatrics
                  </li>
                  <li>
                    <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/mark.png"} />
                    Paediatric Care
                  </li>
                </ul>
                <div className="mt-3">
                  <hr />
                  <p>
                    Professor Joshua Clark studied at Havard and qualified in
                    medicine at Harvard Medical School in 1987.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ul className="nav nav-tabs mt-5 tabs_d" id="myTab" role="tablist">
          <li className={activeTab === "tab1" ? "tab-btn active-btn" : ""} data-tab="#tab-1" onClick={handleTab1}>Overview</li>
                      <li className={activeTab === "tab2" ? "tab-btn active-btn" : ""} data-tab="#tab-2" onClick={handleTab2}>Experience</li>
                      <li className={activeTab === "tab3" ? "tab-btn active-btn" : ""} data-tab="#tab-3" onClick={handleTab3}>Location</li>
                      <li className={activeTab === "tab4" ? "tab-btn active-btn" : ""} data-tab="#tab-4" onClick={handleTab4}>Reviews</li>
          </ul>
          <div className="tab-content" id="myTabContent">
          {activeTab === "tab1" ?  <div className={activeTab === "tab1" ? "tab active-tab" : ""} id="tab1">
                      <div className="inner-box">
                        <div className="text">   
                               
                        {Parser(overviews)} 
                        </div>                       
                        
                      </div>
                    </div> : (activeTab === "tab2" ? <div className={activeTab === "tab2" ? "tab active-tab" : ""} id="tab2">
                      <div className="experience-box">
                        <div className="text">
                        {(Parser(doctordetail.doctor_experience))}
                        </div>
                      </div>
                    </div>                   
                   : ( activeTab === "tab3"?
                    <div className={activeTab === "tab3" ? "tab active-tab" : ""} id="tab-3">
                      <div className="location-box">
                      {(Parser(doctordetail.doctor_location))}
                      </div>
                    </div>
                    : 
                    <div className={activeTab === "tab4" ? "tab active-tab" : ""} id="tab-4">
                      <ReviewForm profileDetails={props}/>
                    </div>
                    )
                    )
                  }
          </div>
        </div>
        <div className="main-appointment-part">
          <div className="course-form">
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="name" />
              <input type="text" id="name" placeholder="Name" name="name" defaultValue={(token != null) ? token['name'] : ''}  onChange={handleInput} required="required" />
            </div>
            <div className="input-group">
              <label htmlFor="email" />
              <input type="text" id="email" placeholder="email" name="email" defaultValue={(token != null && token != undefined) ? ((typeof token.currentUser != 'undefined') ?token.currentUser.email:token['email']) : inputValue.email} onChange={handleInput} required="required"/>
            </div>
            <div className="input-group">
              <label htmlFor="phone" />
              <input
                type="text"
                id="phone"
                placeholder="Phone Number"
                name="phone"
                defaultValue={(token != null) ? token['phone'] : ''} onChange={handleInput} required="required"
              />
            </div>
            {(token == null) ?
                                        <div className="input-group">
                                          <label htmlFor="password" />
                                            <input type="password" name="password" placeholder="Password"   onChange={handleInput} required="required"/>
                                        </div>
                                        : ''}
            <div className="input-group">
              <select
                id="gender"
                name="gender"
                aria-placeholder="Select Gender"
                value={inputValue.gender} onChange={handleInput} required="required"
              >
                <option value="" disabled="" selected="">
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="input-group">
              <label htmlFor="Age" />
              <input type="text" id="Age" value={inputValue.age} placeholder="Age" name="age" onChange={handleInput} />
            </div>
            <div className="input-group">
              <label htmlFor="dob" />
              <DatePicker selected={startDate} minDate={startDate} value={startDate} onChange={(date) => setStartDate(date)} required="required"/>
            </div>
            <div className="time-input">
            <TimePicker selected={time} start="10:00" end="21:00" value={time} step={30} onChange={handleInput} required="required"/>
              <span>:</span>
              <input
                type="number"
                id="minutes"
                name="minutes"
                min={0}
                max={59}
                placeholder="MM"
                required=""
              />
              <select id="ampm" name="ampm" required="">
                <option value="" disabled="" selected="">
                  AM/PM
                </option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
            <button className="subscribe-button">Book Appointment</button>
            {/* <div class="course-preview">
                              <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/lab-image.jpg"}
                                  alt="Course Preview">

                          </div>
                          <div class="subscription-options">
                              <div class="tab-menu">
                                  <button class="tab-link active"
                                      onclick="openTab(event, 'Personal')">Personal</button>
                                  <button class="tab-link"
                                      onclick="openTab(event, 'Teams')">Teams</button>
                              </div>
                              <div id="Personal" class="tab-content1">
                                  <h2>Subscribe to BookLabs</h2>
                                  <p>Get this Appointment, plus 11,000+ of
                                      our
                                      top-rated Doctors, with Personal
                                      Plan.</p>
                                  <button class="subscribe-button">Book
                                      Appointment</button>
                                  <p>Starting at ₹850 per month</p>
                                  <p>Cancel anytime</p>
                                  <hr>
                                  <p class="price">₹3,099</p>
                                  <button class="add-to-cart">Add to
                                      cart</button>
                                  <p>30-Day Money-Back Guarantee</p>
                                  <p>Full Lifetime Access</p>
                                  <div class="actions">
                                      <button>Share</button>
                                      <button>Gift this course</button>
                                      <button>Apply Coupon</button>
                                  </div>
                                  <div class="coupon">
                                      <input type="text"
                                          placeholder="Enter Coupon"
                                          id="couponCode">
                                      <button
                                          onclick="applyCoupon()">Apply</button>
                                  </div>
                              </div>
                              <div id="Teams" class="tab-content1"
                                  style="display: none;">
                                  <h2>Teams Subscription Plan</h2>
                                  <p>Get access for your team members to
                                      all our top-rated courses.</p>
                                  <button class="subscribe-button">Start
                                      subscription</button>
                                
                              </div>
                          </div> */}
                           </form>
          </div>
        </div>
      </div>
    </section>
    <span className="sp-p" />
  </div>
</div>
	   <Footer />
    </>
  );
};