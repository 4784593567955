import { createContext, useState } from "react"

const FormContext = createContext({})

export const FormProvider = ({ children }) => {
   
    const title = {
        0: 'Billing Info',
        1: 'Shipping Info',
        2: 'Opt-In'
    }

    const [page, setPage] = useState(0)
    const [userinfo, setUserInfo] = useState([]);
    const [data, setData] = useState({
        FirstName: "",
        Email: "",
        PhoneNumber: "",
        password: ""
    })

    const handleChange = e => {
        //console.log(e.target.value);
        const type = e.target.type
        
        const name = e.target.name

        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value
        
        // Case 1 : The user checks the box
        //console.log(e.target.checked);
        if (e.target.checked) {
            //console.log(e.target.value);
            let value1=e.target.value;
            setUserInfo([...userinfo, value1])
            
            //console.log([...userinfo, value1]);
        }
       
        // Case 2  : The user unchecks the box
        else {
            //console.log('un-checked');
            setUserInfo(
                userinfo.filter(
                    (item) => item !== e.target.value
                ),
                
            );
        }
        
        setData(prevData => ({
            ...prevData,
            [name]: value,
           
        }))
    }

    const {
        billAddress2,
        sameAsBilling,
        shipAddress2,
        optInNews,
        ...requiredInputs } = data

    const canSubmit = [...Object.values(requiredInputs)].every(Boolean) && page === Object.keys(title).length - 1

    Object.keys(data)
        .filter(key => key.startsWith('bill') && key !== 'billAddress2')
        .map(key => data[key])
        .every(Boolean)

   Object.keys(data)
        .filter(key => key.startsWith('ship') && key !== 'shipAddress2')
        .map(key => data[key])
        .every(Boolean)

    const disablePrev = page === 0
    const disableNext = page === 2
    

    const prevHide = page === 0 && "remove-button"

    const nextHide = page === Object.keys(title).length - 1 && "remove-button"

    const submitHide = page !== Object.keys(title).length - 1 && "remove-button"

    return (
        <FormContext.Provider value={{ title, page, setPage, data, setData, canSubmit, handleChange, disablePrev, disableNext, prevHide, nextHide, submitHide,userinfo }}>
            {children}
        </FormContext.Provider>
    )
}

export default FormContext 