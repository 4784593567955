import React,{ Fragment,useEffect, useState } from 'react';
import { Link,useLocation } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import { DoctorItem } from './DoctorItem';
import Paginate from './Paginate';
import axios from "axios";
import Select from 'react-select';
import { getDistance} from 'geolib';
import store from '../../app/store';
import { useDispatch,useSelector } from 'react-redux';
import{arealist,currentlatitude,currentlongitude} from '../../features/counter/apiSlice';
import { MultiSelect } from "react-multi-select-component";
import { fetchDataWithRetry } from "../../helper/formdata"; 
export const Doctor = () => {
	//console.log(props);
	const [selected1, setSelected1] = useState([]);
	const [isValid, setIsValid] = useState(true);
	const [selected, setSelected] = useState([]);
	const [specialist, setSpecialist] = useState([]);
	const location = useLocation();
	const { ids } = location.state || { ids: "none" };
	const { type } = location.state || { type: "none" };
	const [category, setCategory] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage] = useState(20);
   const [error, setError] = useState(false);
   const indexOfLastPost = currentPage * postsPerPage;
   const indexOfFirstPost = indexOfLastPost - postsPerPage;
   const currentPosts = specialist.slice(indexOfFirstPost, indexOfLastPost);
   const lt = useSelector(currentlatitude);
  const lng = useSelector(currentlongitude);
	//console.log(ids);
	const areaslistt=useSelector(arealist);
  //console.log(areaslistt);
  const dispatch=useDispatch();
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
}  
	const handleOnChange1 = value1 => {
		//console.log(value1);
		if (value1 === null) {
		  value1 = [];
		}
		else
		{
			setSelected1(value1);
		}
		//console.log(selected1);
	  };
	const handleOnChange = value => {   
		if (value === null) {
		  value = [];
		}
		else
		{
			
			setSelected(value);
		}
		//console.log(selected);
	  };
	  async function doctorList(ids) {		
		let type = 'hospital';	
		const reactData = {"item":selected,"city":selected1,"type":type,"ids":ids};	
        //console.log(ids);
	     if(selected.length !==0)
         {
			let result = await fetch(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list1`,
				{
					method: 'POST',
					body: JSON.stringify(reactData),
					headers:
					{
						"Content-Type": 'application/x-www-form-urlencoded',
						"Accept": 'application/json'
					}
				})
			result = await result.json();
			//console.log(JSON.stringify(item,type));
			setSpecialist(result.data);
            }
	  }	
	

	 
		useEffect(() => {
			//console.log(type);
			if ((ids !== 'none') && (selected.length === 0) && (ids !== 'hospital'))
			{
				fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list`).then((cat) => {
					setSpecialist(cat.data);
				
				  }).catch((error) => {
				 setError(error);
				 });	
			// axios
			// 	.get(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list/${ids}`)
			// 	.then((response) => { setSpecialist(response.data.data); });
			}
			if ((type === 'hospital') && (selected.length === 0))
			{
				//console.log(selected.length);
				fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list`).then((cat) => {
					setCategory(cat.data);
				
				  }).catch((error) => {
				 setError(error);
				 });
			// 	axios
			// .get(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list`)
			// .then((response) => { setCategory(response.data.data); });
			}
			fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/hospitallist/${ids}`).then((hos) => {
				setSpecialist(hos.data);
			
			  }).catch((error) => {
			 setError(error);
			 });
			// axios
			// 	.get(`${process.env.REACT_APP_BASE_URL}/hospitallist/${ids}`)
			// 	.then((response) => { setSpecialist(response.data.data); });
		  }, [ids,selected.length])
	
          
  return (
    <div>
	<Header />
	<div className="main-body main-doctor-listing">
  <section className="hero_section2 container-fluid container-fluid">
    <div className="row">
      <div className="col-12 hero_section2_img relative">
        <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/hero-img2.png"} />
        <div className="container">
        <div className="row hero_section_content white align-items-center">
          <div className="col-6 doctor-banner-content">
            <h1 className="common_title_cust">
              Online Doctors Consultation from the comfort of your home
            </h1>
            <h5 className="my-3 start_cate">
              Doctor Consultation starts from $400.00
            </h5>
            <div className="search_app  flex_prop">
              <input type="search" placeholder="Search For An Appointment..." />
              <button className="search_btn">
                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/search-icon.png"} />
              </button>
            </div>
            <p className="font_14 mt-3">
              100% cashback* upto Rs.199 of Doctor Consultation on booking a
              Pathology test within 7 Days.
            </p>
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
  <section className="container main-card-doctor margin_top">
    <div className="row">
      <div className="col-12">
        <div className="do_grid">
          {typeof currentPosts != 'undefined' && currentPosts.length > 0 && currentPosts && (
						<>
						{currentPosts.map((item) => {
				   const { id, address1,address2,doctor_image, first_name,last_name,lat1,long1,doctor_price,doctor_cat_title,doctor_overview,doctor_experience } = item;
				   if(lat1 !=null && long1!=null && lt!=null && lng!=null)
           {
           var dis=getDistance(               
            { latitude: lat1, longitude: long1 },
            { latitude: lt, longitude: lng }
               );
              }
              else
              {
                var dis=0;
              }
			  var url = "https://www.google.com/maps/dir/?api=1";
			  var origin = "&origin=" + lt + "," + lng;
			  var destination = "&destination=" + lat1 + "," + long1;
			  var newUrl = new URL(url + origin + destination);
				   return (
          <div className="do_container text-center relative">
            <div className="flex_prop gap-2">
              <div className="do_img">
			  <Link to={`/specialist/${id}`}><img src={doctor_image} /></Link>
              </div>
              <div className="text-start">
                <div className="title_do">
                  <a href="#">View Profile</a>
                  <p className="do-title">{first_name} {last_name}</p>
                </div>
                <div className="flex_prop justify-content-between cont_1 mt-2 gap-2">
                  <p className="font_13">Paediatric Care</p>
                  <p className="font_13">Location: Gurugram</p>
                </div>
              </div>
            </div>
            <div className="do-content mt-1">
              <hr />
              <ul
                className="nav nav-pills mb-3 nav_tabs_doc"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    SPECIALIZATION AND EXPERTISE
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    QUALIFICATION
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex={0}
                >
                  <ul className="do-ul mt-2">
                    <li>Pediatric Intensive Care</li>
                    <li>Pediatric Pulmonolog</li>
                    <li>Pediatric Intensive Care</li>
                    <li>Pediatric Pulmonolog</li>
                  </ul>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabIndex={0}
                >
                  <ul className="do-ul mt-2">
                    <li>MBBS</li>
                    <li>MD (Anesthesia)</li>
                    <li>paediatrics Residency</li>
                    <li>Pediatric Critical</li>
                  </ul>
                </div>
              </div>
              <span className="status-doc flex_prop gap-1 font_14">
                <span />
                Active
              </span>
              <a href="#" className="book_btn book_btn-do mt-4">
                Book An Appointment
              </a>
            </div>
          </div>
          );
          })}
						</>
						)}
        </div>
      </div>
    </div>
  </section>
</div>

	   <Footer />
    </div>
  );
};
