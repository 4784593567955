import React,{ useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import {ReadMore} from './ReadMore';
import axios from "axios";
import Parser from 'html-react-parser';
import Select from 'react-select';
import { useDispatch,useSelector } from 'react-redux';
import {arealist} from '../../features/counter/apiSlice';
import { fetchDataWithRetry } from "../../helper/formdata";
export const Lab = () => {  
  const [error, setError] = useState(false);
  const [lab, setLab] = useState([]);
  const [lab1, setLab1] = useState([]);
  const [selected1, setSelected1] = useState([]);
  //console.log(lab);
  const areaslistt=useSelector(arealist);
  useEffect(() => {
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/home_lab`).then((lab) => {
        
      setLab(lab.data);
  
    }).catch((error) => {
   setError(error);
   });
  }, []);
  const handleOnChange1 = value1 => {
    //console.log(value1);
    if (value1 === null) {
      value1 = [];
    }
    else
    {
        setSelected1(value1);
    }
    //console.log(selected1);
  };
  const search = () => {
    //console.log(selected1.label);
    //console.log(clinic.filter((item) => selected === item.address2));
    if (selected1 !== null) {
      //console.log(hospital1.filter((item) => item.address2 === selected1.label));
      setLab(lab1.filter((item) => item.address2 === selected1.label));
      //setSelected(selected);
    }
    
    //console.log(selected);
  }; 
  return (
    <div>
	<Header />  
  <div className="main-body main-doctor-listing main-lab">
  <section className="hero_section2 container-fluid container-fluid">
    <div className="row">
      <div className="col-12 hero_section2_img relative">
        <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/banner.webp"} />
        <div className="row hero_section_content white align-items-center ">
          <div className="col-6 doctor-banner-content">
            <h1 className="common_title_cust">
              Online Doctors Consultation from the comfort of your home
            </h1>
            <h5 className="my-3 start_cate">
              Doctor Consultation starts from $400.00
            </h5>
            <div className="search_app  flex_prop">
            <Select
        options={typeof areaslistt != 'undefined' && areaslistt.length > 0 && areaslistt.map(e => ({ label: e.city, value: e.id}))}
        optionLabel="selected City"       
        labelledBy="Select City" 
        className='category_search'
        style={{backgroundColor: 'white',Color: 'black'}}   
        onChange={handleOnChange1}
        value={selected1} 
      />
              <button className="search_btn" type="submit" onClick={search}>
                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/search-icon.png"} />
              </button>
            </div>
            <p className="font_14 mt-3">
              100% cashback* upto Rs.199 of Doctor Consultation on booking a
              Pathology test within 7 Days.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="container main-card-doctor margin_top">
    <div className="row">
      <div className="col-12">
      {typeof lab != 'undefined' && lab.length > 0 && lab.map((item) => {
        const { id,lab_image,lab_title,lab_desc,include } = item;
        //console.log(include);
        return (
      <section className="blog-card-section" key={id}>
        <div className="blog-card">
          <div className="left">
            <img src={lab_image} alt="Blog Image" />
          </div>
          <div className="right">
            <h2>Facilities</h2>
            <p className="content">
              <strong>Facilities</strong>  <ReadMore>{include}</ReadMore>
              </p>
              <p className="content">{lab_desc}, please visit <strong> <a href="http://www.booklabonline.com" target="_blank">www.booklabonline.com.</a></strong></p>
          </div>
        </div>
      </section>
      );
    })}
      </div>
    </div>
  </section>
</div>
	   <Footer />
    </div>
  );
};
