import React,{useEffect, useState } from 'react';
import { Link,useParams,useLocation } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import axios from "axios";
export const LabInfo = () => {
let params = useParams();
    const location = useLocation();
    const { ids } = location.state || { ids: "none" };
  const [labinfo, setLabInfo] = useState([]);   
  useEffect(() => {    
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/lab_cat_detail/${ids}`)
        .then((response) => { setLabInfo(response.data.data[0]); });
        
  }, [params.id])
  const image = (labinfo.lab_cat_banner_image) ? labinfo.lab_cat_banner_image : '';
  const image1 = (labinfo.lab_cat_banner_thumb_image) ? labinfo.lab_cat_banner_thumb_image : '';  
  return (
    <div>
	<Header />  
  
      <div className="banner">	  
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(${image})`,marginTop: `92px`
    }}>
            <div className="d-table">			
                <div className="d-table-cell">			
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}>{labinfo.lab_cat_title}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
   <main>  				
   <section className="block about_sec1">
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-lg-5">
        <div className="about_n1">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <figure className="abfirst_img">
                <img
                  decoding="async"
                  loading="lazy"
                  className="alignnone size-full wp-image-82"
                  src={image1}
                  alt=""
                  width={417}
                  height={400}
                />
              </figure>
            </div>
            <div className="col-md-12 col-lg-12">
              <figure className="absecond_img">
                <img
                  decoding="async"
                  loading="lazy"
                  className="alignnone size-full wp-image-84"
                  src={(labinfo.lab_cat_image)}
                  alt=""
                  width={252}
                  height={226}
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-lg-7">
        <div className="about_r">
        {labinfo.lab_cat_desc};
        </div>
      </div>
    </div>
  </div>
</section>
			
			</main>
	   <Footer />
    </div>
  );
};
