import React, { useState,useEffect } from "react";
import { Link,useLocation,useNavigate } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import { DashboardLeft } from './DashboardLeft';
import axios from "axios";
import { getAuth } from "firebase/auth";
import { useDispatch,useSelector } from 'react-redux';
import { updateProfile1 } from '../../features/counter/apiSlice';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {useAuthValue} from './AuthContext'
export const Profile = () => { 
  //console.log(props.profileDetails.image);
  const navigate = useNavigate();  
const [progresspercent, setProgresspercent] = useState(0);
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState("");	
  const [selectedFile1, setSelectedFile1] = useState("");	
  const [profileImage, setProfileImage] = useState("");	
  const [profileDetails, setProfileDetails] = useState([]);
  const [fname, setFName] = useState("");  
  const [email, setEmail] = useState("");
  const token = JSON.parse(localStorage.getItem('user-info-login'));  
  //console.log(token);
  const dispatch=useDispatch();
  //console.log(token);
  const location = useLocation();
  const { data } = location.state || { data: "none" }; 
  const auth = getAuth(); 
  //const profile = useSelector((state) => state.profile.profile);
  //const status = useSelector((state) => state.profile.status);
  //const error = useSelector((state) => state.profile.error);
  const [displayName, setDisplayName] = useState('');
  const [photoURL, setPhotoURL] = useState('');
  const [imgUrl, setImgUrl] = useState(null);  
  useEffect(() => {   
    //console.log(auth.currentUser.photoURL);  
    if (typeof token === 'undefined'){
     navigate("/")    
    }
    
},[])
//const { profile, loading, error } = useSelector(state => state.user);

  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateProfile1({ displayName, photoURL }));
  };
  
const handleUpdateProfile = (e) => {
  //setSelectedFile(URL.createObjectURL(e.target.files));
  //console.log(photoURL);
  //dispatch(updateUserProfile({ displayName, photoURL }));
  setImgUrl(auth.currentUser.photoURL); 
};
// const handleSubmit = (file) => {  
  
//   updateProfile(auth.currentUser, {
//     displayName: "", photoURL: file
//   }).then(() => {  
//     setImgUrl(auth.currentUser.photoURL);  
//     localStorage.setItem("user-info-login", '');
// 	    localStorage.setItem("user-info-login", JSON.stringify(auth));
//     // Profile updated!
//     // ...
//   }).catch((error) => {
//     // An error occurred
//     // ...
//   });
// }
  // async function changeHandler(event){
  //   let image1;	 
  //   const formData = new FormData();
  //       let file=event.target.files[0];
  //   formData.append('image',file);
  //   formData.append('profile_id','1'); 
  //   //setProfileImage('http://localhost/booklabonline1/uploaded_files/profile'+file);   
  //   setSelectedFile(URL.createObjectURL(event.target.files[0]));
  //   let url='https://royalacademy.net.in/booklabonline/admin/api/uploaded_files/profile';   
    
  //   let data_image;
  //   data_image= await axios.post('https://royalacademy.net.in/booklabonline/admin/api/uploadfile1', formData,{
  //       headers: {
  //           'content-type': 'multipart/form-data',
  //       }
        
  //   });		
  //   image1=data_image.data.data;
  //   console.log(image1);
  //   if(image1 !=null)
  //   {
  //   handleSubmit(image1);
  //   }
  //   //console.log(image1);
  //   setSelectedFile1(image1);
  //   //console.log(image1.data.data);
	// };
  // const profileList = async() => {
  //   const token = JSON.parse(localStorage.getItem('user-info-login'));
  //   if(token.currentUser.uid !== null)
  //   {    
  //     await fetch(`https://royalacademy.net.in/booklabonline/admin/api/profile/${token.cuurentuser.uid}`)
  //       .then(response => {
  //         return response.json();
          
  //       })
  //       .then(data311 => {
  //         //console.log(data311.data);
  //         handleSubmit();
  //         setProfileDetails(data311.data); 
  //         setSelectedFile(data311.data.image);
  //         //profileupdate();
  //       })
  //     }
  //   }
    // useEffect(() => {           
      
      
    // }, []);
    //let imagesss=token['id'] ? (profileDetails.image) : '';
    //setSelectedFile(imagesss);
    //console.log(profileDetails.image);
  // async function handleSubmission(){	
  //   const image=selectedFile1;	
  //   //console.log(image);
  //   var customer_id=token.currentUser.uid;
  //   let item = { fname, email,image,customer_id }
  //   let result = await fetch("https://royalacademy.net.in/booklabonline/admin/api/profile",
	// 		{
	// 			method: 'POST',
	// 			body: JSON.stringify(item),
	// 			headers:
	// 			{
	// 				"Content-Type": 'application/x-www-form-urlencoded',
	// 				"Accept": 'application/json'
	// 			}
	// 		})
	// 	result = await result.json();
  //   setMessage("Your Profile Successfully Updated.");		
	// };
  return (
    
    <div>
	<Header />
  
  <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
  <div className="section">
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="#">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
        </ol>
      </nav>
    </div>
  </div>
  <section className="doctors-dashboard bg-color-3">
       <DashboardLeft/>
        <div className="right-panel">
          <div className="content-container">
            <div className="outer-container">
              <div className="add-listing my-profile">
                <div className="single-box">
                  <div className="title-box">
                  {/* {profile.photoURL && <img src={profile.photoURL} alt="Profile" />} */}
                  {message.length > 0 &&   message == 'Your Profile Successfully Updated.' &&               
                  <div className="message contact-address-headline success">{message ? <p>{message}</p> : null}
                  </div>
                  }
                  {message.length > 0 &&   message == 'Your Profile Not Updated.' &&               
                  <div className="message contact-address-headline" style={{backgroundColor: 'Red'}}>{message ? <p>{message}</p> : null}
                  </div>
                  }
                    <h3>Basic Information</h3>
                    <Link to="" className="menu"><i className="icon-Dot-menu" /></Link>
                  </div>
                  <div className="inner-box">
                  <form action="http://azim.commonsupport.com/Docpro/add-listing.html" method="post">
                    <div className="profile-title">
                      <figure className="image-box"><img src="assets/images/resource/profile-4.png" alt="" /></figure>
                      <div className="upload-photo">
                      <input
        type="text"
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
        placeholder="Display Name"
      />
                      <input type="file" id="fileUpload" value={photoURL}
          onChange={(e) => setPhotoURL(e.target.value)}/><i className="icon-Upload" value="Upload Photo" />
                      <button onClick={handleSubmit}>Update Profile</button>
                        <span>Allowed JPG, GIF or PNG. Max size of 2MB</span>
                        {
                        selectedFile.length === 0 ? 
                        <img src={(typeof token.currentUser != 'undefined' && token.currentUser.photoURL != null) ? token.currentUser.photoURL: selectedFile} alt='uploaded file' height={200} />
                        :
                        <img src={selectedFile} alt='uploaded file' height={200} />
                        }
                        
                      </div>
                    </div>                   
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Name</label>
                          <input type="text" onChange={(e) => setFName(e.target.value)} name="name"  id="name" placeholder="Name"  required />
                        </div>                       
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Email</label>
                          <input type="email" onChange={(e) => setEmail(e.target.value)} value={(typeof token.currentUser != 'undefined' && token.currentUser.email != null) ? token.currentUser.email:token['email']} name="email" id="email" placeholder="Enter your Email" readOnly />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Date of birth</label>
                          <input type="text" name="birth_date" placeholder="Date of birth" required />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Gender</label>
                          <input type="text" name="gender" placeholder="Your Gender" required />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Age</label>
                          <input type="text" name="age" placeholder="Your Age" required />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Mobile number</label>
                          <input type="text" name="phone" placeholder="Your Phone" required />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Address</label>
                          <input type="text" name="address" placeholder="Address" required />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Blood Group</label>
                          <input type="text" name="blood_group" placeholder="Blood Group" required />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Marital status</label>
                          <input type="text" name="marital_status" placeholder="Marital status" required />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Note to the doctor (optional)</label>
                          <textarea name="message" placeholder="Write your note..." defaultValue={""} />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="single-box">
                  <div className="title-box">
                    <h3>Social Networks</h3>
                    <Link to="" className="menu"><i className="icon-Dot-menu" /></Link>
                    <Link to="" className="theme-btn-one">Add More<i className="icon-image" /></Link>
                  </div>
                  <div className="inner-box">
                    <form action="http://azim.commonsupport.com/Docpro/add-listing.html" method="post">
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Facebook URL</label>
                          <input type="text" name="facebook" required />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Twitter URL</label>
                          <input type="text" name="twitter" required />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Google Plus URL</label>
                          <input type="text" name="google" required />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <label>Instagram URL</label>
                          <input type="text" name="instagram" required />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="btn-box">
                  <button className="theme-btn-one">Save Change<i className="icon-Arrow-Right" /></button>
                  <Link to="" className="cancel-btn">Cancel</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
<Footer />
    </div>
  );
};