import React,{ Fragment,useEffect, useState } from 'react';
import { useParams} from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import axios from "axios";
import Parser from 'html-react-parser';
import {ReviewForm} from "./ReviewForm";
export const BlogDetails = (props) => {
  let params = useParams();
 // const location = useLocation();
  //const { id } = location.state || { id: "none" };
  const [articledetail, setArticleDetail] = useState([]);
  
  useEffect(() => {
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/articledetail/${params.id}`)
        .then((response) => { setArticleDetail(response.data.data); });
  }, [params.id])
  
  //console.log(articledetail);
  //console.log(params.id);
 
    return (
        <div>
        <Header />
        <div className="main-body main-blog-details">
        {typeof articledetail != 'undefined' && articledetail.length > 0 && articledetail && (
          <>
          {articledetail.map((item, i) => {
         const { art_id, art_title,art_banner,art_desc} = item;
         return (
          <div key={art_id}>
  <div className="container">
    <div className="blog-card-image">
      <img
        className="banner-img"
        src={art_banner}
        alt=""
      />
    </div>
  </div>
  <div className="container">
    <div className="blog-details-text">
      <h2>
        {art_title}
      </h2>
    {Parser(art_desc)}
      <div className="blog-detailstext pt-3">
        <i className="fa-solid fa-quote-right" />
        <p>
          “Enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
          ut aliquip ex ea commodo consequat duis aute irure dolor in reprehen
        </p>
      </div>
      <p className="pt-3">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Delectus,
        ullam. Voluptatibus animi dignissimos praesentium modi id reprehenderit
        vero sunt error, quaerat cum nobis sequi atque minima ipsum alias.
        Voluptatem, aspernatur. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Totam, explicabo? Magni dolore veritatis deserunt.
        Ducimus beatae consectetur quis, quam non facere consequuntur doloremque
        dolor, dicta iure, numquam impedit nesciunt reiciendis.veritatis
        deserunt. Ducimus beatae consectetur quis, quam non facere consequuntur
        doloremque dolor, dicta iure, numquam impedit nesciunt reiciendis.
      </p>
      <h3>Two Most-Cited Reason</h3>
      <p>
        Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
        duis aute irure dolor.
      </p>
    </div>
    <div className="post-share-option clearfix">
      <div className="text pull-left">
        <h4>We Are Social On:</h4>
      </div>
      <ul className="social-links clearfix pull-right">
        <li>
          <a href="blog-details.html">
            <i className="fab fa-facebook-f" />
          </a>
        </li>
        <li>
          <a href="blog-details.html">
            <i className="fab fa-twitter" />
          </a>
        </li>
        <li>
          <a href="blog-details.html">
            <i className="fab fa-google-plus-g" />
          </a>
        </li>
      </ul>
    </div>
    <div className="comment-box">
      <ReviewForm profileDetails={props}/>
    </div>
    </div>
    </div>
  );
})}
            
              </>
              )}
  </div>
<Footer />
</div>
  );
};