import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import { DashboardLeft } from './DashboardLeft';
import { AccountErrors } from "../../validation/account";
import { ToastContainer, toast } from "react-toastify";
import { getAuth, updatePassword } from "firebase/auth";
export const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); 
  const [message, setMessage] = useState("");
  const [profileDetails, setProfileDetails] = useState([]);
  const token = JSON.parse(localStorage.getItem('user-info-login'));
  const auth = getAuth();
const user = auth.currentUser;
//const newPassword = getASecureRandomPassword();
  //console.log(token['id']);
  const profileList = async() => {
    const token = JSON.parse(localStorage.getItem('user-info-login'));
      await fetch(`${process.env.REACT_APP_BASE_URL}/profile/${token.id}`)
        .then(response => {
          return response.json();
          
        })
        .then(data311 => {
          //console.log(data311.data);
         
          setProfileDetails(data311.data); 
        })
    
    }
async function handleSubmit(){
    
    if (password !== confirmPassword) {
      toast.error("Passwords dont match.", {
        position: toast.POSITION.TOP_CENTER,
    });
      
    } else {
      updatePassword(user, confirmPassword).then(() => {
        
        toast.success("Your Password Successfully changed.");
        setPassword("");
        setConfirmPassword("");
        //console.log('Update successful');
        // Update successful.
      }).catch((error) => {
       
        const wait = toast.loading("Please wait...");
        toast.update(wait, {
          render: AccountErrors(error.code),
          type: "error",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
    }
}
useEffect(() => {           
      
  profileList();
}, []);
  return (
    
    <div>
	<Header />
  <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
  <div className="section">
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="#">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
        </ol>
      </nav>
    </div>
  </div>
  <section className="doctors-dashboard bg-color-3">
         <DashboardLeft/>
        <div className="right-panel">
          <div className="content-container">
            <div className="outer-container">
              <div className="add-listing change-password">
                <div className="single-box">
                  <div className="title-box"> 
                  {message.length > 0 &&   message == 'Your Password Successfully changed.' &&               
                  <div className="message contact-address-headline success">{message ? <p>{message}</p> : null}
                  </div>
                  }
                  {message.length > 0 &&   message == 'Passwords dont match.' &&               
                  <div className="message contact-address-headline" style={{backgroundColor: 'Red'}}>{message ? <p>{message}</p> : null}
                  </div>
                  }
                    <h3>Change Password</h3>
                  </div>
                  <div className="inner-box">
                    <form action="http://azim.commonsupport.com/Docpro/add-listing.html" method="post">
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                          
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                          <label>New Password</label>
                          <input type="password" name="password" onChange={(e) => setPassword(e.target.value)} required />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                          <label>Confirm Password</label>
                          <input type="password" name="confirmPassword" onChange={(e) => setConfirmPassword(e.target.value)} required />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="btn-box">
                  <button  onClick={handleSubmit}  className="theme-btn-one">Save Change<i className="icon-Arrow-Right" /></button>
                  <a href="add-listing.html" className="cancel-btn">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
<Footer />
<ToastContainer />
    </div>
  );
 
};