import React,{ useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
export const Digital = () => {
  
  const [lab, setLab] = useState([]);
  const [center, setCenter] = useState([]);  
  const fetchData1 = async() => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/home_lab`)
      .then(response => {
        return response.json()
      })
      .then(data1 => {
		  
        setLab(data1.data)
		//console.log(category);
      })
  }
  useEffect(() => {    
	fetchData1();	
  fetchData2();	
  }, []);
  const fetchData2 = async() => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/center_lab`)
      .then(response => {
        return response.json()
      })
      .then(data1 => {
		  
        setCenter(data1.data)
		//console.log(category);
      })
  }
  
  return (
    <div>
	<Header />  
  <div className="main-body main-digital">
  <div className="banner-image">
    <img className="banner-img" src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/wepik-export-20240313154738gaQb.webp"} alt="" />
  </div>
  <div className="container">
    <div className="main-content">
      <h1>Energy Management Systems</h1>
      <p>
        भारतीय विशिष्ट पहचान प्राधिकरण (Unique Identification Authority of
        India) सन 2009 में गठित भारत सरकार का एक प्राधिकरण है जिसका गठन भारत के
        प्रत्येक नागरिक को एक बहुउद्देश्यीय राष्ट्रीय पहचान पत्र उपलब्ध कराने की
        भारत सरकार की एक महत्वाकांक्षी योजना के अन्तर्गत किया गया। भारत के
        प्रत्येक निवासियों को प्रारंभिक चरण में पहचान प्रदान करने एवं प्राथमिक
        तौर पर प्रभावशाली जनहित सेवाएं उपलब्ध कराना इस परियोजना का प्रमुख
        उद्देश्य था।
      </p>
    </div>
    <div className="cards">
      <div className="card">
        <div>
          <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/addhar.png"} alt="Aadhaar Card" />
        </div>
        <div className="card-title">Aadhaar Card</div>
      </div>
      <div className="card">
        <div>
          <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/addhar.png"} alt="Pan Card" />
        </div>
        <div className="card-title">Pan Card</div>
      </div>
      <div className="card">
        <div>
          <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/addhar.png"} alt="Ayushman Card" />
        </div>
        <div className="card-title">Ayushman Card</div>
      </div>
    </div>
    <div className="main-digital-card">
      <div className="card-content">
        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/digital1.jpg"} alt="Aadhaar Card" />
        <div className="card-description wrapper">
          <span className="fromleft f2">Aadhaar Card</span>
        </div>
      </div>
      <div className="card-content">
        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/digital2.jpg"} alt="Pan Card" />
        <div className="card-description wrapper">
          <span className="fromleft f2">Pan Card</span>
        </div>
      </div>
      <div className="card-content">
        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/digital3.jpg"} alt="Ayushman Card" />
        <div className="card-description wrapper">
          <span className="fromleft f2">Ayushman Card</span>
        </div>
      </div>
    </div>
  </div>
</div>
 <Footer />
    </div>
  );
};
   