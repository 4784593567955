  import React,{ useEffect, useState,useContext } from 'react';
import { Link,useLocation,useParams } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import axios from "axios";
import Parser from 'html-react-parser';
import Select from 'react-select';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { CartContext } from '../../context/cart';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./image-gallery.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper/modules';
import { Autoplay } from 'swiper/modules';
export const ProductDetails = (props) => { 
  const options = Array.from({ length: 10 }, (v, k) => ({
    value: k + 1,
    label: (k + 1).toString()
  }));

	const params = useParams();
	const location = useLocation();
  const [productdtail1, setProductDtail1] = useState([]);
  const [productimage, setProductImage] = useState([]);
  const [quantity1, setQuantity1] = useState([]);
  const [qunt, setQunt] = useState();
  const { cartItems, addToCart , removeFromCart,quantity=quantity1,setQuantity=setQuantity1} = useContext(CartContext)
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  //console.log(cartItems);
  // const onChange1 = (event) => {
  //   const value = event.target.value;
    
  //   setQuantity1(value);
  // };
	  const images1 = [
		{
		  original: 'https://picsum.photos/id/1018/1000/400/',
		  thumbnail: 'https://picsum.photos/id/1018/1000/400/',
		},
		{
		  original: 'https://picsum.photos/id/1015/1000/400/',
		  thumbnail: 'https://picsum.photos/id/1015/1000/400/',
		},
		{
		  original: 'https://picsum.photos/id/1026/1000/400/',
		  thumbnail: 'https://picsum.photos/id/1026/1000/400/',
		}
	  ];
	  useEffect(() => {		
		
		const getProductdetail = async () => {
		  try {
			const res = await axios.get(
			  `${process.env.REACT_APP_BASE_URL}/productdetails/${params.id}`,
			);
			//console.log(res.data.data);
			setProductDtail1(res.data.data);
			setProductImage(res.data.data.product_images);
		  } catch (e) {
			//console.log('error');
			console.log(e);
		  }
		};
		getProductdetail();
	  }, [params.id]);
	  
	  //console.log(productdtail1);
    const notifyAddedToCart = (item) => toast.success(`${item} added to cart!`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored',
      style: {
        backgroundColor: '#fff',
        color: '#000',
      }
      });
      const handleOnChange1 = value1 => {
        //console.log(value1.value);
        setQunt(value1);
           setQuantity1(value1.value);
       
      }; 
      const handleRemoveFromCart = (product) => {
        removeFromCart(product);
        notifyRemovedFromCart(product);
      };
      const notifyRemovedFromCart = (item) => toast.error(`${item.product_name} removed from cart!`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        style: {
          backgroundColor: '#000',
          color: '#fff',
        }
        });
	  //console.log(productimage);
  return (
    
    <div>
      
	<Header /> 
  <div className="main-body main-product-details booklab-product_det">
  <div className="banner-image">
    <img className="banner-img" src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product details.webp"} alt="" />
  </div>
  <div className="main-product-details">
    <div className="container-fluid container-xl common_padding">
      <div className="product-page">
        <div className="w-75">
          <div className="flex product-details-box ">
            <div className="slider-container product-details">
              <div className="slider">
                <div className="slides">
                  <Swiper
     spaceBetween={10}
     navigation={true}
     thumbs={{ swiper: thumbsSwiper }}
     modules={[Navigation, Thumbs]}
     className="mySwiper2"
  >
    {typeof productimage != 'undefined' && productimage.length > 0 && productimage && productimage.map((item,index) => {
              const { id,original } = item;
              return (
                <SwiperSlide key={id}>
                  <img
                    src={original}
                    alt="Product Image 1"
                    className="slide"
                  />
                </SwiperSlide> 
                );
              })}
                       
                       </Swiper>
                
                </div>
              </div>
              <div className="thumbnails">
              <Swiper
         onSwiper={setThumbsSwiper}
         spaceBetween={10}
         slidesPerView={4}
         freeMode={true}
         watchSlidesProgress={true}
         modules={[Thumbs]}
         className="mySwiper2"
      >
        {typeof productimage != 'undefined' && productimage.length > 0 && productimage && productimage.map((item,index) => {
           const { id,thumbnail } = item;
          return (
          <SwiperSlide key={index}>
                <img
                  src={thumbnail}
                  alt="Thumbnail 1"
                  className="thumbnail"
                  style={{ width: '500px', height: '200px' }}
                />
                </SwiperSlide>
        );
      })}
      </Swiper>
              </div>
            </div>
            <div className="product-details">
              <h1>
                {productdtail1.product_name}
              </h1>
              <div className="store_link">
                <a href="">Visit EVERHERB Store</a>
              </div>
              <div className="ratings">
                <span className="stars">★★★★☆</span>
                <span className="reviews">(1617 ratings)</span>
              </div>
              <span className="taxes">Inclusive of all taxes</span>
              <p className="delivery">
                Delivery by <strong>Today, before 10:00 pm</strong>
              </p>
              <div className="variant">
                <h3>Select Available Variant</h3>
                <p>Flavour</p>
                <div className="flavours">
                  <button>Aloe Vera</button>
                  <button>Amla</button>
                  <button className="active">Karela Jamun</button>
                  <button>Tulsi Giloy</button>
                  <button>Mixed Herbs</button>
                </div>
                <p>Pack Size</p>
                <div className="pack-size">
                  <button className="active">1L</button>
                  <button>500ml</button>
                </div>
              </div>
            </div>
          </div>
          {/*-Description-start */}
          <div className="Description">
            <h3>Description</h3>
            <p className="para-1">
              EverHerb Karela Jamun Juice is formulated with the extracts of
              Karela or bitter gourd and the whole Jamun fruit. Since ancient
              times, Karela and Jamun juice have been used to keep blood sugar
              levels steady. Bitter gourd is a natural blood-sugar controller
              and has been used by Ayurvedic doctors for centuries to help
              diabetes patients. When used along with appropriate medication, a
              healthy diet and regular exercise, EverHerb Karela Jamun Juice for
              diabetes can be helpful as it keeps blood glucose levels in check.
            </p>
            <p className="para-2">
              Moreover, when seeking the finest in natural wellness, EverHerb
              Karela Jamun Juice stands out as the best Karela Jamun Juice
              online. The Karela Jamun juice price is pocket-friendly as well.
              It can help to remove toxins from the blood can aid in improving
              the body’s immunity to help prevent infections and can also manage
              cough. Because of its hydrating effect on the bowels, it can help
              to ease constipation. EverHerb Karela Jamun Juice is 100 % natural
              and vegan. It contains no added sugar.
            </p>
          </div>
          {/*-Description-end-*/}
          <div className="cart_box-1">
            <div className="card-header">
              <div className="header-content">
                <div className="icon-bg">
                  <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/growth-img.svg"} alt="Growth Image" />
                </div>
                <div className="info-text">
                  <span>34 people bought this recently</span>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="price-discount">
                <div className="price-option">
                  <input
                    id="default-radio-1"
                    type="radio"
                    name="default-radio"
                  />
                  <div className="price-info">
                    ₹256*
                    <span className="line-through">MRP ₹320</span>
                    <span className="discount">20% off</span>
                  </div>
                </div>
                <div className="price-option">
                  <input
                    id="default-radio-2"
                    type="radio"
                    name="default-radio"
                  />
                  <div className="price-info">
                    ₹256
                    <span className="free-shipping">+ Free Shipping</span>
                    <span className="discount">20% off</span>
                  </div>
                </div>
                <div className="with-careplan">
                  <span>With</span>
                  <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/careplan-img.webp"} alt="Care Plan" />
                </div>
                <div className="tax-info">
                  ₹5.12/gm (Inclusive of all Taxes)
                </div>
                <div className="coupon-info">
                  <span>
                    This offer price is valid on orders above ₹1200 with Coupon
                  </span>
                </div>
                <div className="zipcode">
                  <div className="location-icon">
                    <svg
                      className="w-3"
                      fill="#099389"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                    </svg>
                    <input
                      id="zipcode"
                      name="zipcode"
                      type="text"
                      placeholder="Enter zipcode"
                    />
                    <div className="change-zipcode">
                      <a href="#">Change eerer</a>
                    </div>
                  </div>
                </div>
                <div className="delivery-info">
                  <div className="delivery-by">
                    <svg
                      className="icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z" />
                    </svg>
                    <span>DELIVERY BY</span>
                  </div>
                  <div className="see-next-step">See on Next Step.2333</div>
                </div>
                <div className="quantity">
                <Select
        options={options}
        optionLabel="selected Quantity"
        onChange={handleOnChange1}
        value={quantity1} 
        labelledBy="Select Quantity" 
        style={{backgroundColor: 'white',Color: 'white'}}    
      />
                  <div className="quantity-info">of 100 ml Syrup</div>
                </div>
                <div className="style__cart-button___3CZnL">
            {
                  !cartItems.find(item => item.product_id === productdtail1.product_id) ? (
              <div className="add-to-cart" onClick={() => {
                        addToCart(productdtail1.product_id,quantity1)
                        notifyAddedToCart(productdtail1.product_name)
                      }
                      }>
                Add to cart
              </div>
              ) : (
                <div className="flex gap-4">
                    <button
                      className="px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                      onClick={() => {
                        addToCart(productdtail1.product_id,1)
                      }}
                    >
                      +
                    </button>
                    <p className='text-gray-600'>{cartItems.find(item => item.product_id === productdtail1.product_id).quantity}</p>
                    <button
                      className="px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                      onClick={() => {
                        const cartItem = cartItems.find((item) => item.product_id === productdtail1.product_id);
                        if (cartItem.quantity === 1) {
                          handleRemoveFromCart(productdtail1);
                        } else {
                          removeFromCart(productdtail1);
                        }
                      }}
                    >
                      -
                    </button>
                  </div>
                 )
                }
               </div>
              </div>
            </div>
          </div>
          {/*-Similar Products-start-*/}
          <div>
            <div className="col-12 mt-3 hospi-slider swiper">
              <div className="swiper-wrapper">
              <Swiper
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
    >
      <SwiperSlide key={1}>
                <div className="swiper-slide">
                  <img
                    src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product-1.jpg"}
                    alt="Product Image"
                    className="product-img"
                  />
                  <div className="product-name">
                    Everherb (by Pharmeasy) Amla ...
                  </div>
                  <div className="product-details">60 Capsule(s) in Bottle</div>
                  <div className="product-price">
                    <span className="product-mrp">₹599</span>
                    <div>
                      ₹251.58 <span className="product-discount">(58%)</span>
                    </div>
                  </div>
                  <a href="#" className="add-btn">
                    Add
                  </a>
                </div>
                </SwiperSlide>
          <SwiperSlide key={2}>
                <div className="swiper-slide">
                  <img
                    src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product-1.jpg"}
                    alt="Product Image"
                    className="product-img"
                  />
                  <div className="product-name">
                    Everherb (by Pharmeasy) Amla ...
                  </div>
                  <div className="product-details">60 Capsule(s) in Bottle</div>
                  <div className="product-price">
                    <span className="product-mrp">₹599</span>
                    <div>
                      ₹251.58 <span className="product-discount">(58%)</span>
                    </div>
                  </div>
                  <a href="#" className="add-btn">
                    Add
                  </a>
                </div>
                </SwiperSlide>
                <SwiperSlide key={3}>
                <div className="swiper-slide">
                  <img
                    src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product-1.jpg"}
                    alt="Product Image"
                    className="product-img"
                  />
                  <div className="product-name">
                    Everherb (by Pharmeasy) Amla ...
                  </div>
                  <div className="product-details">60 Capsule(s) in Bottle</div>
                  <div className="product-price">
                    <span className="product-mrp">₹599</span>
                    <div>
                      ₹251.58 <span className="product-discount">(58%)</span>
                    </div>
                  </div>
                  <a href="#" className="add-btn">
                    Add
                  </a>
                </div>
                </SwiperSlide>
                <SwiperSlide key={4}>
                <div className="swiper-slide">
                  <img
                    src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product-1.jpg"}
                    alt="Product Image"
                    className="product-img"
                  />
                  <div className="product-name">
                    Everherb (by Pharmeasy) Amla ...
                  </div>
                  <div className="product-details">60 Capsule(s) in Bottle</div>
                  <div className="product-price">
                    <span className="product-mrp">₹599</span>
                    <div>
                      ₹251.58 <span className="product-discount">(58%)</span>
                    </div>
                  </div>
                  <a href="#" className="add-btn">
                    Add
                  </a>
                </div>
                </SwiperSlide>
                <SwiperSlide key={5}>
                <div className="swiper-slide">
                  <img
                    src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product-1.jpg"}
                    alt="Product Image"
                    className="product-img"
                  />
                  <div className="product-name">
                    Everherb (by Pharmeasy) Amla ...
                  </div>
                  <div className="product-details">60 Capsule(s) in Bottle</div>
                  <div className="product-price">
                    <span className="product-mrp">₹599</span>
                    <div>
                      ₹251.58 <span className="product-discount">(58%)</span>
                    </div>
                  </div>
                  <a href="#" className="add-btn">
                    Add
                  </a>
                </div>
                </SwiperSlide>
                <SwiperSlide key={6}>
                <div className="swiper-slide">
                  <img
                    src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product-1.jpg"}
                    alt="Product Image"
                    className="product-img"
                  />
                  <div className="product-name">
                    Everherb (by Pharmeasy) Amla ...
                  </div>
                  <div className="product-details">60 Capsule(s) in Bottle</div>
                  <div className="product-price">
                    <span className="product-mrp">₹599</span>
                    <div>
                      ₹251.58 <span className="product-discount">(58%)</span>
                    </div>
                  </div>
                  <a href="#" className="add-btn">
                    Add
                  </a>
                </div>
                </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          {/*-Similar Products-end-*/}
          {/*-Frequently Bought Together-start-*/}
          <div>
            <div className="col-12 mt-3 hospi-slider swiper">
              <div className="swiper-wrapper">
              <Swiper
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
    >
      <SwiperSlide key={1}>
                <div className="swiper-slide">
                  <img
                    src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product-1.jpg"}
                    alt="Product Image"
                    className="product-img"
                  />
                  <div className="product-name">
                    Everherb (by Pharmeasy) Amla ...
                  </div>
                  <div className="product-details">60 Capsule(s) in Bottle</div>
                  <div className="product-price">
                    <span className="product-mrp">₹599</span>
                    <div>
                      ₹251.58 <span className="product-discount">(58%)</span>
                    </div>
                  </div>
                  <a href="#" className="add-btn">
                    Add
                  </a>
                </div>
                </SwiperSlide>
          <SwiperSlide key={2}>
                <div className="swiper-slide">
                  <img
                    src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product-1.jpg"}
                    alt="Product Image"
                    className="product-img"
                  />
                  <div className="product-name">
                    Everherb (by Pharmeasy) Amla ...
                  </div>
                  <div className="product-details">60 Capsule(s) in Bottle</div>
                  <div className="product-price">
                    <span className="product-mrp">₹599</span>
                    <div>
                      ₹251.58 <span className="product-discount">(58%)</span>
                    </div>
                  </div>
                  <a href="#" className="add-btn">
                    Add
                  </a>
                </div>
                </SwiperSlide>
                <SwiperSlide key={3}>
                <div className="swiper-slide">
                  <img
                    src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product-1.jpg"}
                    alt="Product Image"
                    className="product-img"
                  />
                  <div className="product-name">
                    Everherb (by Pharmeasy) Amla ...
                  </div>
                  <div className="product-details">60 Capsule(s) in Bottle</div>
                  <div className="product-price">
                    <span className="product-mrp">₹599</span>
                    <div>
                      ₹251.58 <span className="product-discount">(58%)</span>
                    </div>
                  </div>
                  <a href="#" className="add-btn">
                    Add
                  </a>
                </div>
                </SwiperSlide>
                <SwiperSlide key={4}>
                <div className="swiper-slide">
                  <img
                    src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product-1.jpg"}
                    alt="Product Image"
                    className="product-img"
                  />
                  <div className="product-name">
                    Everherb (by Pharmeasy) Amla ...
                  </div>
                  <div className="product-details">60 Capsule(s) in Bottle</div>
                  <div className="product-price">
                    <span className="product-mrp">₹599</span>
                    <div>
                      ₹251.58 <span className="product-discount">(58%)</span>
                    </div>
                  </div>
                  <a href="#" className="add-btn">
                    Add
                  </a>
                </div>
                </SwiperSlide>
                <SwiperSlide key={5}>
                <div className="swiper-slide">
                  <img
                    src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product-1.jpg"}
                    alt="Product Image"
                    className="product-img"
                  />
                  <div className="product-name">
                    Everherb (by Pharmeasy) Amla ...
                  </div>
                  <div className="product-details">60 Capsule(s) in Bottle</div>
                  <div className="product-price">
                    <span className="product-mrp">₹599</span>
                    <div>
                      ₹251.58 <span className="product-discount">(58%)</span>
                    </div>
                  </div>
                  <a href="#" className="add-btn">
                    Add
                  </a>
                </div>
                </SwiperSlide>
                <SwiperSlide key={6}>
                <div className="swiper-slide">
                  <img
                    src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/product-1.jpg"}
                    alt="Product Image"
                    className="product-img"
                  />
                  <div className="product-name">
                    Everherb (by Pharmeasy) Amla ...
                  </div>
                  <div className="product-details">60 Capsule(s) in Bottle</div>
                  <div className="product-price">
                    <span className="product-mrp">₹599</span>
                    <div>
                      ₹251.58 <span className="product-discount">(58%)</span>
                    </div>
                  </div>
                  <a href="#" className="add-btn">
                    Add
                  </a>
                </div>
                </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          {/*-Frequently Bought Together-end-*/}
        </div>
        {/*cart-box-start*/}
        <div className="card">
          <div className="card-header">
            <div className="header-content">
              <div className="icon-bg">
                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/growth-img.svg"} alt="Growth Image" />
              </div>
              <div className="info-text">
                <span>34 people bought this recently</span>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="price-discount">
              <div className="price-option">
                <input id="default-radio-1" type="radio" name="default-radio" />
                <div className="price-info">
                  ₹256*
                  <span className="line-through">MRP ₹320</span>
                  <span className="discount">20% off</span>
                </div>
              </div>
              <div className="price-option">
                <input id="default-radio-2" type="radio" name="default-radio" />
                <div className="price-info">
                  ₹256
                  <span className="free-shipping">+ Free Shipping</span>
                  <span className="discount">20% off</span>
                </div>
              </div>
              <div className="with-careplan">
                <span>With</span>
                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/careplan-img.webp"} alt="Care Plan" />
              </div>
              <div className="tax-info">₹5.12/gm (Inclusive of all Taxes)</div>
              <div className="coupon-info">
                <span>
                  This offer price is valid on orders above ₹1200 with Coupon
                </span>
              </div>
              {/* <div className="zipcode">
                <div className="location-icon">
                  <svg
                    className="w-3"
                    fill="#099389"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                  </svg>
                  <input
                    id="zipcode"
                    name="zipcode"
                    type="text"
                    placeholder="Enter zipcode"
                  />
                  <div className="change-zipcode">
                    <a href="#">Change</a>
                  </div>
                </div>
              </div> */}
              <div className="delivery-info">
                <div className="delivery-by">
                  <svg
                    className="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z" />
                  </svg>
                  <span>DELIVERY BY</span>
                </div>
                <div className="see-next-step">See on Next Step.</div>
              </div>
              <div className="quantity">
              {quantity1 === 0 && (
              <Select
        options={options}
        optionLabel="selected Quantity"
        onChange={handleOnChange1}
        value={quantity1} 
        labelledBy="Select Quantity" 
        style={{backgroundColor: 'white',Color: 'white'}}    
      />
    )}
                <div className="quantity-info">of 100 ml Syrup</div>
              </div>
              <div className="style__cart-button___3CZnL">
            {
                  !cartItems.find(item => item.product_id === productdtail1.product_id) ? (
              <div className="add-to-cart" onClick={() => {
                        addToCart(productdtail1.product_id,quantity1)
                        notifyAddedToCart(productdtail1.product_name)
                      }
                      }>
                Add to cart
              </div>
              ) : (
                <div className="flex gap-4">
                    <button
                      className="add-to-cart"
                      onClick={() => {
                        addToCart(productdtail1.product_id,1)
                      }}
                    >
                      +
                    </button>
                    <p className='text-gray-600'>{cartItems.find(item => item.product_id === productdtail1.product_id).quantity}</p>
                    <button
                      className="add-to-cart"
                      onClick={() => {
                        const cartItem = cartItems.find((item) => item.product_id === productdtail1.product_id);
                        if (cartItem.quantity === 1) {
                          handleRemoveFromCart(productdtail1);
                        } else {
                          removeFromCart(productdtail1);
                        }
                      }}
                    >
                      -
                    </button>
                  </div>
                 )
                }
               </div>
              {/* <button className="add-to-cart">Add to Cart</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

	   <Footer />
       </div> 
  );
};
