import "bootstrap/dist/css/bootstrap.css";
import { Header } from "./Header";
import { Footer } from "./Footer";
import React, { useEffect, useState } from "react";
import { useNavigate} from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import {  toast } from 'react-toastify';
import{user_register} from '../../features/counter/apiSlice';
import { AccountErrors } from "../../validation/account";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
export const Register = () => {
  const navigate = useNavigate();
  const { status, user } = useSelector((state) => state.api);
  const [msg, setMsg] = useState("");
  const [inputValue,setInputValue]=useState({name:'',email:'',phone:'',password:''})
  const dispatch=useDispatch();
  const handleInput=(e)=>
    {
      
      setInputValue({...inputValue,[e.target.name]:e.target.value});
      //console.log(inputValue);
    }
  const handleSubmit = async (e) => {
	//console.log('submit');
    e.preventDefault();
    const result =await dispatch(user_register(inputValue));
    if (user_register.fulfilled.match(result)) {
    const wait = toast.loading("Please wait...");
			toast.update(wait, {
				render: `Welcome back ${result.meta.arg.email}`,
				type: "success",
				isLoading: false,
        autoClose: 3000,
				hideProgressBar: false,
				progress: undefined,
				closeOnClick: true,
				pauseOnHover: true,
			  });
       // navigate('/dashboard');
		   // Redirect to the dashboard on successful login
    }
    if (user_register.rejected.match(result)) {
      const wait = toast.loading("Please wait...");
      toast.update(wait, {
      render: AccountErrors(result.payload),
      type: "error",
     isLoading: false,
     autoClose: 3000,
     closeOnClick: true,
      pauseOnHover: true,
});
}
  };
useEffect(()=>
{
  if (status == 'succeeded') {
    //navigate('/dashboard'); // Redirect to the desired component after successful registration
    //dispatch(reset());
  }
  
},[status])
  return (
    <div>
      <Header />
      <div className="main-body">
  <div className="banner-image">
    <img className="banner-img" src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/dashboard-banner.webp"} alt="" />
  </div>
  <div className="inner">
    <div className=" doc-slider">
      <div
        className="image-holder swiper"
        style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL1}/assets/imgs/login-bg.webp)` }}
      >
      <div className="swiper-wrapper">
        <Swiper
    slidesPerView={1}
    className="swiper-wrapper"
    modules={[Autoplay]}
    autoplay={{
    delay: 3000, // delay between transitions in ms
    disableOnInteraction: false, // autoplay will not be disabled after user interactions
  }}
    breakpoints={{
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
    }}
  >
    
    <SwiperSlide key={1}>
          <div className="inner_img swiper-slide">
            <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/login-5.png"} alt="" />
          </div>
          </SwiperSlide>
          <SwiperSlide key={2}>
          <div className="inner_img swiper-slide">
            <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/login-1.jpg"} alt="" />
          </div>
          </SwiperSlide>
          <SwiperSlide key={3}>
          <div className="inner_img swiper-slide">
            <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/login-2.png"} alt="" />
          </div>
          </SwiperSlide>
          <SwiperSlide key={4}>
          <div className="inner_img swiper-slide">
            <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/login-3.png"} alt="" />
          </div>
          </SwiperSlide>
          </Swiper>
        </div>
        <div className="swiper-pagination" />
      </div>
    </div>
    <div className="login_form">
    <form onSubmit={handleSubmit}>
      <h3>Sign Up</h3>
      <div className="form-group">
        <input type="text" placeholder="Name" className="form-control" onChange={handleInput}
                      name="name"  value={inputValue.name}  required=""/>
      </div>
      <div className="form-wrapper">
        <input type="email" placeholder="Username" className="form-control" onChange={handleInput}
                      name="email"
					  value={inputValue.email}/>
        <i className="zmdi zmdi-account" />
      </div>
      <div className="form-wrapper">
        <input
          type="phone"
          onChange={handleInput}
					  value={inputValue.phone}
          placeholder="Phone"
          className="form-control"
        />
        <i className="zmdi zmdi-email" />
      </div>
      <div className="form-wrapper">
        <input
          type="password"
          placeholder="Password"
          className="form-control"
          value={inputValue.password}
          onChange={handleInput}
        />
        <i className="zmdi zmdi-lock" />
      </div>
      <button type="submit" className="animated-button" disabled={status === 'loading'}> {status === 'loading' ? 'Loading...' : 'Register'}</button>
    </form>
    </div>
  </div>
</div>

      <Footer />
    </div>
  );
};
