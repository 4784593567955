import React,{ useEffect, useState,useMemo } from "react";
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import { Order } from './Order';
import { DashboardLeft } from './DashboardLeft';
const token = JSON.parse(localStorage.getItem('user-info-login'));
export const OrderDetails = () => {
  //console.log(props); 
  const[order,setOrder]=useState([]);
  let register_id=(typeof token != 'undefined' && token != null ? (typeof token.currentUser != 'undefined' && token.currentUser != null ? token.currentUser.uid:token['id']):'');
  const fetchData3 = async() => { 
    await fetch(`${process.env.REACT_APP_BASE_URL}/order_list/${register_id}`)
      .then(response => {
        return response.json()
      })
      .then(data2 => {
      //console.log(data2.data);
      //console.log(data2.data[0].count);
      setOrder(data2.data);
      
      })
      //navigate('/schedule')
  }
  useEffect(() => {  
    fetchData3();    
      //fetchData2();
  }, [register_id])
  let total = order.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.pro_product_qty * currentValue.pro_product_price;
  }, 0);
  console.log(total);
  return (
     
    <div>
	<Header />
  
  <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
  <div className="section">
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="#">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
        </ol>
      </nav>
    </div>
  </div>
  <section className="doctors-dashboard bg-color-3">
       <DashboardLeft/>
        <div className="right-panel">
  <div className="container py-10 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-lg-12 col-xl-6">
        <div className="card" style={{ borderRadius: 10 }}>
          <div className="card-header px-4 py-5">
            <h5 className="text-muted mb-0">
              Thanks for your Order,{" "}
              <span style={{ color: "#a8729a" }}>{(token != null && token != undefined) ? ((typeof token.currentUser != 'undefined') ?token.currentUser.email:token['email']) : ''}</span>!
            </h5>
          </div>
          <div className="card-body p-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <p className="lead fw-normal mb-0" style={{ color: "#a8729a" }}>
                Receipt
              </p>
              {/* <p className="small text-muted mb-0">
                Receipt Voucher : 1KAU9-84UIL
              </p> */}
            </div>
            {
            
          order.map((order,index) => {
            if(register_id !==0)
              {
                //console.log(order);
            //let pro_total =  (order.pro_product_price * order.pro_product_qty)+(order.pro_shipping_price * order.pro_product_qty)+(order.pro_product_tax * order.pro_product_qty);
            return <Order key={index} item={order}  />
              }
            
          })} 
            
          
            <div className="d-flex justify-content-between pt-2">
              <p className="fw-bold mb-0">Order Details</p>
              <p className="text-muted mb-0">
                <span className="fw-bold me-4">Total</span> 
                
              </p>
            </div>
            <div className="d-flex justify-content-between pt-2">
              <p className="text-muted mb-0">Invoice Number : 788152</p>
              <p className="text-muted mb-0">
                <span className="fw-bold me-4">Discount</span> $19.00
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="text-muted mb-0">Invoice Date : 22 Dec,2019</p>
              <p className="text-muted mb-0">
                <span className="fw-bold me-4">GST 18%</span> 123
              </p>
            </div>
            <div className="d-flex justify-content-between mb-5">
              <p className="text-muted mb-0">Recepits Voucher : 18KU-62IIK</p>
              <p className="text-muted mb-0">
                <span className="fw-bold me-4">Delivery Charges</span> Free
              </p>
            </div>
          </div>
          <div
            className="card-footer border-0 px-4 py-5"
            style={{
              backgroundColor: "#a8729a",
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10
            }}
          >
            <h5 className="d-flex align-items-center justify-content-end text-white text-uppercase mb-0">
              Total paid: <span className="h2 mb-0 ms-2">{total.toFixed(2)}</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>

        </div>
      </section>
<Footer />
    </div>
  );
}