import useFormContext from "../hooks/useFormContext";
import React,{ useState } from 'react';
import { useDispatch } from 'react-redux';
import{signUp} from '../features/counter/apiSlice';
import { booking1 } from "../helper/formdata";
import { toast } from 'react-toastify';
import Form from './Form';
const Shipping = () => {
    const { data, handleChange} = useFormContext();
    const dispatch=useDispatch();
    const token = JSON.parse(localStorage.getItem("user-info-login"));
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };
  
    const handleSubmit = async (e) => {
      //console.log(data);
      e.preventDefault();
      try {
       
        if(token === null)
        {
          dispatch(signUp(data));
        }
        //console.log(params.id);
        const wait = toast.loading("Please wait...");
        //console.log(inputValue);
        const booked=await booking1(
          data
        );
        toast.update(wait, {
          render: `Welcome ${booked.message}`,
          type: "success",
          isLoading: false,
          autoClose: 10000,
          closeOnClick: true,
          pauseOnHover: true,
        }); 
        //window.location.href = '/schedule'
    //dispatch(booking(inputValue))      
      } catch (err) {
        //console.log(err);
      }
      //console.log(JSON.stringify(data))
  }
 
    const content = (
        
      <div className="form-content" id="step-2">
  <h3>Select members you want to insure</h3>
    <div className="form-group insurance-family">
      <div className="box">
        <div className="image-container">
          <input type="checkbox" id="checkbox1" className="checkbox"  checked={isChecked}
        onChange={handleCheckboxChange}/>
          <label htmlFor="checkbox1" className="checkbox-label" />
          <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/Self-husband.webp"} alt="Image 1" className="image" />
        </div>
        <p className="image-name">Self</p>
      </div>
      <div className="box">
        <div className="image-container">
          <input type="checkbox" id="checkbox2" className="checkbox" />
          <label htmlFor="checkbox2" className="checkbox-label" />
          <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/Wife.webp"} alt="Image 1" className="image" />
        </div>
        <p className="image-name">Wife</p>
      </div>
      <div className="box">
        <div className="image-container">
          <input type="checkbox" id="checkbox3" className="checkbox" />
          <label htmlFor="checkbox3" className="checkbox-label" />
          <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/Son.webp"} alt="Image 1" className="image" />
        </div>
        <p className="image-name">Son</p>
      </div>
      <div className="box">
        <div className="image-container">
          <input type="checkbox" id="checkbox4" className="checkbox" />
          <label htmlFor="checkbox4" className="checkbox-label" />
          <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/Daughter.webp"} alt="Image 1" className="image" />
        </div>
        <p className="image-name">Daughter</p>
      </div>
      <div className="box">
        <div className="image-container">
          <input type="checkbox" id="checkbox5" className="checkbox" />
          <label htmlFor="checkbox5" className="checkbox-label" />
          <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/Mother.webp"} alt="Image 1" className="image" />
        </div>
        <p className="image-name">Mother</p>
      </div>
      <div className="box">
        <div className="image-container">
          <input type="checkbox" id="checkbox6" className="checkbox" />
          <label htmlFor="checkbox6" className="checkbox-label" />
          <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/Father.webp"} alt="Image 1" className="image" />
        </div>
        <p className="image-name">Father</p>
      </div>
      <div className="box">
        <div className="image-container">
          <input type="checkbox" id="checkbox7" className="checkbox" />
          <label htmlFor="checkbox7" className="checkbox-label" />
          <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/Father-in-law.webp"} alt="Image 1" className="image" />
        </div>
        <p className="image-name">Father-in-law</p>
      </div>
      <div className="box">
        <div className="image-container">
          <input type="checkbox" id="checkbox8" className="checkbox"  checked={isChecked}
        onChange={handleCheckboxChange} />
          <label htmlFor="checkbox8" className="checkbox-label" />
          <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/Mother-in-Law.webp"} alt="Image 1" className="image" />
        </div>
        <p className="image-name">Mother-in-law</p>
      </div>
      <div className="box">
        <div className="image-container">
          <input type="checkbox" id="checkbox9" className="checkbox" />
          <label htmlFor="checkbox9" className="checkbox-label" />
          <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/Grand-father.webp"} alt="Image 1" className="image" />
        </div>
        <p className="image-name">Grand Father</p>
      </div>
      <div className="box">
        <div className="image-container">
          <input type="checkbox" id="checkbox10" className="checkbox" />
          <label htmlFor="checkbox10" className="checkbox-label" />
          <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/Grand-mother.webp"} alt="Image 1" className="image" />
        </div>
        <p className="image-name">Grand Mother</p>
      </div>
    </div>
    <Form/>
</div>

    )

    return content
}
export default Shipping