import React from "react";
import { Header } from './Header';
import { Footer } from './Footer';
import { Link } from "react-router-dom";
export const LabBooking = () => {
  function continue_book()
  {

  }
  function continue_book1()
  {
    
  }
  return (
    <div>
      <Header />
     <div className="clearfix"></div>
     <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br/>
  <div className="section">
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="#">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">About Us</li>
        </ol>
      </nav>
    </div>
  </div>
  <div className="section innerblock">
  <div className="booking">
    <div className="container">
    <div className="row">
        <div className="col-md-1" />
        <div className="col-md-10 main-box">
          <div className="row test-name">
            <div className="col-md-1" />
            <div className="col-md-10" align="center">
              <h3>Janta X Ray Clinic - Tilak Nagar</h3>
              <h4><i className="fa fa-map-marker" aria-hidden="true" /> (  4A/16, Block 4A, Tilak Nagar )</h4>
            </div>
            <div className="col-md-1" />
          </div>
          <div className="row">
            <div className="col-md-12" style={{padding: 0}}>
              <div className="my-table table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr style={{background: '#1CBBB4', color: '#fff'}}>
                      <th style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px'}}>Test Name</th>
                      <th style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px'}}>MRP</th>
                      <th style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px'}}>Discount</th>
                      <th style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px'}}>Payable</th>
                      <th style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px'}}>Special Requirements</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CBC Complete Blood Count</td>
                      <td><i className="fa fa-inr" aria-hidden="true" /> 330</td>
                      <td>50.00 % </td>
                      <td><i className="fa fa-inr" aria-hidden="true" />165 </td>
                      <td>No special preparation required</td>
                    </tr>
                    <tr id="h_charges" style={{display: 'none'}}>
                      <td><b>Home Collection Charges</b></td>
                      <td />
                      <td />
                      <td><b><i className="fa fa-inr" aria-hidden="true" /> 100/-</b></td>
                      <td />
                    </tr>
                    <tr>
                      <td><b>Total Payable</b></td>
                      <td />
                      <td />
                      <td><b><i className="fa fa-inr" aria-hidden="true" /> <span id="payble_with_home">165</span>/-</b></td>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <form action="https://bookmerilab.com/otp" method="post" name="booking_form"  id="booking_form">
            <div className="row choose">
              <div className="col-md-12" align="center">
                <div className="col-md-6" style={{float: 'left'}}>
                  <input type="radio" name="checked_collection" defaultChecked defaultValue={1} />
                  <label>Home Collection</label>
                </div>
                <div className="col-md-6 pull-right">
                  <input type="radio" name="checked_collection" defaultValue={2} />
                  <label>Lab</label>
                </div>
              </div>
              <div className="col-md-1" />
            </div>
            <div className="row form">
              <div className="col-md-2" />
              <input type="hidden" name="test_id" id="test_id" defaultValue={147} className="form-control" />
              <input type="hidden" name="lab_id" id="lab_id" defaultValue={102} className="form-control" />
              <input type="hidden" name="city" id="city" defaultValue="delhi" className="form-control" />
              <input type="hidden" name="lal_city_id" id="lal_city_id" defaultValue={9} className="form-control" />
              <input type="hidden" name="mrp_amount" id="mrp_amount" defaultValue={330} className="form-control" />
              <input type="hidden" name="insulin_pp_price" id="insulin_pp_price" defaultValue={0} className="form-control" />
              <input type="hidden" name="total_amount" id="total_amount" defaultValue={165} className="form-control" />
              <input type="hidden" name="message" id="message" defaultValue="CBC Complete Blood Count" className="form-control" />
              <input type="hidden" name="url" id="url" defaultValue="https://bookmerilab.com/booking?city=delhi&lab=102&test=147" className="form-control" />
              <div className="col-md-8 form-section">
                <div className="col-md-6 name">
                  <label className="col-sm-12 col-sm-12 control-label">Name</label>
                  <input type="text" name="patient_name" id="patient_name" autoComplete="off" className="form-control" />
                </div>
                <div className="col-md-6 date">
                  <label className="col-sm-12 col-sm-12 control-label">Date</label>
                  <input type="text" name="prefered_date" autoComplete="off" id="prefered_date" autcomplete="off" className="hasDatepicker" />
                </div>
                <div className="col-md-6 time">
                  <label className="col-sm-12 col-sm-12 control-label">Time</label>
                  <select className="form-control" name="prefered_time" id="prefered_time">
                    <option value>Select</option>
                    <option>6:00 AM</option>
                    <option>6:30 AM</option>
                    <option>7:00 AM</option>
                    <option>7:30 AM</option>
                    <option>8:00 AM</option>
                    <option>8:30 AM</option>
                    <option>9:00 AM</option>
                    <option>9:30 AM</option>
                    <option>10:00 AM</option>
                    <option>10:30 AM</option>
                    <option>11:00 AM</option>
                    <option>11:30 AM</option>
                    <option>12:00 PM</option>
                    <option>12:30 PM</option>
                    <option>1:00 PM</option>
                    <option>1:30 PM</option>
                    <option>2:00 PM</option>
                    <option>2:30 PM</option>
                    <option>3:00 PM</option>
                    <option>3:30 PM</option>
                    <option>4:00 PM</option>
                    <option>4:30 PM</option>
                    <option>5:00 PM</option>
                    <option>5:30 PM</option>
                    <option>6:00 PM</option>
                    <option>6:30 PM</option>
                    <option>7:00 PM</option>
                    <option>7:30 PM</option>
                    <option>8:00 PM</option>
                    <option>8:30 PM</option>
                    <option>9:00 PM</option>
                    <option>9:30 PM</option>
                    <option>10:00 PM</option>
                  </select>
                </div>
                <div className="col-md-6 phone">
                  <label className="col-sm-12 col-sm-12 control-label">Mobile No</label>
                  <input type="number" name="phone_no" id="phone_no" maxLength={10} autoComplete="off" className="form-control" />
                </div>
                <div className="col-md-6 email" style={{display: 'none'}}>
                  <label className="col-sm-12 col-sm-12 control-label">Email ID</label>
                  <input type="text" name="email_id" id="email_id" className="form-control" />
                </div>
                <div className="col-md-6 email" style={{display: 'none'}}>
                  <label className="col-sm-12 col-sm-12 control-label">Age</label>
                  <input type="text" name="age" id="age" className="form-control" />
                </div>
                <div className="col-md-12" id="hide_address" style={{display: 'none'}}>
                  <label className="col-sm-12 col-sm-12 control-label">Address</label>
                  <input type="text" name="address" id="address" className="form-control" />
                </div>
                {/*     <div class="col-md-7 coupon_codes_div">
                                                            <label class="col-sm-12 col-sm-12 control-label" style="color: #f36d24;font-weight: bold;">Enter Coupon Code</label>
                                                            <input type="text" name="coupon_code" id="coupon_code" autocomplete="off" style="border: 2px solid #4299d8;border-radius: 15px;" placeholder="Coupon Code" class="form-control">
                                                            <small id="coupon_msg"></small>
                                                        </div>
                                                        <div class="col-md-5 coupon_codes_div" style="padding-top: 15px;">
                                                            <label class="col-sm-12 col-sm-12 control-label" style="color: #f36d24;font-weight: bold;"></label>
                                                            <input type="button" class="btn btn-warning" onclick="check_coupon();" id="apply_coupon" value="Apply Coupon">
                                                        </div>*/}
                <div className="row col-md-12">
                  <div className="col-md-6 email" style={{display: 'none'}}>
                    <input type="radio" name="payment_method" style={{width: '0%', marginTop: '8px'}} defaultValue={0} defaultChecked="checked" />
                    <label className="col-md-6 col-xs-1 control-label">Pay at Lab</label>
                  </div>
                </div>
              </div>
              <div className="col-md-2" />
              <div className="col-md-12 book">
                <input type="button" className="btn btn-success" onClick={continue_book()} id="continue" defaultValue="Continue" />
                <input type="button" className="btn btn-success" onClick={continue_book1()} id="continue1" defaultValue="Continue" style={{display: 'none'}} />
                <input type="submit" className="btn btn-success" id="hide_dis" defaultValue="Book Now" style={{display: 'none'}} />
                <input type="button" className="btn btn-success" id="show_dis" defaultValue="Please wait..........." style={{display: 'none'}} />
              </div>
              <div className="col-md-12 book">
                <p className="risk_free">Pay At Lab or During Sample Collection, 100% Risk Free</p>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-1" />
      </div>
      
    </div>
    </div>
  </div>
  <Footer />
    </div>
  );
};
