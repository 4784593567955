import React from 'react';
import './index.css';
import App from './App';
import store from './app/store' 
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
//import { createRoot } from "react-dom/client";
import { createRoot } from 'react-dom/client';
import { CartProvider } from './context/cart.js';
import { ToastContainer } from 'react-toastify';
let container = null;
const defaultProps = {
  autoClose: 3000,
  // Add other default props here
};
document.addEventListener('DOMContentLoaded', function(event) {
  if (!container) {
    container = document.getElementById('root');
    const root = createRoot(container)
    root.render(
      <Provider store={store}>
         <CartProvider>
    <App />  
    <ToastContainer {...defaultProps}/> 
    </CartProvider>
    </Provider>
    );
  }
});

reportWebVitals();
