import React,{ useEffect, useState } from 'react';
import { Link,Route } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import { ProductDetails } from './ProductDetails';
export const Product = (product,j=1) => {
    //console.log(product);
  return (
    <>
      <div key={j} className="max-w-sm position-relative  mx-auto pb-3 bg-white border border-gray-200 rounded-lg  overflow-hidden hover-zoom">
        <Link key={product.item.product_id} to={`/productdetails/${product.item.product_id}`}> <img
          className="w-full h-56 object-cover px-4 transition-transform duration-300 hero_section_pro_d_img"
          src={product.item.pi_name}
          alt="Product Image"
        />
        </Link>
        <div className="top-left-badges">
          <div className="badge badge-discount">-20%</div>
          <div className="badge badge-new">New</div>
        </div>
        <div className="px-4">
          <h2 className="text-lg font-semibold mb-2 .multiline-ellipsis">
            {product.item.product_name}
          </h2>
          <div className="text-gray-500 text-sm mb-2">
            <span className="line-through">MRP ₹399.00</span>
            <div className="relative">
              <img src="" alt="" />
              <span className="text-red-600 text-xs absolute top-[3px] left-0 font-semibold ml-2">
                48% OFF
              </span>
            </div>
          </div>
          <div className="text-2xl font-bold text-gray-800">₹207.48</div>
        </div>
      </div>
      </>
      );
}