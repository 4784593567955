import React,{ useEffect, useState } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { fetchDataWithRetry } from "../../helper/formdata";
export const Team = () => {  
  const [testimonial, setTestimonial] = useState([]);
  const [error, setError] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL1; // Ensure this is set correctly in your environment variables
  const backgroundImageUrl = `${baseUrl}/assets/imgs/banner-team.webp`;
  const fetchData1 = async() => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/testimonial`)
      .then(response => {
        return response.json()
      })
      .then(data1 => {
		  
        setTestimonial(data1.data)
		//console.log(category);
      })
  }
  useEffect(() => { 
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/testimonial`).then((testimonial) => {
        
      setTestimonial(testimonial.data);
  
    }).catch((error) => {
   setError(error);
   });   
	//fetchData1();	
  //fetchData2();	
  }, []);
  // const fetchData2 = async() => {
  //   await fetch(`${process.env.REACT_APP_BASE_URL}/center_lab`)
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(data1 => {
		  
  //       setCenter(data1.data)
	// 	//console.log(category);
  //     })
  // }
  
  return (
    <div>
	<Header />  
  <section
    className="team-banner"
    style={{ backgroundImage: `url(${backgroundImageUrl})` }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="team-banner-content">
            <h2>Our Team</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="our-team-box">
    <div className="container">
      {testimonial.map((item) => {
        const { id,comment,designation, image,postedby } = item;
        return (
      <div className="row d-flex align-items-center  team-main-box" key={id}>
        <div className="col-md-4">
          <div className="team-image-box">
            <img src={image} alt="doctor image" />
          </div>
        </div>
        <div className="col-md-8">
          <div className="our-team-contect-box">
            <h3>{postedby}</h3>
            <h4>{designation}</h4>
            {comment}
          </div>
        </div>
      </div>
      );
					})}
    </div>
  </section>
	   <Footer />
    </div>
  );
};
