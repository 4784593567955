import React, { useEffect, useState } from "react";
import Geocode from "react-geocode";
import { getDistance, getPreciseDistance,geolib,findNearest,getAreaOfPolygon } from 'geolib';
import axios from "axios";

export const LocationComp = (lat=28.4981137, lng=77.06076) => {
  const [medical, setMedical] = useState([]);
  const google = window.google = window.google ? window.google : {}
  var map;
var service;
var infowindow;
  //const createMarker = window.createMarker = window.createMarker ? window.createMarker : {}
  //const initMap = window.initMap = window.initMap ? window.initMap : {}
  // This example requires the Places library. Include the libraries=places
// parameter when you first load the API. For example:
// <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places">
function initMap() {
  // Create the map.
  
  const pyrmont = { lat: 28.646650, lng: 77.050090 };
  const map = new google.maps.Map(document.getElementById("map"), {
    center: pyrmont,
    zoom: 17,
    mapId: "4b8519d75776972d",
  });
  // Create the places service.
  const service = new google.maps.places.PlacesService(map);
  //console.log(service);
  let getNextPage;
  const moreButton = document.getElementById("more");

  

  // Perform a nearby search.
  service.nearbySearch(
    { location: pyrmont, radius: 500, type: "store" },
    (results, status, pagination) => {
      console.log(status);
      if (status !== "OK" || !results) return;
      
      setMedical(results);
      // moreButton.disabled = !pagination || !pagination.hasNextPage;
      // if (pagination && pagination.hasNextPage) {
      //   getNextPage = () => {
      //     // Note: nextPage will call the same handler function as the initial call
      //     pagination.nextPage();
      //   };
      // }
    },
  );
}
useEffect(() => {
window.initMap = initMap;
},[]);
return (
  <>
  <div id='map'></div>
     
   </>
  );
}
