import useFormContext from "../hooks/useFormContext"
import Form from './Form';
const Billing = () => {
  const { data, handleChange } = useFormContext()
  const token = JSON.parse(localStorage.getItem('user-info-login')); 
    const content = (
      <div className="form-content active" id="step-1">
  <h3>Personal Information</h3>
    <div className="form-group">
      <label htmlFor="name">Full Name</label>
      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="FirstName"
                        pattern="([A-Z])[\w+.]{1,}"
                        value={(token != null) ? token['name'] : data.name}
                        onChange={handleChange}
                        required=""
                    />
    </div>
    <div className="form-group">
      <label htmlFor="email">Email</label>
      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="email"
                        pattern="([A-Z])[\w+.]{1,}"
                        value={(typeof token != 'undefined' && token != null) ? token.currentUser.email:data.email}
                        onChange={handleChange}
                    />
    </div>
    <div className="form-group">
      <label htmlFor="address">Phone Number</label>
      <input
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder="phone"
                        pattern="([A-Z])[\w+.]{1,}"
                        value={data.phone}
                        onChange={handleChange}
                    />
    </div>
    {(token == null) ?
    <div className="form-group">
      <label htmlFor="address">Password</label>
      <input type="password" name="password" placeholder="Password"   onChange={handleChange} required="required"/>
    </div>
    : ''}
    <Form/>
</div>
    )

    return content
}
export default Billing