import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "./Header";
import { AddressForm } from "./AddressForm.js";
import { Footer } from "./Footer";
import { CartContext } from "../../context/cart.js";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { signUp,signUp1 } from "../../features/counter/apiSlice";
import useRazorpay from "react-razorpay";
import {
  productlistt,productlisting1,productlist_status
} from "../../features/counter/productSlice";
export const CheckOut = (props) => {
  const token = JSON.parse(localStorage.getItem("user-info-login"));
  const dispatch=useDispatch();
    const productlist111 = useSelector(productlistt);
     const status = useSelector((state) => state.product.status);
  const {
    cartItems,
    addToCart,
    removeFromCart,
    clearCart,
    getCartTotal,
    quantity,
    setQuantity,
    total1,
  } = useContext(CartContext);
  const [Razorpay, isLoaded] = useRazorpay();
  const [inputValue, setInputValue] = useState({name:'',email:'',phone:'',password:''});
  const [productList, setProductList] = useState([]);
  const handleInput = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
    //console.log(inputValue);
  };
  const billingAddressRef = useRef(null);
  const billFirstName = useRef(null);
  const billLastName = useRef(null);
  const billAddress1 = useRef(null);
  const billAddress2 = useRef(null);
  const billCity = useRef(null);
  const billState = useRef(null);
  const billZipCode = useRef(null);
  const billingCityRef = useRef(null);
  const billingStateRef = useRef(null);
  const billingZipRef = useRef(null);

  const shippingAddressRef = useRef(null);
  const shippingCityRef = useRef(null);
  const shippingStateRef = useRef(null);
  const shippingZipRef = useRef(null);

  //
  const shippingFirstName = useRef(null);
  const shippingLastName = useRef(null);
  const shippingAddress1 = useRef(null);
  const shippingAddress2 = useRef(null);
  const shippingCity = useRef(null);
  const uname = useRef(null);
  const uemail = useRef(null);
  const shippingState = useRef(null);
  const shippingZipCode = useRef(null);
  const [sameAsBilling, setSameAsBilling] = useState(false);
  const [validationMessages, setValidationMessages] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const validateFields = () => {
    const messages = {};
    const billFirstName1 = billFirstName.current.value;
    const billAddresss1 = billAddress1.current.value;
    const billCity1 = billCity.current.value;
    const billState1 = billState.current.value;
    const billZipCode1 = billZipCode.current.value;
    const uemail1 = uemail.current.value;
    const uname1 = uname.current.value;
    //console.log(billFirstName1);
    // const billingAddress = billingAddressRef.current.value;
    // const billingCity = billingCityRef.current.value;
    // const billingState = billingStateRef.current.value;
    // const billingZip = billingZipRef.current.value;

    // const shippingAddress = shippingAddressRef.current.value;
    // const shippingCity = shippingCityRef.current.value;
    // const shippingState = shippingStateRef.current.value;
    // const shippingZip = shippingZipRef.current.value;

    // Billing address validation
    if (!uname1) messages.uname = 'Name is required.';
    if (!uemail1) messages.uemail = 'Email is required.';
    if (!billFirstName1) messages.billFirstName = 'Billing firstname is required.';
    if (!billAddresss1) messages.billAddress1 = 'Billing Address is required.';
    if (!billState1) messages.billState = 'Billing State is required.';
    if (!billZipCode1) messages.billZipCode = 'Billing Zipcode is required.';
    // if (!billingState) messages.billingState = 'Billing state is required.';
    // if (!billingZip) messages.billingZip = 'Billing zip is required.';

    // Shipping address validation
    // if (!shippingAddress) messages.shippingAddress = 'Shipping address is required.';
    // if (!shippingCity) messages.shippingCity = 'Shipping city is required.';
    // if (!shippingState) messages.shippingState = 'Shipping state is required.';
    // if (!shippingZip) messages.shippingZip = 'Shipping zip is required.';

    return messages;
  };
  const toggleDiv = () => {
    setIsVisible((prevState) => !prevState);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const messages = validateFields();
    setValidationMessages(messages);

    if (Object.keys(messages).length === 0) {
      // No validation errors, proceed with form submission logic
       //e.preventDefault();
    if (token === null) {
      dispatch(signUp1(inputValue));
    }

    try {
      let res = await fetch(`${process.env.REACT_APP_BASE_URL}/order`, {
        method: "POST",
        body: JSON.stringify({
          order_first_name: billFirstName.current.value,
          order_status: "pending",
          order_total: getCartTotal(),
          order_subtotal: getCartTotal(),
          order_last_name: billLastName.current.value,
          order_address: billAddress1.current.value,
          cartItems: cartItems,
          register_id:
            typeof token.currentUser != "undefined"
              ? token.currentUser.uid
              : token["id"],
        }),
      });
      let resJson = await res.json();
      if (resJson.status != "") {
        let order_id = resJson.data;
        handlePayment(order_id, getCartTotal());

        //setMessage(resJson.message);
      } else {
        //setMessage(resJson.message);
      }
    } catch (err) {
      console.log(err);
    }
    }
  };
  let oder_update = async (order_id, transction_id) => {
    //e.preventDefault();
    try {
      let res1 = await fetch(
        `${process.env.REACT_APP_BASE_URL}/order_details_update`,
        {
          method: "POST",
          body: JSON.stringify({
            order_id: order_id,
            order_status: "Success",
            scc_id: transction_id,
          }),
        }
      );
      let resJson = res1.json();
      if (resJson.status != "") {
        let order_id = resJson.data;

        //setMessage(resJson.message);
      } else {
        //setMessage(resJson.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePayment = useCallback(
    (params, total_amount) => {
      let tMoney = total_amount * 100;
      let stringMoney = tMoney.toString();
      let totalMoney = Number(stringMoney);

      // console.log(amount.toString());
      const options = {
        key: "rzp_test_oPqr8wgT1tTldY",
        amount: totalMoney,
        currency: "INR",
        name: "BooklabOnine",
        description: "Test Transaction",
        image: "https://booklabonline.com/assets/img/logo.png",

        handler: (res) => {
          oder_update(params, res.razorpay_payment_id);
          let order_id = params;
          let payment_id = res.razorpay_payment_id;
          navigate("/thankyou", {
            state: {
              order_id,
              payment_id,
            },
          });
          //console.log(res.razorpay_payment_id);
        },
        prefill: {
          name: billFirstName,
          email: "youremail@example.com",
          contact: "9999999999",
        },
        notes: {
          address: billAddress1,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    },
    [Razorpay]
  );
  useEffect(() => {

    if (status === 'idle') {
      dispatch(productlisting1());
    }
    if(status === 'succeeded')
      {
        setProductList(productlist111);
      }
  }, [status, dispatch]);

  const navigate = useNavigate();
  const initialOptions = {
    clientId:
      "Aeo1eUOgdlOA4ougMWUsOt8skaLvpswzL_2v9lYZbsRXXfOxzXaRA0UyWWs86z4lqHEhPhcFs-DvrOwT",
    currency: "USD",
    intent: "capture",
  };
  // let handleSubmit1 = async (e) => {
  //   //e.preventDefault();
  //   if (token === null) {
  //     dispatch(signUp1(inputValue));
  //   }

  //   try {
  //     let res = await fetch(`${process.env.REACT_APP_BASE_URL}/order`, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         order_first_name: billFirstName,
  //         order_status: "pending",
  //         order_total: getCartTotal(),
  //         order_subtotal: getCartTotal(),
  //         order_last_name: billLastName,
  //         order_address: billAddress1,
  //         cartItems: cartItems,
  //         register_id:
  //           typeof token.currentUser != "undefined"
  //             ? token.currentUser.uid
  //             : token["id"],
  //       }),
  //     });
  //     let resJson = await res.json();
  //     if (resJson.status != "") {
  //       let order_id = resJson.data;
  //       handlePayment(order_id, getCartTotal());

  //       //setMessage(resJson.message);
  //     } else {
  //       //setMessage(resJson.message);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const handleCheckboxChange = () => {
    setSameAsBilling(!sameAsBilling);
    if (!sameAsBilling) {
      // Copy billing address to shipping address
      shippingFirstName.current.value = billFirstName.current.value;
      shippingLastName.current.value = billLastName.current.value;
      shippingAddress1.current.value = billAddress1.current.value;
      shippingState.current.value = billState.current.value;
      shippingZipCode.current.value = billZipCode.current.value;
    } else {
      // Clear shipping address fields
      shippingFirstName.current.value = '';
       shippingLastName.current.value = '';
       shippingAddress1.current.value = '';
      shippingState.current.value = '';
      shippingZipCode.current.value = '';
    }
  };
  return (
    <div>
      <Header />
      <div className="main-body main-checkout">
  <div className="banner-image">
    <img className="banner-img" src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/checkout.webp"} alt="" />
  </div>
  <div className="checkout_page">
    <div className="container">
      <div className="main-checkout">
        <div className="w-75">
          <div className="top">
            <div className="logo">
              <span>CHECKOUT</span>
            </div>
            <div className="login">
              <div className="account">
                <Link to="/login">Have An Account?</Link>
              </div>
            </div>
          </div>
          {/* Existing-Address */}
          <AddressForm />
          <form className="shipping-form" onSubmit={handleSubmit}>
            <div className="ship_add">
              <span> EXISTING ADDRESS</span>
              <i className="fa-solid fa-question" />
            </div>
            <div className="form-row-exist">
              <div className="form-group-exist">
                <label htmlFor="street-address">
                  Name
                  <span>*</span>
                </label>
                <input
                      type="text"
                      id="name"
                      name="name"
                      ref={uname}
                      placeholder="name"
                      value={inputValue.name}
                      onChange={handleInput}
                    />
                    {validationMessages.uname && (
            <p style={{ color: 'red' }}>{validationMessages.uname}</p>
          )}
              </div>
            </div>
            <div className="form-group-exist">
              <label htmlFor="Email-address">
                Email
                <span>*</span>
              </label>
              <input
              type="text"
              id="email"
              name="email"
              ref={uemail}
              placeholder="email"
              value={
                token != null && token != undefined
                  ? typeof token.currentUser != "undefined"
                    ? token.currentUser.email
                    : token["email"]
                  : inputValue.email
              }
              onChange={handleInput}
            />
            {validationMessages.uemail && (
    <p style={{ color: 'red' }}>{validationMessages.uemail}</p>
  )}
            </div>
            <div className="form-group-exist">
              <label htmlFor="Password">Password</label>
              {token == null ? (
                <input
                  type="text"
                  id="password"
                  name="password"
                  placeholder="password"
                  value={inputValue.password}
                  onChange={handleInput}
                />
              ) : (
                ""
              )}
            </div>
            <div className="form-group-exist">
              <label htmlFor="Phone">Phone</label>
              <input
              type="text"
              id="phone"
              name="phone"
              placeholder="Phone"
              value={inputValue.phone}
              onChange={handleInput}
            />
            </div>
          {/* Existing-Address-End */}
          <div className="shipping">
            <span>SHIPPING</span>
          </div>
          <button onClick={toggleDiv} className="animated-button">
          {isVisible ? "Hide" : "Show"}+ Add New Address
          </button>
          {isVisible && (
          <div
            id="toggle-div"
            className="shipping-billing"
          >
            <div className="shipping_address">
              <div className="ship_add">
                <span> SHIPPING ADDRESS</span>
                <i className="fa-solid fa-question" />
              </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="first-name">
                      First Name
                      <span>*</span>
                    </label>
                    <input
                    type="text"
                    id="shipFirstName"
                    name="shipFirstName"
                    placeholder="Ship First Name"
                    ref={shippingFirstName}
                  />
                   {validationMessages.shippingFirstName1 && (
            <p style={{ color: 'red' }}>{validationMessages.shippingFirstName1}</p>
          )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="last-name">
                      Last Name
                      <span>*</span>
                    </label>
                    <input
                    type="text"
                    id="shipLastName"
                    name="shipLastName"
                    ref={shippingLastName}
                    placeholder="Ship Last Name"
                  />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="street-address">
                    Address
                    <span>*</span>
                  </label>
                  <input
                      type="text"
                      id="shipAddress1"
                      name="shipAddress1"
                      ref={shippingAddress1}
                      placeholder="Ship Address"
                    />
                </div>
                <div className="form-group">
                  <label htmlFor="apt">2nd Address line</label>
                  <input
                  type="text"
                  id="shipAddress2"
                  name="shipAddress2"
                  placeholder="ship Address2"
                  ref={shippingAddress2}
                />
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="street-address">City</label>
                    <input
                    type="text"
                    id="shipCity"
                    name="ship-city"
                    placeholder="Ship City"
                  />
                  </div>
                  <div className="form-group">
                    <label htmlFor="province">
                      State
                      <span>*</span>
                    </label>
                    <select
                      id="shipState"
                      name="shipState"
                      ref={shippingState}
                    >
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group postal-code-group">
                    <label htmlFor="postal-code">
                      Zip Code
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="shipZipCode"
                      name="shipZipCode"
                      placeholder="Zip Code"
                      maxLength="5"
                      ref={shippingZipCode}
                    />
                  </div>
                </div>
                <div>
                  <label className="form-control">
                    <input type="checkbox" checked={sameAsBilling} onChange={handleCheckboxChange} name="checkbox" />
                    Shipping address same as billing address
                  </label>
                </div>
            </div>
            <div className="billing_address">
              <div className="ship_add">
                <span> BILLING ADDRESS</span>
                <i className="fa-solid fa-question" />
              </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="first-name">
                      First Name
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="billFirstName"
                      name="billFirstName"
                      placeholder="First Name"
                      ref={billFirstName}
                    />
                    {validationMessages.billFirstName && (
            <p style={{ color: 'red' }}>{validationMessages.billFirstName}</p>
          )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="last-name">
                      Last Name
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="billLastName"
                      name="billLastName"
                      placeholder="Last Name"
                      ref={billLastName}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="street-address">
                    Address
                    <span>*</span>
                  </label>
                  <input
                      type="text"
                      id="billAddress1"
                      name="billAddress1"
                      placeholder="Address"
                      ref={billAddress1}
                    />
                   {validationMessages.billAddress1 && (
            <p style={{ color: 'red' }}>{validationMessages.billAddress1}</p>
          )}
                </div>
                <div className="form-group">
                  <label htmlFor="apt">2nd Address line</label>
                  <input
                  type="text"
                  id="billAddress2"
                  name="billAddress2"
                  placeholder="Apt. 2"
                  ref={billAddress2}
                />
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="street-address">City</label>
                    <input
                      type="text"
                      id="billCity"
                      name="billCity"
                      placeholder="City"
                      ref={billCity} 
                    />
                    {validationMessages.billCity && (
            <p style={{ color: 'red' }}>{validationMessages.billCity}</p>
          )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="province">
                      State
                      <span>*</span>
                    </label>
                    <select
                      id="billState"
                      name="billState"
                      ref={billState}
                    >
                      {validationMessages.billState && (
            <p style={{ color: 'red' }}>{validationMessages.billState}</p>
          )}
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group postal-code-group">
                    <label htmlFor="postal-code">
                      Zip Code
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="billZipCode"
                      name="billZipCode"
                      placeholder="Zip Code"
                      maxLength="5"
                      ref={billZipCode}
                    />
                    {validationMessages.billZipCode && (
            <p style={{ color: 'red' }}>{validationMessages.billZipCode}</p>
          )}
                  </div>
                </div>
            </div>
          </div>
          )}
          <div className="shipping_method">
            <div className="ship_method">
              <span>SHIPPING METHOD</span>
              <i className="fa-solid fa-question" />
            </div>
            <span>
              Enter a shipping address to see accurale shipping options for your
              order.
            </span>
          </div>
          <div className="gift_options">
            <span>GIFT OPTIONS</span>
          </div>
          <div className="gift-label">
            <span> This is a gift </span>
          </div>
          <div className="gift_card">
            <i className="fa-solid fa-gift" />
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque
              asperiores nam ab placeat? Fuga molestiae assumenda cum harum
              tenetur, obcaecati quos cumque.{" "}
            </p>
          </div>
          <div className="horizontal-line" />
          <div className="countinue_btn">
            <button className="countinue_btn1" type="submit">COUNTINUE</button>
          </div>
          <div className="w-34 right-side-bar-m">
            <div className="summary">
              <div className="order_summary">
                <div className="sum-head">
                  <span>ORDER SUMMARY</span>
                </div>
                <div className="billing">
                  <div className="subtotal flex jutyfy_between ">
                    <span>Subtotal</span>
                    <span>{total1}</span>
                  </div>
                  <div className="subtotal flex jutyfy_between ">
                    <span>Texes</span>
                    <span>-</span>
                  </div>
                  <div className="subtotal flex jutyfy_between ">
                    <span>Shipping (2 items)</span>
                    <span>-</span>
                  </div>
                  <div className="horizontal-line-34" />
                  <div className="total flex jutyfy_between ">
                    <span>TOTAL</span>
                    <span>$74.98 CDN</span>
                  </div>
                  <div className="discount_btn">
                    <a href="">APPLY A PROMO CODE OR DISCOUNT</a>
                  </div>
                </div>
              </div>
              <div className="bag-summary">
                <div className="beg_sum-head">
                  <span>BAG SUMMARY</span>
                  <div className="tow_round">
                    <i className="fa-solid fa-2" />
                  </div>
                </div>
                <div className="Arrives_days">
                  <span>Arrives in 4-7 days</span>
                </div>
                {typeof productList != "undefined" &&
                productList.length > 0 &&
                cartItems.map((item, index) => {
                  const product_det = productList.find(
                    (product11) => product11.product_id === item.product_id
                  );
                  return (
                <div className="flex my_order" key={index}>
                  <div className="w-50">
                    <img src={
                            product_det.pi_name !== ""
                              ? product_det.pi_name
                              : ""
                          } alt="" />
                  </div>
                  <div className="w-50">
                    <div className="Product_dt">
                      <span className="title">
                        <b>{product_det.product_name}</b>
                      </span>
                      <div>
                        <span className="size">1300123-025-XLffff</span>
                      </div>
                      <div>
                        <span className="color">
                          Quantity:{item.quantity}
                        </span>
                      </div>
                      <div className="full-price">
                        <span className="price">$49.00 CDN </span>
                        <span className="red_text">$39.49 CDN </span>
                      </div>
                    </div>
                  </div>
                </div>
                );
                })}
              </div>
            </div>
          </div>
          <div className="payment">
            <div className="payment-button">
              <span className="payment-text">PAYMENT</span>
              <div className="payment-options">
                <span className="gift text-underline">Gift Card</span>
                <span className="credit_card text-underline">Credit Card</span>
                <span className="paypal text-underline">PayPal</span>
              </div>
            </div>
            <div className="contact_information ">
              <span className="payment-text">CONTACT INFO</span>
              <div className="payment-options">
                <span>Email and phone</span>
              </div>
            </div>
          </div>
          </form>
        </div>
        <div className="w-34 right-side-bar-f">
          <div className="summary">
            <div className="order_summary">
              <div className="sum-head">
                <span>ORDER SUMMARY</span>
              </div>
              <div className="billing">
                <div className="subtotal flex jutyfy_between ">
                  <span>Subtotal</span>
                  <span>{getCartTotal()}</span>
                </div>
                <div className="subtotal flex jutyfy_between ">
                  <span>Tex</span>
                  <span>-</span>
                </div>
                <div className="subtotal flex jutyfy_between ">
                  <span>Shipping (2 items)</span>
                  <span>-</span>
                </div>
                <div className="horizontal-line-34" />
                <div className="total flex jutyfy_between ">
                  <span>TOTAL</span>
                  <span>$74.98 CDN</span>
                </div>
                <div className="discount_btn">
                  <a href="">APPLY A PROMD CODE OR DISCOUNT</a>
                </div>
              </div>
            </div>
            <div className="bag-summary">
              <div className="beg_sum-head">
                <span>BAG SUMMARY</span>
                <div className="tow_round">
                  <i className="fa-solid fa-2" />
                </div>
              </div>
              <div className="Arrives_days">
                <span>Arrives in 4-7 days</span>
              </div>
              {typeof productList != "undefined" &&
                productList.length > 0 &&
                cartItems.map((item, index) => {
                  const product_det = productList.find(
                    (product11) => product11.product_id === item.product_id
                  );
                  return (
                <div className="flex my_order" key={index}>
                  <div className="w-50">
                    <img src={
                            product_det.pi_name !== ""
                              ? product_det.pi_name
                              : ""
                          } alt="" />
                  </div>
                  <div className="w-50">
                    <div className="Product_dt">
                      <span className="title">
                        <b>{product_det.product_name}</b>
                      </span>
                      <div>
                        <span className="size">1300123-025-XLffff</span>
                      </div>
                      <div>
                        <span className="color">
                          Quantity:{item.quantity}
                        </span>
                      </div>
                      <div className="full-price">
                        <span className="price">$49.00 CDN </span>
                        <span className="red_text">$39.49 CDN </span>
                      </div>
                    </div>
                  </div>
                </div>
                );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      <Footer />
    </div>
  );
};
