import React from "react";
import { Header } from './Header';
import { Footer } from './Footer';
export const Health = () => {
  return (
    <div>
	<Header />
      <h1>Health</h1>
	   <Footer />
    </div>
  );
};
