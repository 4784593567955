import React,{ useEffect, useState,useRef } from "react";
import { Link,useLocation } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import{register,signUp} from '../../features/counter/apiSlice';
import { booking,booking1 } from "../../helper/formdata";
import {ReadMore} from './ReadMore';
import TimePicker from "react-bootstrap-time-picker";
import { useDispatch, useSelector } from "react-redux";
export const HealthDetails = () => {
  const formRef=useRef();
  const token = JSON.parse(localStorage.getItem("user-info-login"));
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [startDate, setStartDate] = useState(new Date());
  const location = useLocation();
  const { ids } = location.state || { ids: "none" };
  const [labid, setLabId] = useState(ids);
  const [inputValue,setInputValue]=useState({name:'',email:(typeof token != 'undefined' && token != null) ? token.currentUser.email:'',phone:'',password:'',startDate:startDate,subject:'',comments:'',age:'',gender:'',time:'',reference_id:labid,register_id:(typeof token != 'undefined' && token != null ) ? token.currentUser.uid:'',booking_status:'booked',type:'health package'})
  const handleClose = () => setShow(false);
  const [ health, setHealth] = useState([]);  
  const [name, setName] = useState("");
  const [selected, setSelected] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [time, setTime] = useState("10");	
  const [message, setMessage] = useState(""); 
  const [password, setPassword] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL1; // Ensure this is set correctly in your environment variables
  const backgroundImageUrl = `${baseUrl}/assets/imgs/bannerhealth.webp`;
  const bannerFormRef = useRef(null);
  const dispatch = useDispatch();
   useEffect(() => {
    if (ids !== 'none')
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/health_list/${ids}`)
      .then((response) => { //console.log(response.data.data[0].health_title);
        setHealth(response.data.data[0]); });
    }, [ids])   
  const labclick = () => {  
    //console.log('test'); 
    setShow(true);    
  };
  useEffect(() => {
    const bannerForm = bannerFormRef.current;

    if (!bannerForm) return;

    // Initially set margin-top to 0% on page load
    bannerForm.style.marginTop = '0%';
    bannerForm.style.position = ''; // Ensure the position is set to its default
    bannerForm.style.transition = 'margin-top 2s ease'; // Smooth transition for margin-top changes

    // After 5 seconds, set margin-top to -60%
    const timeout = setTimeout(() => {
      bannerForm.style.marginTop = '-60%';
    }, 5000);

    // Handle scroll event
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // On scroll, set margin-top back to 0%
      if (scrollTop > 100) {
        bannerForm.style.marginTop = '0%';
        bannerForm.style.position = 'sticky';
        bannerForm.style.top = '10%';
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up: remove timeout and event listener on unmount
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleInput=(e)=>
    {
      
      setInputValue({...inputValue,[e.target.name]:e.target.value});
      //console.log(inputValue);
    }
  const handleSubmit=async(e)=>
    {
      //console.log('test');
      e.preventDefault();
      if(token === null)
        {
          dispatch(signUp(inputValue));
          
        }
        else
        {
          const wait = toast.loading("Please wait...");
          //console.log(inputValue);
          const booked=await booking1(
            inputValue
          );
          toast.update(wait, {
            render: `Welcome ${booked.message}`,
            type: "success",
            isLoading: false,
            autoClose: 10000,
            closeOnClick: true,
            pauseOnHover: true,
          }); 
          window.location.href = '/schedule'
      //dispatch(booking(inputValue))
        }
    }
  return (
    <div>
      <Header/>
  <section
    className="health-banner"
    style={{ backgroundImage: `url(${backgroundImageUrl})` }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-8">
          <div className="health-banner-content">
            <h2>Basic Screening Package</h2>
            <p className="font_14">
              full body test, full body checkup, fullbody checkup, fullbody
              test, complete health, entire body
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="health-content-information">
    <div className="container">
      <div className="row">
        <div className="col-md-8 ">
          <div className="health-wrapp">
            <div className="health-heading-box">
              <h3>
                {health.health_title}
              </h3>
            </div>
            <div className="health-perameters">
              <h4>Includes 91 Parameters</h4>
              <div className="parameter-box">
                <p>Fasting</p>
                <h6>10-12 Hrs Required</h6>
              </div>
              <div className="parameter-box">
                <p>Get reports in</p>
                <h6>12 hrs</h6>
              </div>
              <div className="health-content">
                <h4>What is it for ?</h4>
                <p>
                  CBC, ESR, BSF, HbA1c, Lipid Profile, HsCRP, LFT, KFT, Thyroid
                  Profile Total, Vit B12, Vit D, Urine Routine
                </p>
                <h4>package Instructions</h4>
                <p>Age Group: Above 21 Years</p>
              </div>
              <div className="health-benifit">
                <h4>Benefits</h4>
                <ul>
                  <li>
                    <div className="b-image-box">
                      <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/doctor.png"} alt="icon" />
                    </div>
                    <p>Free Reports Consultation</p>
                  </li>
                  <li>
                    <div className="b-image-box">
                      <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/discount.png"} alt="icon" />
                    </div>
                    <p>Most Affordable Pricing</p>
                  </li>
                  <li>
                    <div className="b-image-box">
                      <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/blood-sample.png"} alt="icon" />
                    </div>
                    <p>On-time Sample Collection</p>
                  </li>
                  <li>
                    <div className="b-image-box">
                      <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/guarantee.png"} alt="icon" />
                    </div>
                    <p>NABL Certified Labs</p>
                  </li>
                </ul>
              </div>
              <div className="included-test">
                <h4>Included Tests</h4>
                <p>Package includes following parameters</p>
                <div className="test-box">
                  <h5>Complete Blood Count (CBC) (26)</h5>
                  <ul>
                    {typeof health.include != 'undefined' && health.include.length > 0 && health.include && (
                        <div>
                        {health.include.map((item1,j) => {
                          return (
                            <li key={item1+1}>{item1}</li>
                      );
                          })}
                          </div>
                          )}
                    </ul>
                </div>
                <div className="test-box">
                  <h5>Complete Blood Count (CBC) (26)</h5>
                  <ul>
                    <li>Absolute Basophils Count, Blood</li>
                    <li> Absolute Eosinophil Count, Blood</li>
                    <li>Absolute Lymphocyte Count, Blood</li>
                    <li>Absolute Monocyte Count, Blood</li>
                    <li>Absolute Neutrophil Count, Blood</li>
                    <li>Basophils</li>
                    <li>Eosinophils</li>
                    <li>Eosinophils</li>
                    <li>Hemoglobin Hb</li>
                    <li>Lymphocytes</li>
                    <li>MCH</li>
                    <li>MCHC</li>
                    <li>MCV</li>
                    <li>MENTZER INDEX9MCV/RCC</li>
                    <li>Monocytes</li>
                    <li>MPV Mean Platelet Volume</li>
                    <li>Neutrophils</li>
                    <li>PCT</li>
                    <li>PCV Haematocrit</li>
                    <li>PDW Platelet Distribution Width</li>
                    <li>Platelet Count Thrombocyte count</li>
                    <li>P - LCC</li>
                    <li>P - LCR</li>
                    <li>RBC Count</li>
                    <li>RDW-CV</li>
                    <li>RDW (Red Cell Distribution Width)</li>
                    <li>WBC-Total Counts Leucocytes</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="health-banner-form" ref={bannerFormRef}>
          <form ref={formRef} onSubmit={handleSubmit}>
            <input type="text" id="name" placeholder="Name" name="name" defaultValue={(token != null) ? token['name'] : ''}  onChange={handleInput} required="required" />
            <input type="text" id="email" placeholder="email" name="email" defaultValue={(token != null && token != undefined) ? ((typeof token.currentUser != 'undefined') ?token.currentUser.email:token['email']) : inputValue.email} onChange={handleInput} required="required"/>
            <input
                type="text"
                id="phone"
                placeholder="Phone Number"
                name="phone"
                defaultValue={(token != null) ? token['phone'] : ''} onChange={handleInput} required="required"
              />
               {(token == null) ?
                                            <input type="password" name="password" placeholder="Password"   onChange={handleInput} required="required"/>
                                        : ''}
               <select
                id="gender"
                name="gender"
                aria-placeholder="Select Gender"
                value={inputValue.gender} onChange={handleInput} required="required"
              >
                <option value="" disabled="" selected="">
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <input type="text" id="Age" value={inputValue.age} placeholder="Age" name="age" onChange={handleInput} />
              <DatePicker selected={startDate} minDate={startDate} value={startDate} onChange={(date) => setStartDate(date)} required="required"/>
              <div className="time-input">
              <TimePicker selected={time} start="10:00" end="21:00" value={time} step={30} onChange={handleInput} required="required"/>
              <span>:</span>
              <input
                type="number"
                id="minutes"
                name="minutes"
                min={0}
                max={59}
                placeholder="MM"
                required=""
              />
              <select id="ampm" name="ampm" required="">
                <option value="" disabled="" selected="">
                  AM/PM
                </option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
              <button className="form-btn">Book Appointment</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
	   <Footer />
    </div>
  );
};
