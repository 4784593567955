import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-bootstrap-time-picker";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { MultiSelect } from "react-multi-select-component";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import{signUp} from '../../features/counter/apiSlice';
import { booking1 } from "../../helper/formdata";
import axios from "axios";
import { getPreciseDistance } from "geolib";
import { useDispatch, useSelector } from "react-redux";
import {  arealist,currentcity } from "../../features/counter/apiSlice";
export const Labs = () => {
  const navigate = useNavigate();
  const areaslistt = useSelector(arealist);
  const city=useSelector(currentcity);
  const token = JSON.parse(localStorage.getItem("user-info-login"));
  const [selected, setSelected] = useState([]);
  const [selected1, setSelected1] = useState(city);
  const [searchlab, setSearchLab] = useState([]);
  const [labid, setLabId] = useState([]);
  const [labid1, setLabId1] = useState([]);
  const { state } = useLocation();
  const [labcategory, setLabCategory] = useState([]);
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [inputValue,setInputValue]=useState({name:'',email:(typeof token != 'undefined' && token != null) ? token.currentUser.email:'',phone:'',password:'',startDate:'',subject:'',comments:'',age:'',gender:'',time:'',reference_id:labid1,register_id:(typeof token != 'undefined' && token != null ) ? token.currentUser.uid:'',booking_status:'booked',type:'lab',parameter:selected})
  const [time, setTime] = useState('10:00');
  const handleClose = () => setShow(false);
  const [isValid, setIsValid] = useState(true);
  const [isValid1, setIsValid1] = useState(true);
  const [lt, setLt] = useState("");
  const [lng, setLng] = useState("");
  const datechange=(date)=>
    {
      //console.log(date);
      setStartDate(date);
      setInputValue({...inputValue,startDate:date});
       
    }
    const handleInput1=(time)=>
      {
        console.log(time);
        setInputValue({...inputValue,time:time});
        //setTime(time); 
      }
  //console.log(token);
  const openLinkInNewTab = (url) => {
    const newTab = window.open(url, "_blank", "noopener,noreferrer");
    if (newTab) newTab.opener = null;
  };
  navigator.geolocation.getCurrentPosition(function (position) {
    setLt(position.coords.latitude);
    setLng(position.coords.longitude);
  });
 
  //console.log(areaslistt);
  const dispatch = useDispatch();
  const handleInput=(e)=>
    {
      
      setInputValue({...inputValue,[e.target.name]:e.target.value});
      //console.log(inputValue);
    }
    const handleSubmit=async(e)=>
    {
      //console.log('test');
      e.preventDefault();
      if(token === null)
        {
          dispatch(signUp(inputValue));
          
        }
        else
        {
          //console.log(labid1);
          //console.log(inputValue);
          const wait = toast.loading("Please wait...");
          //console.log(inputValue);
          const booked=await booking1(
            inputValue
          );
          toast.update(wait, {
            render: `Welcome ${booked.message}`,
            type: "success",
            isLoading: false,
            autoClose: 10000,
            closeOnClick: true,
            pauseOnHover: true,
          }); 
          navigate('/schedule');
          //window.location.href = '/schedule'
      //dispatch(booking(inputValue))
        }
    }
  const handleOnChange = (value) => {
    if (value === null) {
      value = [];
    } else {
      setSelected(value);
    }
    //console.log(selected);
  };
  const handleOnChange1 = (value1) => {
    //console.log(value1);
    if (value1 === null) {
      value1 = [];
    } else {
      setSelected1(value1);
    }
    //console.log(selected1);
  };
  const labclick = (valu) => {
    //console.log(valu.lab_id);
    if (valu !== "") {
      setShow(true);
      setLabId(valu.lab_title);
      setLabId1(valu.lab_id);
      setInputValue({...inputValue,reference_id:valu.lab_id,type:'lab',register_id:(typeof token != 'undefined' && token != null ) ? token.currentUser.uid:'',parameter:selected,email:(typeof token != 'undefined' && token != null) ? token.currentUser.email:''});
    }
  };
  const fetchData2 = async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/lab_cat_list`)
      .then((response) => {
        return response.json();
      })
      .then((data2) => {
        //console.log(data2.data);
        setLabCategory(data2.data);
      });
  };

  if (state === null) {
    //console.log('test');
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/center_lab`)
      .then((response) => {
        setSearchLab(response.data.data);
      });
  }
  
  useEffect(() => {
    // If there is data, the form is valid
    setIsValid(selected1 ? true : false);
    setIsValid1(selected ? true : false);
  }, [selected, selected1]);
  useEffect(() => {
    if (state !== null) {
      //console.log(state.selected);
      //console.log(state.result.data);
      setSelected(state.selected);
      setSelected1(state.selected1);
      setSearchLab(state.result.data);
    }
    fetchData2();
  }, [state]);

  async function labList() {
    //let selected = {selected };
    if (selected1.length === 0) {
      setIsValid(false);
    } else if (selected1.length !== undefined) {
      setIsValid(true);
    } else if (selected.length === 0) {
      setIsValid1(false);
    } else {
      setIsValid1(true);
      let item = { selected };
      var content = { item: selected, city: selected1 };
      let result = await fetch(`${process.env.REACT_APP_BASE_URL}/search_lab`, {
        method: "POST",
        body: JSON.stringify(content),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      });
      result = await result.json();
      //console.log(result);
      if (result !== "") {
        navigate("/labs", {
          state: {
            result,
            selected,
            selected1,
          },
        });
      }
    }
  }
  return (
    <div>
      <Header />
<div className="main-body main-lablisting">
  <div className="banner-image">
    <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/dashboard-banner.webp"} alt="" />
  </div>
  <Modal show={show} onHide={handleClose}>
    <Modal.Body>
<div className="">
      <div className="popup-content">
       <ToastContainer />
          <h2>{labid}</h2>
          <div className="address">
              <span>Address :</span>
              <p>4A/1/6, Block 4A, Tilak Nagar</p>
          </div>
          <table>
          <thead>
              <tr>
                  <th>Test Name</th>
                  <th>Payable</th>
              </tr>
              </thead>
              {typeof searchlab != "undefined" &&
                searchlab.length > 0 &&
                searchlab && (
                  <>
                    {searchlab.map((item2, l) => {
                      const { lab_title, include } = item2;
                      let total = 0;
                      return (
                        <tbody key={lab_title}>
                          {typeof include != "undefined" &&
                            include.length > 0 &&
                            include && (
                              <>
                                {include.map((item3, j = 10) => {
                                  if (lab_title === labid) {
                                    if (item3.sell_price !== 0) {
                                      total += Number(
                                        item3.sell_price
                                      );
                                    } else {
                                      total += 0;
                                    }
                                    return (
                                      <tr key={j}>
                                        <td>{item3.lab_cat_title}</td>
                                        <td>
                                          <i
                                            className="fa fa-inr"
                                            aria-hidden="true"
                                          />{" "}
                                          {item3.market_price != null
                                            ? item3.market_price
                                            : 0}
                                        </td>
                                        <td>
                                          {item3.discount != null
                                            ? item3.discount
                                            : 0}
                                          .00 %{" "}
                                        </td>
                                        <td>
                                          <i
                                            className="fa fa-inr"
                                            aria-hidden="true"
                                          />
                                          {item3.sell_price != null
                                            ? item3.sell_price
                                            : 0}{" "}
                                        </td>
                                        <td>
                                          No special preparation
                                          required
                                        </td>
                                      </tr>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                              </>
                            )}
                          {total !== null &&
                          total !== undefined &&
                          total !== 0 ? (
                            <tr key={l}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <i
                                  className="fa fa-inr"
                                  aria-hidden="true"
                                ></i>{" "}
                                {total} /-
                              </td>
                            </tr>
                          ) : (
                            <tr></tr>
                          )}
                        </tbody>
                      );
                    })}
                  </>
                )}
          </table>
          <form onSubmit={handleSubmit}>
              <div className="field-1">
                  <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={
                    token != null ? token["name"] : inputValue.name
                  }
                  onChange={handleInput}
                  required="required"
                />
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={
                    typeof token != "undefined" &&
                    token != null
                      ? token.currentUser.email
                      : inputValue.email
                  }
                  onChange={handleInput}
                  required="required"
                />
              </div>
              {token == null ? (
                            <div className="field-1">
                              <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={inputValue.password}
                                onChange={handleInput}
                                required="required"
                              />
                            </div>
                          ) : (
                            ""
                          )}
              <div className="field-1">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Phone Number"
                            onChange={handleInput}
                            value={
                              token != null ? token["phone"] : inputValue.phone
                            }
                            required="required"
                          />
              </div>
              <div className="field-1">
              <select
                            className=""
                            name="gender"
                            value={inputValue.gender}
                            onChange={handleInput}
                            required="required"
                            style={{ border: "1px solid #999" }}
                          >
                            <option data-display="Select Your Gender">
                              Select Your Gender
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          <input
                            type="text"
                            name="age"
                            value={inputValue.age}
                            placeholder="Age"
                            onChange={handleInput}
                          />
              </div>
              <div className="field-1">
                  <DatePicker
                              label="Select Date"
                              selected={startDate}
                              onChange={datechange}
                              dateFormat="yyyy/MM/dd"
                              required="required"
                            />
                  <TimePicker
                              value={time}
                              onChange={handleInput1}
                              start="10:00"
                              end="21:00"
                              step={30}
                            />
              </div>
              <div className="popup-button-appointment">
              <button type="submit">Book Appointment</button></div>
          </form>
      </div>
  </div>
  </Modal.Body>
    <Modal.Footer style={{ width: "105%" }}>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" onClick={handleClose}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
  <div className="container">
    <div className="after-banner">
      <div className="after-banner-path">
        <span className="active-page">Home</span>
        <span>/</span>
        <span>About Us</span>
      </div>
    </div>
    <div className="main-title">
      <span>Sinus CT Scan Lab List in Gurgaon</span>
    </div>
    <div className="main-discount">
      <img className="discount-svg" src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/discount.svg"} alt="" />
      <span>
        {" "}
        Get 2% cashback on online payment of your Sinus CT Scan. T&amp;C Apply.
      </span>
    </div>
    <div className="listings">
      {typeof searchlab != "undefined" &&
      searchlab.length > 0 &&
      searchlab && (
        <>
          {searchlab.map((item, l) => {
            const { lab_title, include, lab_id, lab_lat, lab_long,lab_image } =
              item;
            let dis = getPreciseDistance(
              { latitude: lt, longitude: lng },
              {
                latitude: lab_lat != null ? lab_lat : "",
                longitude: lab_long != null ? lab_long : "",
              }
            );
            //const background = item.background;
            var url = "https://www.google.com/maps/dir/?api=1";
            var origin = "&origin=" + lt + "," + lng;
            var destination =
              "&destination=" + lab_lat + "," + lab_long;
            var newUrl = new URL(url + origin + destination);
            return (
      <div className="listing" key={l}>
        <img
          className="lab-machine-image"
          src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/labMachine.svg"}
          alt="Clinic Icon"
        />
        <div className="info">
          <h3>{lab_title}</h3>
          <div className="main-lab-listing-collection">
            <div className="lab-listing-partition-line">
              <i className="fa-solid fa-house" />
              <span>Home Collection</span>
            </div>
            <div className="lab-listing-partition-line">
              <i className="fa-solid fa-certificate" />
              <span>Certified By NABL</span>
            </div>
            <div>
              <span>1227 times booked this week</span>
            </div>
          </div>
          <div
            id={lab_title}
            className="accordian-body collapse"
          >
            <table>
              <thead>
                <tr>
                  <th>Test Name</th>
                  <th>MRP</th>
                  <th>Discount</th>
                  <th>Payable</th>
                </tr>
              </thead>
              <tbody>
              {typeof include != "undefined" &&
                                        include.length > 0 &&
                                        include && (
                                          <>
                                            {include.map((item1, j = 2) => {
                                              return (
                                                <tr
                                                  key={j}
                                                >
                                                  <td>
                                                    {item1.lab_cat_title}
                                                  </td>
                                                  <td>
                                                    {item1.market_price}
                                                  </td>
                                                  <td>
                                                    {item1.discount}%
                                                  </td>
                                                  <td>
                                                    {item1.sell_price}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </>
                                        )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="price">
          <button
            className="accordion-button1 side-dropdown book-btn collapsed"
            data-toggle="collapse"
            data-target={"#" + lab_title}
          >
            Price
          </button>
          <div className="distance-destination">
            <div>
              <span>{`Distance\n\n${dis} Meter\nOR\n${
                dis / 1000
              } KM`}</span>
            </div>
            <div>
              <button  onClick={() =>
                openLinkInNewTab(newUrl)
              } className="book-btn">Direction</button>
            </div>
          </div>
        </div>
        <button className="book-btn" value={lab_title}
        onClick={() =>
          labclick({ lab_title, lab_id })
        }>
          Book Appointment
        </button>
      </div>
      );
    })}
  </>
)}
    </div>
    <button id="toggle-btn" onclick="toggleView()">
      View More
    </button>
  </div>
  {/* Section-2 */}
  <div className="main-Healthians container">
    <div className="main-part">
      <div className="main-Healthians">
        <div className="Healthians">
          <div className="main-title">
            <span>Why Choose Healthians</span>
          </div>
          <div className="main-content">
            <p>
              Healthians is one of the most trusted players in diagnostics, with
              a network of fully automated labs across major cities in India,
              that are hardcoded with innovative technology.
            </p>
          </div>
          <div className="Healthian-cards">
            <div className="Healthian-cards-first">
              <div className="main-card">
                <div className="card-image">
                  <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/EEG.png"} alt="Healthians-1" />
                </div>
                <div className="card-content">
                  <span>1.5 lakh+ patients test with us every month</span>
                </div>
              </div>
              <div className="main-card">
                <div className="card-image">
                  <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/EEG.png"} alt="Healthians-1" />
                </div>
                <div className="card-content">
                  <span>Patients save an average of ₹700 on each scan</span>
                </div>
              </div>
            </div>
            <div className="Healthian-cards-second">
              <div className="main-card">
                <div className="card-image">
                  <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/EEG.png"} alt="Healthians-1" />
                </div>
                <div className="card-content">
                  <span>ISO and NABH certified scan centers</span>
                </div>
              </div>
              <div className="main-card">
                <div className="card-image">
                  <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/EEG.png"} alt="Healthians-1" />
                </div>
                <div className="card-content">
                  <span>100% reliable and accurate reports</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="consultation-section">
          <img
            className="lab-machine-image"
            src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/dp-2.png"}
            alt="Clinic Icon"
          />
          <div className="info">
            <span>Don't have a prescription? Consult a doctor online</span>
          </div>
          <button className="book-btn">Book consultation</button>
        </div>
      </div>
      <div className="main-part">
        <div className="Benifits">
          <div className="main-title">
            <span>Exclusive Benefits with Healthians</span>
          </div>
          <div className="Benifits-cards">
            <div className="main-Benifits-card">
              <div className="card-Benifits-image">
                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/banifits1.svg"} alt="Healthians-1" />
              </div>
              <div className="card-Benifits-content">
                <span>1.5 lakh+ patients test with us every month</span>
              </div>
            </div>
            <div className="main-Benifits-card">
              <div className="card-Benifits-image">
                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/banifits3.svg"} alt="Healthians-1" />
              </div>
              <div className="card-Benifits-content">
                <span>Patients save an average of ₹700 on each scan</span>
              </div>
            </div>
            <div className="main-Benifits-card">
              <div className="card-Benifits-image">
                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/banifits2.svg"} alt="Healthians-1" />
              </div>
              <div className="card-Benifits-content">
                <span>ISO and NABH certified scan centers</span>
              </div>
            </div>
            <div className="main-Benifits-card">
              <div className="card-Benifits-image">
                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/banifits4.svg"} alt="Healthians-1" />
              </div>
              <div className="card-Benifits-content">
                <span>100% reliable and accurate reports</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Section-3 */}
</div>
<Footer />
    </div>
  );
};
