import { FormProvider } from "../context/FormContext"
import { Header } from '../components/Pages/Header';
import { Footer } from '../components/Pages/Footer';
import { Link } from "react-router-dom";
import FormInputs from './FormInputs';
import { ToastContainer, toast } from 'react-toastify';
export const Insurance = () => {
  return (
    
    <FormProvider>
      <Header />
      <div className="main-body main-insurance">
  <div className="banner-image">
    <img className="banner-img" src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/inner-banner.webp"} alt="" />
  </div>
  <div className="container-fluid container-xl after-banner">
    <div className="after-banner-path">
      <span className="active-page">Home</span>
      <span>/</span>
      <span>About Us</span>
    </div>
  </div>
  <div className="container main-insurance-form">
    <div className="left-side">
      <div className="content">
        <h1>Apply For Insurance</h1>
      </div>
    </div>
    <div className="right-side">
      <div className="form-container">
        <div className="progress-bar">
          <div className="step active" data-step={1}>
            1
          </div>
          <div className="line" />
          <div className="step" data-step={2}>
            2
          </div>
        </div>
        <FormInputs />
      </div>
    </div>
  </div>
</div>
      <br></br>
      <Footer />
    </FormProvider>
  )

}
