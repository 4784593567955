import "bootstrap/dist/css/bootstrap.css";
import React, { useState } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  signIn,
  facebooklogin,
  googlelogin,
  signWithPhoneNumber,
  verifyflag,
  verifyOTP,
} from "../../features/counter/apiSlice";
import { AccountErrors } from "../../validation/account";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
export const Login = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [inputValue, setInputValue] = useState({ email: "", password: "" });
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();
  const verifyflagstatus = useSelector(verifyflag);
  const { status, user } = useSelector((state) => state.api);
  const handleInput = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
    //console.log(inputValue);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await dispatch(signIn(inputValue));
    const wait = toast.loading("Please wait...");
    if (signIn.fulfilled.match(result)) {
      toast.update(wait, {
        render: `Welcome back ${result.meta.arg.email}`,
        type: "success",
        isLoading: false,
        autoClose: 3000,
        hideProgressBar: false,
        progress: undefined,
        closeOnClick: true,
        pauseOnHover: true,
      });
      navigate("/dashboard"); // Redirect to the dashboard on successful login
    }
    if (signIn.rejected.match(result)) {
      toast.update(wait, {
        render: AccountErrors(result.payload),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };
  return (
    <div>
      <Header />
      <div className="main-body ">
  <div className="banner-image">
    <img className="banner-img" src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dashboard-banner.webp"} alt="" />
  </div>
  <div className="container">
    <div className="inner">
      <div className="doc-slider">
        <div
          className="image-holder swiper"
          style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL1}/assets/imgs/login-bg.webp)` }}
        >
          <div className="swiper-wrapper">
          <Swiper
      slidesPerView={1}
      className="swiper-wrapper"
      modules={[Autoplay]}
      autoplay={{
      delay: 3000, // delay between transitions in ms
      disableOnInteraction: false, // autoplay will not be disabled after user interactions
    }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      }}
    >
      
      <SwiperSlide key={1}>
            <div className="inner_img swiper-slide">
              <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/login-5.png"} alt="" />
            </div>
            </SwiperSlide>
            <SwiperSlide key={2}>
            <div className="inner_img swiper-slide">
              <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/login-1.jpg"} alt="" />
            </div>
            </SwiperSlide>
            <SwiperSlide key={3}>
            <div className="inner_img swiper-slide">
              <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/login-2.png"} alt="" />
            </div>
            </SwiperSlide>
            <SwiperSlide key={4}>
            <div className="inner_img swiper-slide">
              <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/login-3.png"} alt="" />
            </div>
            </SwiperSlide>
            </Swiper>
          </div>
          <div className="swiper-pagination" />
        </div>
      </div>
      <div className="login_form">
      <form onSubmit={handleSubmit}>
        <h3>Login</h3>
        <div className="form-wrapper">
          <input type="text" name="email" placeholder="Email" onChange={handleInput} className="form-control" />
          <i className="zmdi zmdi-account" />
        </div>
        <div className="form-wrapper">
          <input type="text" name="password" placeholder="Password" onChange={handleInput} className="form-control1" />
          <i className="zmdi zmdi-account" />
        </div>
        <div className="forgot-password">
          <Link to="/forgetpassword">
            Forgot Password?
          </Link>
        </div>
        <button
                    type="submit"
                    className="animated-button"
                    disabled={status === "loading"}
                  >
                    {status === "loading" ? "Logging in..." : "Login"}
                  </button>
        <div className="text-center mt-4">
          <span>Or</span>
        </div>
      </form>
      <form style={{ display: !verifyflagstatus ? "block" : "none" }}>
        <div className="form-wrapper">
          <input
            type="text"
            placeholder="Phone Number"
            name="phone"
            onChange={(e) => setPhone(e.target.value)}
            className="form-control"
          />
          <i className="zmdi zmdi-email" />
        </div>
      <button
                    onClick={() => dispatch(signWithPhoneNumber(phone))}
                    className="animated-button"
                    type="submit"
                    form="login-form"
                  >
                    Send
                  </button>
                  </form>
                  <form style={{ display: verifyflagstatus ? "block" : "none" }}>
                    <div className="input-with-icon-left">
                      {" "}
                      <i className="fa fa-envelope"></i>
                      <input
                        type="text"
                        className="input-text with-border"
                        name="code"
                        onChange={(e) => setCode(e.target.value)}
                        id="Otp"
                        placeholder="One Time Password"
                      />
                    </div>
                    <button
                      onClick={() => dispatch(verifyOTP(code))}
                      className="animated-button"
                      type="submit"
                      form="login-form"
                    >
                      Send
                    </button>
                  </form>
        <div className="text-center mt-4">
          <span>Or</span>
        </div>
        <div className="social">
          <button className="google-Login-button"  onClick={() => dispatch(googlelogin())}>
            <i className="fa-brands fa-google" />
            Sign in with google
          </button>
          <button className="facebook-Login-button" onClick={() => dispatch(facebooklogin())}>
            <i className="fa-brands fa-facebook-f" />
            Sign in with facebook
          </button>
        </div>
        <div className="text-center m-4 sign-up">
          <span>
            Don't Have an Account?<a href="">Sign Up!</a>
          </span>
        </div>
        </div>
    </div>
  </div>
</div>
      <Footer />
    </div>
  );
};
