import React,{ Fragment,useEffect, useState,useMemo } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { Link,useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import{arealist,currentcity} from '../../features/counter/apiSlice';
import { MultiSelect } from "react-multi-select-component";
import { fetchDataWithRetry } from "../../helper/formdata";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Select from 'react-select';
function Home(props) {   
    //console.log(props);
const navigate = useNavigate();
const [slider, setSlider] = useState([]);
const city=useSelector(currentcity);
//console.log(city);
  const [selected, setSelected] = useState([]);
  const [selected1, setSelected1] = useState(city);
  const [doctorcount, setDoctorCount] = useState(0);
  const [labcount, setLabCount] = useState(0);
  const [hospitalcount, setHospitalCount] = useState(0);
  const [hospital, setHospital] = useState([]);
  const [healthcount, setHealthCount] = useState(0);
  const [cliniccount, setClinicCount] = useState(0);
  //const [areas, setAreas] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [isValid1, setIsValid1] = useState(true);
  const [labcategory, setLabCategory] = useState([]);
 const [health, setHealth] = useState([]);
 const [category, setCategory] = useState([]);
 const [worker, setWorker] = useState(null);
  const [error, setError] = useState(false);
  //console.log(selected1);
  const areaslistt=useSelector(arealist);
  
//   const fetchData = async() => {
  
//     await fetch(`${process.env.REACT_APP_BASE_URL}/banner_list`)
//       .then(response => {
//         return response.json()
//       })
//       .then(data32 => {
//         //console.log(data32.data);
//         setSlider(data32.data);
		
//       })
  
//   }
  useEffect(() => {
    setSelected1(city);
  }, [city]);
  useEffect(() => {
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/hospital`).then((hospital) => {
      setHospital(hospital.data);
  
    }).catch((error) => {
   setError(error);
   });

    //fetchWithNetworkCheck(`${process.env.REACT_APP_BASE_URL}/hospital`);
    // const data = localStorage.getItem('hospital');
    // if (data) {
    //   setHospital(JSON.parse(data));
    // }
    
    // fetchData1();
   
  }, []);
  useEffect(()=>{
    
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/home_page_details/doctor`).then((doctor) => {
        
                setDoctorCount(doctor.data);
            
    }).catch((error) => {
        setError(error);
      });
   
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/home_page_details/lab`).then((lab) => {
        
                setLabCount(lab.data);
            
    }).catch((error) => {
        setError(error);
      });
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/home_page_details/hospital`).then((hospital) => {
        
                setHospitalCount(hospital.data);
            
    }).catch((error) => {
        setError(error);
      });
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/home_page_details/clinic`).then((clinic) => {
        
                setClinicCount(clinic.data);
            
    }).catch((error) => {
        setError(error);
      });
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/home_page_details/health`).then((health) => {
        
                setHealthCount(health.data);
            
    }).catch((error) => {
        setError(error);
      });
    // let urlOne=`${process.env.REACT_APP_BASE_URL}/home_page_details/doctor`;
    // let urlTwo=`${process.env.REACT_APP_BASE_URL}/home_page_details/lab`;
    // let urlThree=`${process.env.REACT_APP_BASE_URL}/home_page_details/hospital`;
    // let urlFour=`${process.env.REACT_APP_BASE_URL}/home_page_details/clinic`;
    // let urlFive=`${process.env.REACT_APP_BASE_URL}/home_page_details/health`;
    // Promise.all([
    //     fetch(urlOne).then(res => res.json()),
    //     fetch(urlTwo).then(res => res.json()),
    //     fetch(urlThree).then(res => res.json()),
    //     fetch(urlFour).then(res => res.json()),
    //     fetch(urlFive).then(res => res.json())
    // ]).then(([urlOneData, urlTwoData,urlThreeData,urlFourData,urlFiveData]) => {
    //    setDoctorCount(urlOneData);
    //    setLabCount(urlTwoData);
    //    setHospitalCount(urlThreeData);  
    //    setClinicCount(urlFourData);
    //    setHealthCount(urlFiveData);
    // }) 
    
},[]) 


//   const fetchData3 = async() => {
//     await fetch(`${process.env.REACT_APP_BASE_URL}/health_list`)
//       .then(response => {
//         return response.json()
//       })
//       .then(data3 => {
// 		  //console.log(data2.data);
//         setHealth(data3.data)
		
//       })
//   }
  
  useMemo(() => {
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/banner_list`).then((banner) => {
        setSlider(banner.data);
    }).catch((error) => {
        setError(error);
      });
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/health_list`).then((health) => {
        
        setHealth(health.data);
            
    }).catch((error) => {
        setError(error);
      });
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list`).then((doctor_cat) => {
        setCategory(doctor_cat.data);
            
    }).catch((error) => {
        setError(error);
      });
    //fetchData();
	//fetchData1();
	//fetchData2();
	//fetchData3();
    //fetchArea();
  }, []);  
  //const [doctorlist, setDoctorlist] = useState([]);
 
  const handleOnChange = value => {
    //console.log(value);
    if (value === null) {
      value = [];
    }
    else
    {
        setSelected(value);
    }
    //console.log(selected);
  };
  const handleOnChange1 = value1 => {
    //console.log(value1);
    if (value1 === null) {
      value1 = [];
    }
    else
    {
        setSelected1(value1);
    }
    //console.log(selected1);
  };
  useEffect(() => {
    // If there is data, the form is valid
    setIsValid(selected1 ? true : false);
    setIsValid1(selected ? true : false);
  }, [selected,selected1]);
  async function labList() {
    //let selected = {selected };
    //console.log(selected.length);
    if(selected1.length === 0)
    {
        setIsValid(false);
    }
    else if(selected1.length !==undefined)
    {
        setIsValid(true);
    }
    else if(selected.length === 0)
    {
        setIsValid1(false);
    }
    else
    {
        setIsValid1(true);    
    let item = {selected};
    var content={"item":selected,"city":selected1};
    //console.log(content);
		let result = await fetch(`${process.env.REACT_APP_BASE_URL}//search_lab`,
			{
				method: 'POST',
				body: JSON.stringify(content),
				headers:
				{
					"Content-Type": 'application/x-www-form-urlencoded',
					"Accept": 'application/json'
				}
			})
		result = await result.json();
        console.log(result);
		//setMsg(result.message);
    if (result !=='') {	
        navigate(
            'labs',
            {
              state: {
                result,
                selected,
                selected1
              }
            }
          );
       
    }
}
  }

  useEffect(() => {
    // Initialize the worker
    const newWorker = new Worker(new URL('./worker.js', import.meta.url));
    setWorker(newWorker);

    newWorker.onmessage = function(e) {
      const { status, results, error } = e.data;
      if (status === 'success') {
        setLabCategory(results[0].data);
        //setCategory(results[1].data);
        //console.log(results[0].data);
      } else if (status === 'error') {
        setError(error);
      }
    };

    // Fetch data when the component mounts
    newWorker.postMessage({ urls: [`${process.env.REACT_APP_BASE_URL}/lab_cat_list`] });

    // Cleanup worker on component unmount
    return () => {
      newWorker.terminate();
    };
  }, []);
return (   

<Fragment>

<Header />

<div className="main-body main-index">
  <section className="container-fluid hero_section flex_prop white">
    <div className="container-fluid container-xl">
      <div className="row common_padding">
        <div className="col-12 col-lg-8">
          <p className="semi_title">Starting From ₹400.00</p>
          <h1 className="banner_title mt-2">
            Find Best Labs At Affordable Prices
          </h1>
        </div>
      </div>
    </div>
  </section>
  <section className="hero_section2 container-fluid container-fluid">
    <div className="row">
      <div className="col-12 hero_section2_img relative">
      <Swiper
    modules={[Autoplay]}
    autoplay={{
      delay: 3000, // delay between transitions in ms
      disableOnInteraction: false, // autoplay will not be disabled after user interactions
    }}
    breakpoints={{
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
    }}
  >
    {slider.map((item,index) => {
              const { id,slider_image } = item;
              return (
                <SwiperSlide key={id}>
        <img src={slider_image} />
        </SwiperSlide> 
      );
    })}
             
             </Swiper>
        <div className="row hero_section_content white align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <h3 className="banner_title_semi">
              Assured Quality, Best Prices, Cost Comparison &amp; Huge Discounts
            </h3>
            <p className="mt-2 font_15">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. A ipsam
              unde voluptas reiciendis, praesentium nesciunt aperiam aspernatur.
              Tempore, neque iusto?
            </p>
            <div className="sub_cont2 home-book-btn flex_prop gap-3 mt-0 mt-sm-3">
              <a href="#" className="appointment_btn flex_prop">
                Book An Appointment{" "}
                <i className="fa-solid fa-circle-arrow-right" />
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-5 mt-4 mt-md-0">
            <div className="form_main black">
              <p className="form_title">Health Scans &amp; Imaging Tests</p>
              <div className="row py-3 py-sm-3 px-3 px-sm-4">
                <div className="col-12 mb-3 text-center">
                  <p className="font_14">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Culpa, ut illo? Esse assumenda recusandae ex?
                  </p>
                </div>
                <div className="col-6 pe-2">
                  <div className="form_holder_input">
                    <label htmlFor="">
                      <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/location-icon.png"} />
                      Select States
                    </label>
                    <div className="search-bar-container">
                      <div className="search-bar" id="search-bar1">
                      <Select
        options={typeof areaslistt != 'undefined' && areaslistt.length > 0 && areaslistt.map(e => ({ label: e.city, value: e.id}))}
        optionLabel="selected City"
        onChange={handleOnChange1}
        value={selected1} 
        labelledBy="Select City" 
        style={{backgroundColor: 'white',Color: 'white'}}    
      />
      {!isValid && <p style={{color:'red'}}>Please Select City</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 ps-2">
                  <div className="form_holder_input">
                    <label htmlFor="">
                      <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/location-icon.png"} />
                      Select Cities
                    </label>
                    <div className="search-bar-container">
                      <div className="search-bar" id="search-bar2">
                      <MultiSelect
        options={labcategory.map(e => ({ key:e.id,label: e.lab_cat_title, value: e.id}))}
        optionLabel="selected Lab"
        value={selected}        
        onChange={handleOnChange}  
        showGroupWheel={true}
        labelledBy="Select Lab" 
        placeholder="Select Lab"       
      />
      {!isValid1 && <p style={{color:'red'}}>Please Select Lab Test</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <button onClick={labList} className="test_price_btn">Get Test Price</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div className="mobile-dropdown ">
      <div className="top-search">
        <div className="select-container">
          <div className="select-wrapper">
          <Select
        options={typeof areaslistt != 'undefined' && areaslistt.length > 0 && areaslistt.map(e => ({ label: e.city, value: e.id}))}
        optionLabel="selected City"
        onChange={handleOnChange1}
        value={selected1} 
        labelledBy="Select City" 
        style={{backgroundColor: 'white',Color: 'white'}}    
      />
      {!isValid && <p style={{color:'red'}}>Please Select City</p>}
          </div>
        </div>
      </div>
      <div className="sub-top-search">
        <div className="search-container">
        <MultiSelect
        options={labcategory.map(e => ({ key:e.id,label: e.lab_cat_title, value: e.id}))}
        optionLabel="selected Lab"
        value={selected}        
        onChange={handleOnChange}  
        showGroupWheel={true}
        labelledBy="Select Lab" 
        placeholder="Select Lab"       
      />
      {!isValid1 && <p style={{color:'red'}}>Please Select Lab Test</p>}
        </div>
        <div className="mobile-button">
          <div className="sub-mobile-button">
          <button onClick={labList} style={{backgroundColor:'#0e223e',color:'#0e223e'}}>
            <svg
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
            </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="container-fluid container-fluid hero_offer">
    <div className="container-fluid container-xl">
      <div className="row common_padding">
        <div className="col-12 flex_prop justify-content-center gap-0 gap-sm-2">
          <div className="award_icon">
            <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/unit-no1.svg"} />
          </div>
          <p>India’s largest doorstep diagnostic service provider</p>
        </div>
      </div>
    </div>
  </section>
  {/*Category*/}
  <section className="container-fluid container-xl margin_top main-web-section">
    <div className="row common_padding">
      <div className="col-12 col-lg-7 text-center m-auto">
        <h1 className="common_title">Health Scans &amp; Imaging Tests</h1>
        <div className="title_icon">
          <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/title_icon.svg"} />
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet omnis
          atque culpa veritatis tempore, sint itaque sapiente vero harum
          dolores!
        </p>
      </div>
      <div className="col-12 mt-4 ">
        <div className="cat_grid ">
          <div className="cat_container hover-zoom overflow-hidden">
            <div className="cat_img transition-transform transform">
              <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/cat1.png"} />
            </div>
            <div className="cat_content">
              <p className="cat_title">TMT Test</p>
              <div className="starting_cat">From: ₹1400.00</div>
              <a href="#" className="know_btn mt-2 btn btn-1">
                Know More
              </a>
            </div>
          </div>
          <div className="cat_container hover-zoom overflow-hidden">
            <div className="cat_img transition-transform transform">
              <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/cat1.png"} />
            </div>
            <div className="cat_content">
              <p className="cat_title">TMT Test</p>
              <div className="starting_cat">From: ₹1400.00</div>
              <a href="#" className="know_btn mt-2 btn btn-1">
                Know More
              </a>
            </div>
          </div>
          <div className="cat_container hover-zoom overflow-hidden">
            <div className="cat_img transition-transform transform">
              <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/cat1.png"} />
            </div>
            <div className="cat_content">
              <p className="cat_title">TMT Test</p>
              <div className="starting_cat">From: ₹1400.00</div>
              <a href="#" className="know_btn mt-2 btn btn-1">
                Know More
              </a>
            </div>
          </div>
          <div className="cat_container hover-zoom overflow-hidden">
            <div className="cat_img transition-transform transform">
              <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/cat1.png"} />
            </div>
            <div className="cat_content">
              <p className="cat_title">TMT Test</p>
              <div className="starting_cat">From: ₹1400.00</div>
              <a href="#" className="know_btn mt-2 btn btn-1">
                Know More
              </a>
            </div>
          </div>
          <div className="cat_container hover-zoom overflow-hidden">
            <div className="cat_img transition-transform transform">
              <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/cat1.png"} />
            </div>
            <div className="cat_content">
              <p className="cat_title">TMT Test</p>
              <div className="starting_cat">From: ₹1400.00</div>
              <a href="#" className="know_btn mt-2 btn btn-1">
                Know More
              </a>
            </div>
          </div>
          <div className="cat_container hover-zoom overflow-hidden">
            <div className="cat_img transition-transform transform">
              <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/cat1.png"} />
            </div>
            <div className="cat_content">
              <p className="cat_title">TMT Test</p>
              <div className="starting_cat">From: ₹1400.00</div>
              <a href="#" className="know_btn mt-2 btn btn-1">
                Know More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div className="main-mobile-section">
      <div className="mobile-section-title">
        <h1 className="common_title-process">Appointment Process</h1>
      </div>
      <div className="col-12 swiper process-slider">
      <Swiper
      slidesPerView={3}
      loop={true}
      modules={[Autoplay]}
    autoplay={{
      delay: 3000, // delay between transitions in ms
      disableOnInteraction: false, // autoplay will not be disabled after user interactions
    }}
    >
         <SwiperSlide key={1}>
            <div className="custom-card">
              <div className="custom-card-image-wrapper">
                <img
                  className="custom-card-image"
                  src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/process-image-1.jpg"}
                  alt="Process 1"
                />
              </div>
              <div className="custom-card-content1">
                <div className="custom-card-title1">
                  Search Best Online Professional
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide key={2}>
            <div className="custom-card">
              <div className="custom-card-image-wrapper">
                <img
                  className="custom-card-image"
                  src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/process-image-2.jpg"}
                  alt="Process 1"
                />
              </div>
              <div className="custom-card-content1">
                <div className="custom-card-title1">
                  Search Best Online Professional
                </div>
              </div>
            </div> 
          </SwiperSlide>
          <SwiperSlide key={3}>
            <div className="custom-card">
              <div className="custom-card-image-wrapper">
                <img
                  className="custom-card-image"
                  src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/process-image-3.jpg"}
                  alt="Process 1"
                />
              </div>
              <div className="custom-card-content1">
                <div className="custom-card-title1">
                  Search Best Online Professional
                </div>
              </div>
            </div>
          
          </SwiperSlide>
          </Swiper>
        
        <div className="swiper-pagination" />
      </div>
    </div>
  </section>
  <section>
    <div className="container-fluid bg_common py-4 py-sm-5 below-process margin_top main-health-scans">
      <section className="container-fluid container-xl">
        <div className="row common_padding">
          <div className="col-12 col-lg-7  text-center m-auto">
            <h1 className="common_title">Health Scans &amp; Imaging Tests</h1>
            <div className="title_icon">
              <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/title_icon.svg"} />
            </div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
              omnis atque culpa veritatis tempore, sint itaque sapiente vero
              harum dolores!
            </p>
          </div>
          <div className="col-12 mt-4 main-hospital-sec">
            <div className="main_grid sub-grid-sec">
              <Link exact='true' to="/hospital">
              <div className="main_container">
                <div className="main_img relative">
                  <div className="main-img">
                    <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/host-img.png"} />
                  </div>
                  <div className="img-overlay main_icon_cust">
                    <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/h3-b.png"} />
                  </div>
                  <div className="main_content">
                    <div className="main_title">
                      <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/arrow-right.png"} />
                      Hospitals ({hospitalcount}+)
                    </div>
                  </div>
                </div>
              </div>
              </Link>
              <Link exact='true' to="/specialist">
              <div className="main_container">
                <div className="main_img relative">
                  <div className="main-img">
                    <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/doctors.webp"} />
                  </div>
                  <div className="img-overlay main_icon_cust">
                    <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/doctor-icon.png"} />
                  </div>
                  <div className="main_content">
                    <div className="main_title">
                      <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/arrow-right.png"} />
                      Doctors(1000+)
                    </div>
                  </div>
                </div>
              </div>
              </Link>
              <Link exact='true' to="/productlisting">
              <div className="main_container">
                <div className="main_img relative">
                  <div className="main-img">
                    <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/main3.jpg"} />
                  </div>
                  <div className="img-overlay main_icon_cust">
                    <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/pharmacies-icon.png"} />
                  </div>
                  <div className="main_content">
                    <div className="main_title">
                      <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/arrow-right.png"} />
                      Pharmacies(700+)
                    </div>
                  </div>
                </div>
              </div>
              </Link>
              <Link exact='true' to="/clinic">
              <div className="main_container">
                <div className="main_img relative">
                  <div className="main-img">
                    <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/clinics2.webp"} />
                  </div>
                  <div className="img-overlay main_icon_cust">
                    <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/clinic-icon.png"} />
                  </div>
                  <div className="main_content">
                    <div className="main_title">
                      <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/arrow-right.png"} />
                      Clinics(1450+)
                    </div>
                  </div>
                </div>
              </div>
              </Link>
              <Link exact='true' to="/lab">
              <div className="main_container">
                <div className="main_img relative">
                  <div className="main-img">
                    <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/laboratories.webp"} />
                  </div>
                  <div className="img-overlay main_icon_cust">
                    <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/lab-icon.png"} />
                  </div>
                  <div className="main_content">
                    <div className="main_title">
                      <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/arrow-right.png"} />
                      Laboratories (2000+)
                    </div>
                  </div>
                </div>
              </div>
              </Link>
              <Link exact='true' to="/insurance">
              <div className="main_container">
                <div className="main_img relative">
                  <div className="main-img">
                    <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/health-insurance.webp"} />
                  </div>
                  <div className="img-overlay main_icon_cust">
                    <img src={process.env.REACT_APP_BASE_URL1+"/assets/imgs/doctor-icon.png"} />
                  </div>
                  <div className="main_content">
                    <div className="main_title">
                      <img src={process.env.REACT_APP_BASE_URL1 +"/assets/imgs/arrow-right.png"} />
                      Health Insurance(6+)
                    </div>
                  </div>
                </div>
              </div>
              </Link>
              <Link exact='true' to="/medicose">
              <div className="main_container">
                <div className="main_img relative">
                  <div className="main-img">
                    <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/nearby.webp"} />
                  </div>
                  <div className="img-overlay main_icon_cust">
                    <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/doctor-icon.png"} />
                  </div>
                  <div className="main_content">
                    <div className="main_title">
                      <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/arrow-right.png"} />
                      Near By Medical (2600+)
                    </div>
                  </div>
                </div>
              </div>
              </Link>
              <Link exact='true' to="/airambulance">
              <div className="main_container">
                <div className="main_img relative">
                  <div className="main-img">
                    <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/airambulance.webp"} />
                  </div>
                  <div className="img-overlay main_icon_cust">
                    <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/doctor-icon.png"} />
                  </div>
                  <div className="main_content">
                    <div className="main_title">
                      <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/arrow-right.png"} />
                      Air Ambulance (2600+)
                    </div>
                  </div>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
  <section className="container-fluid container-xl margin_top">
    <div className="row common_padding">
      <div className="col-12 col-lg-7 text-center m-auto">
        <h1 className="common_title">Our Specialist Appointment</h1>
        <div className="title_icon">
          <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/title_icon.svg"} />
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet omnis
          atque culpa veritatis tempore, sint itaque sapiente vero harum
          dolores!
        </p>
      </div>
      <div className="our-specialist">
        <div className="special_app">
          <div className="special_container">
            <div className="sp_img relative transition-transform transform">
              <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/host-img.png"} />
              <div className="sp_content flex_prop gap-2 justify-content-center">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/right-arrow.png"} />
                <p>Pregnancy Speicalist</p>
              </div>
            </div>
          </div>
          <div className="special_container">
            <div className="sp_img relative transition-transform transform">
              <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/main4.jpg"} />
              <div className="sp_content flex_prop gap-2 justify-content-center">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/right-arrow.png"} />
                <p>Pregnancy Speicalist</p>
              </div>
            </div>
          </div>
          <div className="special_container">
            <div className="sp_img relative transition-transform transform">
              <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/main3.jpg"} />
              <div className="sp_content flex_prop gap-2 justify-content-center">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/right-arrow.png"} />
                <p>Pregnancy Speicalist</p>
              </div>
            </div>
          </div>
          <div className="special_container">
            <div className="sp_img relative transition-transform transform">
              <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/host-img.png"} />
              <div className="sp_content flex_prop gap-2 justify-content-center">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/right-arrow.png"} />
                <p>Pregnancy Speicalist</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* <section className="container-fluid container-xl margin_top">
    <div className="row common_padding">
      <div className="col-12 col-lg-7 text-center m-auto">
        <h1 className="common_title">Health Scans &amp; Imaging Tests</h1>
        <div className="title_icon">
          <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/title_icon.svg"} />
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet omnis
          atque culpa veritatis tempore, sint itaque sapiente vero harum
          dolores!
        </p>
      </div>
      <div className="col-12 mt-4 swiper hos-slider">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="cate_container hover-zoom overflow-hidden">
              <div className="cate_img relative transition-transform transform">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/host-img.png"} />
                <div className="cate_cont flex_prop justify-content-between">
                  <p className="cate_title">
                    Hospitals
                    <span>100+</span>
                  </p>
                  <button className="hos_btn">
                    <i className="fa-solid fa-caret-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="cate_container hover-zoom overflow-hidden">
              <div className="cate_img relative transition-transform transform">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/den-img.png"} />
                <div className="cate_cont flex_prop justify-content-between">
                  <p className="cate_title">
                    Doctors
                    <span>100+</span>
                  </p>
                  <button className="hos_btn">
                    <i className="fa-solid fa-caret-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="cate_container hover-zoom overflow-hidden">
              <div className="cate_img relative transition-transform transform">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/emg-ncv-test.png"} />
                <div className="cate_cont flex_prop justify-content-between">
                  <p className="cate_title">
                    Health
                    <span>100+</span>
                  </p>
                  <button className="hos_btn">
                    <i className="fa-solid fa-caret-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="cate_container hover-zoom overflow-hidden">
              <div className="cate_img relative transition-transform transform">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/host-img.png"} />
                <div className="cate_cont flex_prop justify-content-between">
                  <p className="cate_title">
                    Clinics
                    <span>100+</span>
                  </p>
                  <button className="hos_btn">
                    <i className="fa-solid fa-caret-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
  <section className="container-fluid container-xl margin_top">
    <div className="row common_padding">
      <div className="col-12 col-lg-7 text-center m-auto">
        <h1 className="common_title">Our Services</h1>
        <div className="title_icon">
          <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/title_icon.svg"} />
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet omnis
          atque culpa veritatis tempore, sint itaque sapiente vero harum
          dolores!
        </p>
      </div>
      <div className="col-12 mt-4 ser-slider swiper">
        <Swiper
    breakpoints={{
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    }}
  >
    {labcategory.map((item,i) => {
				   const {id,lab_cat_title,lab_cat_image,home_page_show} = item;
                   if(home_page_show == 'yes')
				   return ( 
            <SwiperSlide key={i}> 
            <div className="ser_container position-relative">
              <div className="hover-zoom overflow-hidden">
                <div className="ser_img">
                  <img
                    className="transition-transform transform"
                    src="https://payal.booklabonline.com/designer/imgs/emg-ncv-test.png"
                  />
                </div>
                <div className="ser_content position-absolute end-0">
                  <p className="ser_title">{lab_cat_title}</p>
                  <p className="startin_cont">Starting From: ₹1400.00</p>
                  <Link exact='true' to="/labinfo" className="read_more_btn">
                    Read More
                    <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
         );
        })}
        </Swiper>
        
      </div>
    </div>
  </section>
  <section className="container-fluid container-xl margin_top">
    <div className="row common_padding">
      <div className="col-12 col-lg-7 text-center m-auto">
        <h1 className="common_title">Our Process</h1>
        <div className="title_icon">
          <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/title_icon.svg"} />
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet omnis
          atque culpa veritatis tempore, sint itaque sapiente vero harum
          dolores!
        </p>
      </div>
      <div className="col-12 mt-4 process-section">
        <div className="process_grid">
          <div className="process_container">
            <div className="pro_content_1 flex_prop">
              <div className="pro_content_2 flex_prop">
                <div className="pro_img">
                  <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/professional-person.png"} />
                </div>
                <div className="pro_num">
                  <p className="pro_para">Step</p>
                  <p className="pro_title_num">01</p>
                </div>
              </div>
              <div className="pro_content">
                <p className="pro_title_1">Search Best Online Professional</p>
              </div>
            </div>
          </div>
          <div className="process_container">
            <div className="pro_content_1 flex_prop">
              <div className="pro_content_2 flex_prop">
                <div className="pro_img">
                  <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/professional-person.png"} />
                </div>
                <div className="pro_num">
                  <p className="pro_para">Step</p>
                  <p className="pro_title_num">01</p>
                </div>
              </div>
              <div className="pro_content">
                <p className="pro_title_1">Search Best Online Professional</p>
              </div>
            </div>
          </div>
          <div className="process_container">
            <div className="pro_content_1 flex_prop">
              <div className="pro_content_2 flex_prop">
                <div className="pro_img">
                  <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/professional-person.png"} />
                </div>
                <div className="pro_num">
                  <p className="pro_para">Step</p>
                  <p className="pro_title_num">01</p>
                </div>
              </div>
              <div className="pro_content">
                <p className="pro_title_1">Search Best Online Professional</p>
              </div>
            </div>
          </div>
          <div className="process_container">
            <div className="pro_content_1 flex_prop">
              <div className="pro_content_2 flex_prop">
                <div className="pro_img">
                  <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/professional-person.png"} />
                </div>
                <div className="pro_num">
                  <p className="pro_para">Step</p>
                  <p className="pro_title_num">01</p>
                </div>
              </div>
              <div className="pro_content">
                <p className="pro_title_1">Search Best Online Professional</p>
              </div>
            </div>
          </div>
          <div className="process_container">
            <div className="pro_content_1 flex_prop">
              <div className="pro_content_2 flex_prop">
                <div className="pro_img">
                  <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/professional-person.png"} />
                </div>
                <div className="pro_num">
                  <p className="pro_para">Step</p>
                  <p className="pro_title_num">01</p>
                </div>
              </div>
              <div className="pro_content">
                <p className="pro_title_1">Search Best Online Professional</p>
              </div>
            </div>
          </div>
          <div className="process_container">
            <div className="pro_content_1 flex_prop">
              <div className="pro_content_2 flex_prop">
                <div className="pro_img">
                  <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/professional-person.png"} />
                </div>
                <div className="pro_num">
                  <p className="pro_para">Step</p>
                  <p className="pro_title_num">01</p>
                </div>
              </div>
              <div className="pro_content">
                <p className="pro_title_1">Search Best Online Professional</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="container-fluid bg_common2 py-4 py-sm-5 margin_top">
    <div className="container-fluid container-xl white">
      <div className="row common_padding">
        <div className="col-12">
          <div className="num_grid">
            <div className="counter-item num_container">
              <h2>
                <span
                  id="part1Count"
                  className="count num_cont"
                  data-target={2000}
                >
                  0
                </span>
                <span className="plus-sign1">+</span>
              </h2>
              <p className="num_title">Experience Doctors</p>
            </div>
            <div className="counter-item num_container">
              <h2>
                <span
                  id="part2Count"
                  className="count num_cont"
                  data-target={40000}
                >
                  0
                </span>
                <span className="plus-sign2">+</span>
              </h2>
              <p className="num_title">Satisfied Clients</p>
            </div>
            <div className="counter-item num_container">
              <h2>
                <span
                  id="part3Count"
                  className="count num_cont"
                  data-target={44000}
                >
                  0
                </span>
                <span className="plus-sign3">+</span>
              </h2>
              <p className="num_title">Total Calls</p>
            </div>
            <div className="counter-item num_container">
              <h2>
                <span
                  id="part4Count"
                  className="count num_cont"
                  data-target={20000}
                >
                  0
                </span>
                <span className="plus-sign4">+</span>
              </h2>
              <p className="num_title">More Experience</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Doctor*/}
  <section className="container-fluid container-xl margin_top">
    <div className="row common_padding">
      <div className="col-12 col-lg-7 text-center m-auto">
        <h1 className="common_title">Our Specialist Doctors</h1>
        <div className="title_icon">
          <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/title_icon.svg"} />
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet omnis
          atque culpa veritatis tempore, sint itaque sapiente vero harum
          dolores!
        </p>
      </div>
      <div className="col-12 mt-4 doc-slider swiper">
        <Swiper
    breakpoints={{
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    }}
  >
    {category.map((item,index) => {
				   const {id,doctor_cat_title,doctor_cat_image, doctor_cat_desc,home_page_show} = item;
                   if(home_page_show == 'yes')
				   return ( 
            <SwiperSlide key={index}> 
           <div className="doc_container hover-zoom">
              <div className="doc_img">
                <img
                  className="transition-transform transform overflow-hidden"
                  src={doctor_cat_image}
                />
              </div>
              <div className="doc_content">
                <div className="flex_prop justify-content-between doc_content_main">
                <Link to="/specialist"  state={{ids: id}}><p className="pack_header_title doc_title">{doctor_cat_title}</p></Link>
                </div>
                <p className="font_14">
                {doctor_cat_desc}
                </p>
              </div>
            </div>
          </SwiperSlide>
         );
        })}
        </Swiper>
       
      </div>
    </div>
  </section>
  {/*Package*/}
  <section className="container-fluid container-xl margin_top">
    <div className="row common_padding">
      <div className="col-12 col-lg-7 text-center m-auto">
        <h1 className="common_title">Health Checks &amp; Lab Tests</h1>
        <div className="title_icon">
          <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/title_icon.svg"} />
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet omnis
          atque culpa veritatis tempore, sint itaque sapiente vero harum
          dolores!
        </p>
      </div>
      <div className="col-12 mt-4 swiper pack-slider">
        <Swiper
    breakpoints={{
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    }}
  >
    {health.map((item,index) => {
				   const {health_id, health_title, health_market_price, health_sell_price,include,discount} = item;
				   return ( 
            <SwiperSlide key={index}> 
            <div className="pack_container">
              <div className="pack_header">
                <p className="pack_header_title">
                {health_title}
                </p>
                <div className="startin_pack mt-1">
                  <p>
                  <b>Market Price: </b> ₹{health_market_price}
                  <br />
                    <b>Sell Price: </b> ₹{health_sell_price}
                  </p>
                </div>
                <p className="mt-1 font_14">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  voluptatibus officiis.
                </p>
              </div>
              <div className="pack_body mt-2">
                <h5 className="font_only">Includes</h5>
                <hr className="my-2" />
                <ul className="pack_ul">
                {typeof include != 'undefined' && include.length > 0 && include && (
									<>
									{include.map((item1,j) => {
                                        if(j < 2)
                                        {
										return (
                                        <li key={j+1}><i className="fa fa-check-circle"></i>{item1}</li>
                                        );
                                        }
										})}
										</>
										)}
                </ul>
              </div>
              <div className="tabs_pack mt-3">
                <a href="#">Online Test</a>
                <a href="#">100% Safe</a>
                <a href="#">Money Back</a>
              </div>
              <div className="banner">
              <Link to="/health_details"  state={{ids: health_id}} className="butn butn__new">
                  <span>Read more</span>
                  </Link>
              </div>
            </div>
          </SwiperSlide>
         );
        })}
        </Swiper>
       
      </div>
    </div>
  </section>
  {/*Parmacy*/}
  <section className="container-fluid container-xl margin_top">
    <div className="row common_padding">
      <div className="col-12 col-lg-7 text-center m-auto">
        <h1 className="common_title">Digital Pharmacy</h1>
        <div className="title_icon ">
          <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/title_icon.svg"} />
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet omnis
          atque culpa veritatis tempore, sint itaque sapiente vero harum
          dolores!
        </p>
      </div>
      <div className="col-12 mt-4 swiper par-slider">
    <Swiper
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
    >
      <SwiperSlide key={1}>
            <div className="par_container">
              <Link to='/productlisting'><img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/pr-1.webp"} /></Link>
            </div>
          </SwiperSlide>
          <SwiperSlide key={2}>
            <div className="par_container">
            <Link to='/productlisting'><img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/pr-2.webp"} /></Link>
            </div>
          </SwiperSlide>
          <SwiperSlide key={3}>
            <div className="par_container">
            <Link to='/productlisting'><img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/pr-3.webp"} /></Link>
            </div>
          </SwiperSlide>
          <SwiperSlide key={4}>
            <div className="par_container">
            <Link to='/productlisting'><img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/pr-3.webp"} /></Link>
            </div>
          </SwiperSlide>
          </Swiper>
      </div>
    </div>
  </section>
  <section className="container-fluid container-xl margin_top">
    <div className="row common_padding">
      <div className="col-12 col-lg-7 text-center m-auto">
        <h1 className="common_title">Our Featured Hospital</h1>
        <div className="title_icon">
          <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/title_icon.svg"} />
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet omnis
          atque culpa veritatis tempore, sint itaque sapiente vero harum
          dolores!
        </p>
      </div>
      <div className="col-12 mt-4 hospi-slider swiper">
        <Swiper
    breakpoints={{
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    }}
  >
    {hospital.map((item,index) => {
				   const {id, hospital_image, box_title,box_description,address1,address2,lat1,long1} = item;
				   return ( 
            <SwiperSlide key={index}> 
            <div className="hos_container hover-zoom overflow-hidden">
              <img
                className="transition-transform transform overflow-hidden"
                src={hospital_image}
              />
              <div className="host_content">
              <Link to="/doctor"  state={{ids: id,type:'hospital'}}><p className="pack_header_title">{box_title} </p></Link>
              </div>
            </div>
          </SwiperSlide>
         );
        })}
        </Swiper>
       
      </div>
    </div>
  </section>
  <section>
    <div className="main-advert-bar-color">
      <div className="container-fluid container-xl margin_top">
        <div className="main-advert-bar common_padding">
          <div className="advert-content">
            <span>Get in touch with us today! Call us on: +99-99-9999</span>
          </div>
          <div className="advert-button">
            <div className="advert-icon">
              <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/phone-call-icon-1.png"} alt="" />
            </div>
            <div className="advert-button-name">
              <span>Book Appointment</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>       
    <Footer />
    
    
</Fragment>
);
 
}
export default Home;


