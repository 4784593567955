import React,{ useEffect, useState,useMemo } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import Parser from 'html-react-parser';
import axios from "axios";
export const HealthPackage = (props) => {  
  //console.log(props.lab);
  const [testimonial, setTestimonial] = useState([]);
  const [center, setCenter] = useState([]);
  const [health, setHealth] = useState([]);
  const [activeTab, setActiveTab] = useState("tab-1"); 
  const [lab, setLab] = useState([]);
useMemo(() => {		
  const data = localStorage.getItem('lab');
  if (data) {
    setLab(JSON.parse(data));
  }
  else
  {
  axios
  .get(`${process.env.REACT_APP_BASE_URL}/home_lab`)
  .then((response) => { 
    localStorage.setItem('lab', JSON.stringify(response.data.data));
    setLab(response.data.data); });	
  }		
}, []) 
  useEffect(() => {    
    fetchData1();	
  }, []);
  // const handleTab1 = () => {
  //   // update the state to tab1
  //   setActiveTab("tab1");
  // };
  // const handleTab = (e) => {
  //   //console.log(e.currentTarget.dataset.id);
  //   // update the state to tab1
  //   setActiveTab(e.currentTarget.dataset.id);
  // };
  // const handleTab2 = () => {
  //   // update the state to tab2
  //   setActiveTab("tab2");
  // };
  // const handleTab3 = () => {
  //   // update the state to tab2
  //   setActiveTab("tab3");
  // };
  // const handleTab4 = () => {
  //   // update the state to tab2
  //   setActiveTab("tab4");
  // }; 
const fetchData1 = async() => {

await fetch(`${process.env.REACT_APP_BASE_URL}/health_list1`)
  .then(response => {
    return response.json()
  })
  .then(data1 => {
      
    setHealth(data1.data)
    //console.log(category);
  })
}
  
  return (
    <div>
	<Header />  
  <div className="main-body main-our-packeges">
      {/* Banner Image */}
      <div className="banner-image">
        <img className="banner-img" src="imgs/dashboard-banner.webp" alt="banner" />
      </div>

      {/* Breadcrumb */}
      <div className="container after-banner">
        <div className="after-banner-path">
          <span className="active-page">Home</span>
          <span> / </span>
          <span>Our Packages</span>
        </div>
      </div>

      {/* Tab Buttons */}
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "tab-1" ? "active" : ""}`}
          onClick={() => setActiveTab("tab-1")}
        >
          HealthSquare
        </button>
        <button
          className={`tab-button ${activeTab === "tab-2" ? "active" : ""}`}
          onClick={() => setActiveTab("tab-2")}
        >
          Redcliffe
        </button>
      </div>

      {/* Tab Content for HealthSquare */}
      {activeTab === "tab-1" && (
        <div className="tab-content active" id="tab-1">
          <section className="section-packages">
            <div className="container-packages">
              <div className="header-packages">
                <div className="title-packages">
                  <span>Our Packages</span>
                </div>
                <div className="subtitle-packages">
                  <span>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Non pariatur tempore omnis accusamus aut rem, nisi aperiam commodi error quidem, et quas a dignissimos rerum.
                  </span>
                </div>
              </div>
              <div className="cards-packages">
                {/* Free Card */}
                {health.map((item,i) => {
				   const { health_id, health_title, health_market_price, health_sell_price,include,discount,health_desc,image } = item;        
				   return (
                <div className={health_id %2 === 0 ? "card-free":"card-basic"}>
                  <h2 className="card-title">{health_title}</h2>
                  <div className="card-divider"></div>
                  <ul className="card-list">
									{typeof include != 'undefined' && include.length > 0 && include && (
									<div>
									{include.map((item1,j) => {
										return (
                                        <li key={item1.lab_cat_title} className="card-list-item">{item1.lab_cat_title}</li>
                                        );
										})}
										</div>
										)}
                                    </ul>
                  <div className="card-price">£0 for one user</div>
                  <button className="card-button">More Details</button>
                </div>
                );               
              })}
              </div>
            </div>
          </section>
        </div>
      )}

      {/* Tab Content for Redcliffe */}
      {activeTab === "tab-2" && (
        <div className="tab-content active" id="tab-2">
          <section className="section-other-content">
            <div className="container-packages">
              <div className="header-packages">
                <div className="title-packages">
                  <span>Our Packages</span>
                </div>
                <div className="subtitle-packages">
                  <span>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Non pariatur tempore omnis accusamus aut rem, nisi aperiam commodi error quidem, et quas a dignissimos rerum.
                  </span>
                </div>
              </div>
              <div className="card-pro">
                <h2 className="card-title">Pro</h2>
                <div className="card-divider"></div>
                <ul className="card-list">
                  <li className="card-list-item">17 - HYDROXYCORTICOSTEROIDS 17-OHCS 24-HOUR URINE</li>
                  <li className="card-list-item">17 - HYDROXYPREGNENOLONE</li>
                  <li className="card-list-item">Online Test</li>
                  <li className="card-list-item">100% Safe</li>
                  <li className="card-list-item">Money Back</li>
                </ul>
                <div className="card-price">£X.99 / per user</div>
                <button className="card-button">More Details</button>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
	   <Footer />
    </div>
  );
};
