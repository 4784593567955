import React,{ useContext } from 'react';
import { useLocation } from "react-router-dom";
import { Payment } from './Payment';
    import { ToastContainer, toast } from 'react-toastify'
    import { CartContext } from '../../context/cart.js'
    import { Header } from './Header';
    import { Footer } from './Footer';
    import 'react-toastify/dist/ReactToastify.css';
   export const Paypal = () => { 
    const { state } = useLocation();
    const { cartItems, addToCart, removeFromCart, clearCart, getCartTotal,quantity,setQuantity,productList,total1} = useContext(CartContext)
    //console.log(state.order_id);
    const initialOptions = {
        clientId: "Aeo1eUOgdlOA4ougMWUsOt8skaLvpswzL_2v9lYZbsRXXfOxzXaRA0UyWWs86z4lqHEhPhcFs-DvrOwT",
        currency: "USD",
        intent: "capture",
    };
    const notifyCartCleared = () => toast.error(`Cart cleared!`, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        style: {
          backgroundColor: '#000',
          color: '#fff'
        }
      })
      const handleRemoveFromCart = (product) => {
        const product_name = productList.find((item) => item.product_id === product);
        //console.log(product_name);
        removeFromCart(product);
        notifyRemovedFromCart(product_name.product_name);
      };
      const notifyRemovedFromCart = (item) => toast.error(`${item} removed from cart!`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        style: {
          backgroundColor: '#000',
          color: '#fff',
        }
        });
    return (
        <div>
        <Header />
        <div className="clearfix"></div>
       <div className="banner">	  
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(assets/img/side-view-adult-paying-with-card.webp)`,marginTop: `92px`
    }}>
            <div className="d-table">			
                <div className="d-table-cell">			
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}>CheckOut</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <br></br>
  <div className="container">
        
    </div>
    <Footer />
    </div>
    );
    }