import React,{ useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import { fetchDataWithRetry } from "../../helper/formdata"; 
export const Partner = () => {
  const [partner, setPartner] = useState([]);
  const [center, setCenter] = useState([]);
  const [error, setError] = useState(false);
  const fetchpartner = async() => {
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/partner`).then((partner) => {
      setPartner(partner.data);
  
    }).catch((error) => {
   setError(error);
   });
  }
  useEffect(() => {    
    fetchpartner();	
  }, []);
  
  return (
    <div>
	<Header />  
  <div className="main-body main-partner">
    <div className="main-about-us">
  <div className="banner-image">
    <img className="banner-img" src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/inner-banner.webp"} alt="" />
  </div>
  <div className="container after-banner">
    <div className="after-banner-path">
      <span className="active-page">Home</span>
      <span>/</span>
      <span>About Us</span>
    </div>
  </div>
  </div>
  <section className="container-fluid container margin_top">
    <div className="row">
      <div className="col-12">
        <div className="part_grid">
          {partner.map((item) => {
            const { id,title,description, image } = item;
            return (
          <div className="part_img text-center relative" key={id}>
            <div>
              <img src={image} />
            </div>
            <p className="part_title mt-1">{title}</p>
            <p className="mt-2 font_13">
              {description}
            </p>
            <div className="part_num">01</div>
          </div>
          );
            })}
         
        </div>
      </div>
    </div>
  </section>
  </div>
	   <Footer />
    </div>
  );
};
   