import React,{ useEffect, useState,useRef } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import store from '../../app/store';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch,useSelector } from 'react-redux';
import{register,signUp} from '../../features/counter/apiSlice';
import { booking,booking1 } from "../../helper/formdata";
import { fetchDataWithRetry } from "../../helper/formdata";  
export const AirAmbulance = (props) => { 
  let statevalue=useSelector((state) => state); 
  //console.log(statevalue);
  const dispatch=useDispatch();
  const sliderRef = useRef(null);
  const token = JSON.parse(localStorage.getItem('user-info-login')); 
  //console.log(token);
  const [items, setItems] = useState([
    {
      id: 1,
      imgSrc: "/assets/imgs/bg-4.jpg",
      alt: "Lossless Youths",
      title: "Lossless Youths",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.",
    },
    {
      id: 2,
      imgSrc: "/assets/imgs/bg-5-1.jpg",
      alt: "Estrange Bond",
      title: "Estrange Bond",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.",
    },
    {
      id: 3,
      imgSrc: "/assets/imgs/bg-1.jpg",
      alt: "The Gate Keeper",
      title: "The Gate Keeper",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.",
    },
    {
      id: 4,
      imgSrc: "/assets/imgs/bg-2-1-1.jpg",
      alt: "Last Trace Of Us",
      title: "Last Trace Of Us",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.",
    },
    {
      id: 5,
      imgSrc: "/assets/imgs/bg-3-1.jpg",
      alt: "Urban Decay",
      title: "Urban Decay",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.",
    },
  ]);

  const handleNext = () => {
    const newItems = [...items];
    newItems.push(newItems.shift()); // Move the first element to the end
    setItems(newItems);
  };

  const handlePrev = () => {
    const newItems = [...items];
    newItems.unshift(newItems.pop()); // Move the last element to the front
    setItems(newItems);
  };
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [articlelist, setArticlelist] = useState([]);
    const [inputValue,setInputValue]=useState({name:'',email:(typeof token != 'undefined' && token != null) ? token.currentUser.email:'',phone:'',password:'',startDate:startDate,subject:'',comments:'',age:'',gender:'',time:'',reference_id:'',register_id:(typeof token != 'undefined' && token != null ) ? token.currentUser.uid:'',booking_status:'booked',type:'air'})
    const activate = (e) => {
      const slider = sliderRef.current;
      const items = slider.querySelectorAll(".item");
  
      if (e.target.matches(".next")) {
        slider.append(items[0]); // Move first item to the end
      } else if (e.target.matches(".prev")) {
        slider.prepend(items[items.length - 1]); // Move last item to the start
      }
    };
    const handleInput=(e)=>
    {
      
      setInputValue({...inputValue,[e.target.name]:e.target.value});
      //console.log(inputValue);
    }
    const handleSubmit=async(e)=>
    {
      e.preventDefault();
      if(token === null)
        {
          dispatch(signUp(inputValue));
          
        }
        else
        {
          const wait = toast.loading("Please wait...");
          //console.log(inputValue);
          const booked=await booking1(
            inputValue
          );
          toast.update(wait, {
            render: `Welcome ${booked.message}`,
            type: "success",
            isLoading: false,
            autoClose: 10000,
            closeOnClick: true,
            pauseOnHover: true,
          }); 
          window.location.href = '/schedule'
      //dispatch(booking(inputValue))
        }
    }

  const fetchData2 = async(props) => {
    //console.log(props);
     let url='';
     if (props === undefined) {
         
     url=`${process.env.REACT_APP_BASE_URL}/article_list`;
     }
     else
     {
         
         
     url=`${process.env.REACT_APP_BASE_URL}/article_list/${props}`;
     }
     fetchDataWithRetry(url).then((article) => {
         setArticlelist(article.data);
     
       }).catch((error) => {
      setError(error);
      });
 
   }
   useEffect(() => {    
     fetchData2();
   }, []);
    // useEffect(() => {  
    //   //props.ChangeId(labid1);  
    //   props.ChangeType('air');  
    //   props.ChangeId('4'); 
    // }, [])
  return (
    <div>
	<Header />  
    <section className="main">
    <div className="slider">
      {items.map((item, index) => (
        <div className={`item item-${index + 1}`} key={item.id}>
          <img src={item.imgSrc} loading="eager" alt={item.alt} />
          <div className="content">
            <h2 className="title">{item.title}</h2>
            <p className="description">{item.description}</p>
            <button>Read More</button>
          </div>
        </div>
      ))}
    </div>
      <nav className="nav">
        <button className="btn prev" onClick={handlePrev}>
          <ion-icon name="arrow-back-outline"></ion-icon>
        </button>
        <button className="btn next" onClick={handleNext}>
          <ion-icon name="arrow-forward-outline"></ion-icon>
        </button>
      </nav>
    </section>
    <section className="ambulance-content-box">
  <div className="container">
    <div className="row  justify-content-between">
      <div className="col-md-8">
        <div className="ambulance-content">
          <h4>Lorem ipsum dolor sit amet, consectetur.</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Architecto, incidunt laboriosam blanditiis magni porro deleniti
            reiciendis vitae officiis quia officia. Quam quae iure maiores nisi
            inventore alias obcaecati nostrum nam.
          </p>
          <h4>Lorem ipsum dolor sit amet, consectetur.</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Architecto, incidunt laboriosam blanditiis magni porro deleniti
            reiciendis vitae officiis quia officia. Quam quae iure maiores nisi
            inventore alias obcaecati nostrum nam.
          </p>
          <h4>Lorem ipsum dolor sit amet, consectetur.</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Architecto, incidunt laboriosam blanditiis magni porro deleniti
            reiciendis vitae officiis quia officia. Quam quae iure maiores nisi
            inventore alias obcaecati nostrum nam.
          </p>
          <h4>Lorem ipsum dolor sit amet, consectetur.</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Architecto, incidunt laboriosam blanditiis magni porro deleniti
            reiciendis vitae officiis quia officia. Quam quae iure maiores nisi
            inventore alias obcaecati nostrum nam.
          </p>
          <h4>Lorem ipsum dolor sit amet, consectetur.</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Architecto, incidunt laboriosam blanditiis magni porro deleniti
            reiciendis vitae officiis quia officia. Quam quae iure maiores nisi
            inventore alias obcaecati nostrum nam.
          </p>
          <h4>Lorem ipsum dolor sit amet, consectetur.</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Architecto, incidunt laboriosam blanditiis magni porro deleniti
            reiciendis vitae officiis quia officia. Quam quae iure maiores nisi
            inventore alias obcaecati nostrum nam.
          </p>
        </div>
      </div>
      <div className="col-md-3">
        <div className="ambul-form">
          <div className="filed-group">
            <label>Name</label>
            <input type="text" placeholder="Name" />
          </div>
          <div className="filed-group">
            <label>Email</label>
            <input type="email" placeholder="Email" />
          </div>
          <div className="filed-group">
            <label>Password</label>
            <input type="password" placeholder="Password" />
          </div>
          <div className="filed-group">
            <label>Password</label>
            <textarea name="" id="" placeholder="Massage" defaultValue={""} />
          </div>
          <div className="ambul-btn-box">
            <a href="#" className="amubl-btn">
              Submit
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

	   <Footer />
    </div>
  );
};
