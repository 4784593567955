import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { fetchAddresses, addAddress, updateAddress, deleteAddress } from '../../helper/formdata';
export const AddressForm = () => {
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [editingAddress, setEditingAddress] = useState(null);

  useEffect(() => {
    loadAddresses();
  }, [addresses]);

  const loadAddresses = async () => {
    const data = await fetchAddresses();
    setAddresses(data.data);
  };

  const handleAddAddress = async (e) => {
    e.preventDefault();
    if (newAddress.trim()) {
      const data = await addAddress(newAddress);
      setAddresses([...addresses, data]);
      setNewAddress('');
    }
  };

  const handleUpdateAddress = async (e) => {
    e.preventDefault();
    if (editingAddress && newAddress.trim()) {
      const data = await updateAddress(editingAddress.id, newAddress);
      setAddresses(
        addresses.map((address) =>
          address.id === editingAddress.id ? data : address
        )
      );
      setEditingAddress(null);
      setNewAddress('');
    }
  };

  const handleDeleteAddress = async (id) => {
    await deleteAddress(id);
    setAddresses(addresses.filter((address) => address.id !== id));
  };

  const startEditing = (address) => {
    setEditingAddress(address);
    setNewAddress(address.address);
  };

  return (
    <Container>
      <h2>Select Shipping Address</h2>
      <form onSubmit={editingAddress ? handleUpdateAddress : handleAddAddress}>
        <Input
          type="text"
          value={newAddress}
          onChange={(e) => setNewAddress(e.target.value)}
          placeholder="Enter new address"
        />
        <Button type="submit">{editingAddress ? 'Update' : 'Add'} Address</Button>
      </form>
      <AddressList>
        {addresses.map((address) => (
          <AddressItem key={address.id}>
            <input
              type="radio"
              id={`address-${address.id}`}
              name="address"
              value={address.address}
              onChange={() => setSelectedAddress(address.address)}
              checked={selectedAddress === address.address}
            />
            <label htmlFor={`address-${address.id}`}>{address.address}</label>
            <EditButton onClick={() => startEditing(address)}>Edit</EditButton>
            <DeleteButton onClick={() => handleDeleteAddress(address.id)}>Delete</DeleteButton>
          </AddressItem>
        ))}
      </AddressList>
      <SelectedAddress>
        <strong>Selected Address:</strong> {selectedAddress}
      </SelectedAddress>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
`;

const Input = styled.input`
  padding: 10px;
  margin-right: 10px;
  font-size: 16px;
  width: calc(100% - 110px);
  box-sizing: border-box;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
`;

const AddressList = styled.div`
  margin-top: 20px;
`;

const AddressItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  
  input {
    margin-right: 10px;
  }
  
  label {
    font-size: 16px;
  }
`;

const EditButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #ffc107;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const DeleteButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const SelectedAddress = styled.p`
  margin-top: 20px;
  font-size: 16px;
`;
