import useFormContext from "../hooks/useFormContext"
import Form from "./Form";
const OptIn = () => {
    const { data, handleChange} = useFormContext()
    
    const content = (
        <>
         <section className="personal_info">
        <div className="login-box1">
            <div className="thanks-page">
  <div id="thanks-page-wrapper" className="animated zoomIn">
    {/* We make a wrap around all of the content so that we can simply animate all of the content at the same time. I wanted a zoomIn effect and instead of placing the same class on all tags, I wrapped them into one div! */}
    <h1>
      {/* The <h1> tag is the reason why the text is big! */}
      <underline>Thank you!</underline>
      {/* The underline makes a border on the top and on the bottom of the text */}
    </h1>
    <br></br>
    <h3>
      Our Executive call as soon as possible.
      {/* The <h3> take is the description text which appear under the <h1> tag. It's there so you can display some nice message to your visitors! */}
    </h3>
    <Form/>
  </div>
</div>
</div>
</section>

        </>
    )

    return content
}
export default OptIn