import React,{ useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import store from '../../app/store';
import { useDispatch,useSelector } from 'react-redux';
import{fetcharea,areaslist} from '../../features/counter/apiSlice';
export const ThankYou = () => {
  const { state } = useLocation();
  //const data11=useSelector(state => state.counter.data.data);
  //console.log(data11);
  const dispatch=useDispatch();
  useEffect(() => {
    dispatch(fetcharea());
  }, []);
  // useEffect(() => {   
   
  //  dispatch(fetcharea())
  //   }, [dispatch]);
   //console.log(store.dispatch(fetcharea())); 
  //console.log(state);
  const [partner, setPartner] = useState([]);
  const [center, setCenter] = useState([]);
  const fetchData1 = async() => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/partner`)
      .then(response => {
        return response.json()
      })
      .then(data1 => {
		  
        setPartner(data1.data)
		//console.log(category);
      })
  }
  useEffect(() => {   
   
	fetchData1();	
  fetchData2();	
  }, []);
  const fetchData2 = async() => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/center_lab`)
      .then(response => {
        return response.json()
      })
      .then(data1 => {
		  
        setCenter(data1.data)
		//console.log(category);
      })
  }
  //const areass=useSelector(areaslist);
  //console.log(store.dispatch(fetcharea()));
  return (
    <div>
	<Header />  
  
      <div className="banner">
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(assets/img/thank-you-appreciation-greatful-happy-min.webp)`,marginTop: `110px`
    }}>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
   <main>  
        <div className="container">
          <section className="block bottom_padding">
          Order Id {state !=null ? state.order_id : ''}
          <br></br>
          Payment Id {state !=null ? state.payment_id : ''}
          <button onClick={e=>dispatch(fetcharea())}>Fetch</button>
          </section>
        </div>
       
      </main>
	   <Footer />
    </div>
  );
};
   