import {useAuthValue} from "./AuthContext";
import {useState,useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import {auth} from './firebase'
import {sendEmailVerification} from 'firebase/auth'
import { Header } from './Header';
import { Footer } from './Footer';
export const VerifyEmail = () => {    
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const {currentUser} = useAuthValue();
    const [time, setTime] = useState(60)
const [timeActive, setTimeActive] = useState(false)
const navigate = useNavigate();
    const resendEmailVerification = () => {
        setButtonDisabled(true)
        sendEmailVerification(auth.currentUser)
        .then(() => {
          setButtonDisabled(false)
          setTimeActive(true)
        }).catch((err) => {
          alert(err.message)
          setButtonDisabled(false)
        })
        
      }
      useEffect(() => {
        const interval = setInterval(() => {
          currentUser?.reload()
          .then(() => {
            if(currentUser?.emailVerified){
              clearInterval(interval)
              navigate('/login')
            }
          })
          .catch((err) => {
            alert(err.message)
          })
        }, 1000)
      }, [])
    return (
        <div>
             <Header />
        <div className="clearfix"></div>
       <div className="banner">	  
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(assets/img/inbox-communication-notification-e-mail-mail-concept-min.webp)`,marginTop: `92px`
    }}>
            <div className="d-table">			
                <div className="d-table-cell">			
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}>CheckOut</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <br></br>
  <main>  
        <div className="container">
          <section className="block bottom_padding">
          <span>{currentUser?.email}</span>
    <button 
  onClick={resendEmailVerification}
  disabled={buttonDisabled}
>Resend Email {timeActive && time}</button>  
          </section>
        </div>
       
      </main>

<Footer /> 
  </div>
  );
}