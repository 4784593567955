
import 'bootstrap/dist/css/bootstrap.css';
import React,{ useState } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { Link,useNavigate} from 'react-router-dom';
import {signInWithEmailAndPassword, sendEmailVerification,sendPasswordResetEmail} from 'firebase/auth'
import {auth} from './firebase'
import {useAuthValue} from './AuthContext'
export const ForgetPassword = () => {
	const navigate = useNavigate();
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [msg, setMsg] = useState("");	
const {setTimeActive} = useAuthValue()
//const [isLoggedin, setIsLoggedin] = useState(false);  
const forgetpass = () => {    
    if(email == null || email == undefined || email == '')
    {
        setMsg('Please Enter Email');
        return false;
    }
sendPasswordResetEmail(auth, email)
  .then(() => {
    setMsg('Password reset email sent!');
    console.log('Password reset email sent!');
    // Password reset email sent!
    // ..
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });
}
 return(
	<div>
	<Header />
      <div className="banner">
        <div className="banner-item banner-bg inner_banner">
		
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="banner-content">
						                          
                        </div>
                    </div>
                </div>
            
        </div>
    </div>
    </div>
	<div className="login">
		<div className="register_block">
			<div className="row">
				<div className="col-sm-6">
					<img src="assets/img/register_img.jpg" alt="Register" className="img-responsive" />
				</div>
				<div className="col-sm-6">
					<div className="login-register-page"> 
					
						<div className="welcome-text">
							<h3>We're glad to see you again!</h3>
							<span>Don't have an account? <Link to="/">Sign Up!</Link></span> 
						</div>				  
						<div className="messages">
									<span style={{ color: 'green' }}>{msg}</span>
									
								</div>
						<form>						
							<div className="input-with-icon-left"> <i className="fa fa-envelope"></i>
								<input type="text" className="input-text with-border" name="email" value={email} onChange={(e) => setEmail(e.target.value)} id="emailaddress" placeholder="Email" 
            required=""/>
							</div>						
							<button  onClick={forgetpass} className="button"  form="login-form">Log In</button>
						</form>
						<div className="social-login-separator"><span>or</span></div>  
						<div className="social-login-buttons">
						
						</div>
					</div>
				</div>
			</div>
		  </div>
	  </div>
	   <Footer />
    </div>
);
};
