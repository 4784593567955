import React,{ useEffect, useState } from 'react';
import { Link,Route } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
export const Order = (order,j=1) => {
    //console.log(order);
return (
    <>
    <div key={j} className="card shadow-0 border mb-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2">
                    <img
                      src={order.item.pi_name}
                      className="img-fluid"
                      alt="Phone"
                    />
                  </div>
                  <div className="col-md-2 text-center d-flex justify-content-center align-items-center">
                    <p className="text-muted mb-0">{order.item.product_name}</p>
                  </div>
                 
                  <div className="col-md-2 text-center d-flex justify-content-center align-items-center">
                    <p className="text-muted mb-0 small">Qty: {order.item.pro_product_qty}</p>
                  </div>
                  <div className="col-md-2 text-center d-flex justify-content-center align-items-center">
                    <p className="text-muted mb-0 small">{order.item.product_price * order.item.pro_product_qty}</p>
                  </div>
                </div>
                <hr
                  className="mb-4"
                  style={{ backgroundColor: "#e0e0e0", opacity: 1 }}
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-2">
                    <p className="text-muted mb-0 small">Track Order</p>
                  </div>
                  <div className="col-md-10">
                    <div
                      className="progress"
                      style={{ height: 6, borderRadius: 16 }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "65%",
                          borderRadius: 16,
                          backgroundColor: "#a8729a"
                        }}
                        aria-valuenow={65}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                    <div className="d-flex justify-content-around mb-1">
                      <p className="text-muted mt-1 mb-0 small ms-xl-5">
                        Out for delivary
                      </p>
                      <p className="text-muted mt-1 mb-0 small ms-xl-5">
                        Delivered
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>      
    </>
  );
}