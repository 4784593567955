import React,{ Fragment,useEffect, useState } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import Parser from 'html-react-parser';
import { Link } from "react-router-dom";
import Select from 'react-select';
import {useAuthValue} from './AuthContext';
import { getDistance} from 'geolib';
import store from '../../app/store';
import { useDispatch,useSelector } from 'react-redux';
import{fetcharea,arealist,currentlatitude,currentlongitude} from '../../features/counter/apiSlice';
import { registration, booking, city_check,fetchDataWithRetry } from "../../helper/formdata";
export const Hospital = (props) => {
  //console.log(props);
  const [hospital, setHospital] = useState([]);
  const [hospital1, setHospital1] = useState([]);
  const [selected1, setSelected1] = useState([]);
  const [error, setError] = useState(false);
  const areaslistt = useSelector(arealist);
  const lt = useSelector(currentlatitude);
  const lng = useSelector(currentlongitude);
  //console.log(areaslistt);
  const dispatch=useDispatch();

  const search = () => {
    //console.log(selected1.label);
    //console.log(clinic.filter((item) => selected === item.address2));
    if (selected1 !== null) {
      setHospital(hospital1.filter((item) => item.address2 === selected1.label));
      //setSelected(selected);
    }
    
    //console.log(selected);
  }; 
	
    useEffect(() => {
      fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/hospital`).then((hospital) => {
        setHospital(hospital.data);
    
      }).catch((error) => {
     setError(error);
     });

      //fetchWithNetworkCheck(`${process.env.REACT_APP_BASE_URL}/hospital`);
      // const data = localStorage.getItem('hospital');
      // if (data) {
      //   setHospital(JSON.parse(data));
      // }
      
      // fetchData1();
     
    }, []);
    const handleOnChange1 = value1 => {
      //console.log(value1);
      if (value1 === null) {
        value1 = [];
      }
      else
      {
          setSelected1(value1);
      }
      //console.log(selected1);
    };
    const openLinkInNewTab = ( url ) => {
      const newTab = window.open(url, '_blank', 'noopener,noreferrer');
      if ( newTab ) newTab.opener = null;
    }
  // const fetchData1 = async(ids) => {
   
  //   await fetch(`${process.env.REACT_APP_BASE_URL}/hospital`)
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(data1 => {
  //       localStorage.setItem('hospital', JSON.stringify(data1.data));
  //       setHospital(data1.data)
	// 	//console.log(category);
  //     })
	// }
	  
  return (
    <>
	<Header />
  <div className="main-body main-hospital">
  <div className="banner-image">
    <img className="banner-img" src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/inner-banner.webp"} alt="" />
  </div>
  <div className="container-fluid container-xl">
    {typeof hospital != 'undefined' && hospital.length > 0 && hospital && (
      <>
      {hospital.map((item) => {
     const { id, hospital_image, box_title,box_description,address1,address2,lat1,long1 } = item;
     if(lat1 !=null && long1!=null && lt!=null && lng!=null)
     {
     var dis=getDistance(               
      { latitude: lat1, longitude: long1 },
      { latitude: lt, longitude: lng }
         );
        }
        else
        {
          var dis=0;
        }
     var url = "https://www.google.com/maps/dir/?api=1";
var origin = "&origin=" + lt + "," + lng;
var destination = "&destination=" + lat1 + "," + long1;
var newUrl = new URL(url + origin + destination);
     return (
    <div className="hcard" key={id}>
      <div className="hcard-image">
        <img
          src={hospital_image}
          alt={box_title}
          className="card-img"
        />
      </div>
      <div className="hcard-content">
        <h2 className="hcard-title">{box_title}</h2>
        <p className="hcard-subtitle">Best healthcare center</p>
        <p className="hcard-description">
          {Parser(box_description)}
          <a href="#" className="advanced-treatments">
            advanced treatments
          </a>{" "}
          by adopting the best techniques and facilities using the cutting-edge
          technologies in terms of surgical equipment, operating theatres,
          recovery areas and also help in providing tertiary care to the
          patients at a reasonable cost.
        </p>
        <div className="hcard-info">
          <span className="rating">Avalability : </span>
          <span className="availability">24/7 Available</span>
        </div>
        <div className="main-address">
          <div className="address">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
              </svg>
            </span>
            <p className="hcard-address">
              {address1}<br></br>{address2}
            </p>
          </div>
          <div className="address">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
              </svg>
            </span>
            <p className="hcard-distance">
              {`Distance\n\n${dis} Meter\nOR\n${dis / 1000} KM`}
            </p>
          </div>
        </div>
        <div className="main-btn-doctor">
          <div className="cbtn-holder">
            <button onClick={ () => openLinkInNewTab(newUrl)} className="cbtn btn-3 hover-border-4">
              <span>Direction</span>
            </button>
          </div>
          <div className="cbtn-holder">
          <Link to="/doctor"  state={{ids: id,type:'hospital'}}><button className="cbtn btn-3 hover-border-4">
             <span>View Doctor</span>
            </button></Link>
          </div>
        </div>
      </div>
    </div>
    );
  })}
    </>
    )}
  </div>
</div>

	   <Footer />
    </>
  );
};
