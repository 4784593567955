import { useState} from "react";
import { Link } from "react-router-dom";
export const DashboardLeft = () => {
    const token = JSON.parse(localStorage.getItem('user-info-login'));
    console.log(token);
    const [selectedFile, setSelectedFile] = useState("");
    return (	
  <>
   <li className="nav-item dashboard" data-tab="dashboard">
        Dashboard
      </li>
      <li className="nav-item" data-tab="favourite-doctors">
        Favourite Doctors
      </li>
      <li className="nav-item" data-tab="schedule-timing">
        Schedule Timing
      </li>
      <li className="nav-item" data-tab="profile">
        My Profile
      </li>
      <li className="nav-item" data-tab="order">
        Order
      </li>
      <li className="nav-item" data-tab="change-password">
        Change Password
      </li>
      <li className="nav-item logout-nav" data-tab="logout">
        Logout
      </li>
  </>
  );
};