import React,{ useEffect, useState } from "react";
import { Link,useNavigate} from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import axios from "axios";
export const Dashboard = () => {
  //console.log({profile});
  const token = JSON.parse(localStorage.getItem('user-info-login')); 
  //console.log(token);
const [profile, setProfile] = useState([]);
const [activeTab, setActiveTab] = useState("tab1");
const[order,setOrder]=useState([]);
let register_id=(typeof token != 'undefined' && token != null ? (typeof token.currentUser != 'undefined' && token.currentUser != null ? token.currentUser.uid:token['id']):'');
  const fetchData3 = async() => { 
    await fetch(`${process.env.REACT_APP_BASE_URL}/order_list/${register_id}`)
      .then(response => {
        return response.json()
      })
      .then(data2 => {
      //console.log(data2.data);
      //console.log(data2.data[0].count);
      setOrder(data2.data);
      
      })
      //navigate('/schedule')
  }
  useEffect(() => {  
    fetchData3();    
      //fetchData2();
  }, [register_id])
  var id=(typeof token.currentUser != 'undefined' ? token.currentUser.uid:token['id']);
useEffect(() => {  
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/profile/${id}`)
    .then((response) => { setProfile(response.data.data); });
  
  }, [id])
  const navigate = useNavigate();
  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1");
  };
 
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
  };
  const handleTab3 = () => {
    // update the state to tab2
    setActiveTab("tab3");
  };
  const handleTab4 = () => {
    // update the state to tab2
    setActiveTab("tab4");
  };
  const handleTab5 = () => {
    // update the state to tab2
    setActiveTab("tab5");
  }; 
  const handleTab6 = () => {
    // update the state to tab2
    setActiveTab("tab6");
  };
  const handleTab7 = () => {
    // update the state to tab2
    setActiveTab("tab7");
  };
  const logout = (e) => {
    e.preventDefault();
    //console.log('Logout');   
    // CLEAR DATA FROM STORAGE
    localStorage.clear();
    sessionStorage.clear();

    navigate("/");
}
  return (
    <>
	<Header />
  <div className="main-body main-dashbaord">
    <div className="banner-image">
      <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dashboard-banner.webp"} alt="" />
    </div>
    <div className="container after-banner">
      <div className="after-banner-path">
        <span className="active-page">Home</span>
        <span>/</span>
        <span>DashBoard</span>
      </div>
    </div>
    <ul className="mobile-nav">
      <li className="nav-item dashboard" data-tab="#tab-1" onClick={handleTab1}>
        Dashboard
      </li>
      <li className="nav-item" data-tab="#tab-2" onClick={handleTab2}>
        Favourite Doctors
      </li>
      <li className="nav-item" data-tab="#tab-3" onClick={handleTab3}>
        Schedule Timing
      </li>
      <li className="nav-item" data-tab="#tab-4" onClick={handleTab4}>
        My Profile
      </li>
      <li className="nav-item" data-tab="#tab-5" onClick={handleTab5}>
        Order
      </li>
      <li className="nav-item" data-tab="#tab-6" onClick={handleTab6}>
        Change Password
      </li>
      <li className="nav-item logout-nav" data-tab="#tab-7" onClick={handleTab7}>
        Logout
      </li>
    </ul>
    <div className="mobile-profile">
      <div className="mobile-image">
        <img src={(typeof token.currentUser != 'undefined' && token.currentUser.photoURL != null) ? token.currentUser.photoURL : ""} alt="" />
      </div>
      <div className="mobile-content">
        <p className="profile-name">sandeepshu34@gmail.com</p>
        <p className="profile-date">Oct 05, 2083, 38 Years</p>
      </div>
    </div>
    <div className="main-dashboard">
      <div className="sidebar">
        <div className="profile">
          <img src={(typeof token.currentUser != 'undefined' && token.currentUser.photoURL != null) ? token.currentUser.photoURL : ""} alt="" />
          <p className="profile-name">sandeepshu34@gmail.com</p>
          <p className="profile-date">Oct 05, 2083, 38 Years</p>
        </div>
        <ul className="nav">
          <li className="nav-item dashboard" data-tab="#tab-1" onClick={handleTab1}>
            Dashboard
          </li>
          <li className="nav-item" data-tab="#tab-2" onClick={handleTab2}>
            Favourite Doctors
          </li>
          <li className="nav-item" data-tab="#tab-3" onClick={handleTab3}>
            Schedule Timing
          </li>
          <li className="nav-item" data-tab="#tab-4" onClick={handleTab4}>
            My Profile
          </li>
          <li className="nav-item" data-tab="#tab-5" onClick={handleTab5}>
            Order
          </li>
          <li className="nav-item" data-tab="#tab-6" onClick={handleTab6}>
            Change Password
          </li>
          <li className="nav-item logout-nav" data-tab="#tab-7" onClick={handleTab7}>
            Logout
          </li>
        </ul>
      </div>
      <div className="main-content tabs-content">
      {activeTab === "tab1" ?
        <div id="tab1" className={activeTab === "tab1" ? "content-tab active" : ""}>
          <div>
            {/* <div>
                            <div class="dashboard-content ">
                                <div class="card">
                                    <div class="card-content">
                                        <h1 class="card-title">170</h1>
                                        <p class="card-text">Doctor Booking</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            <div className="cards-container">
              <div className="card doctor-booking">
                <div className="content">
                  <h2>170</h2>
                  <p>Doctor Booking</p>
                </div>
              </div>
              <div className="card lab-booking">
                <div className="content">
                  <h2>20</h2>
                  <p>Lab Booking</p>
                </div>
              </div>
              <div className="card health-package">
                <div className="content">
                  <h2>50</h2>
                  <p>Health Package</p>
                </div>
              </div>
              <div className="card medical-package">
                <div className="content">
                  <h2>100</h2>
                  <p>Medical Package</p>
                </div>
              </div>
            </div>
          </div>
          <div className="doctor-appointment">
            <div className="appointment-title">
              <span>Doctors Appointment</span>
            </div>
            <div className="main-btn">
              <div className="btn-holder">
                <button className="btn btn-3 hover-border-4">
                  <span>Upcoming</span>
                </button>
              </div>
              <div className="btn-holder">
                <button className="btn btn-3 hover-border-4">
                  <span>Today</span>
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="table-container">
              <table className="responsive-table">
                <thead>
                  <tr>
                    <th>Doctor Name</th>
                    <th>Appt Date</th>
                    <th>Booking Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="doctor-info">
                        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"} alt="Dr. Amelia Anna" />
                        <div>
                          <strong>Dr. Amelia Anna</strong>
                          <span>Cardiology</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      20 Oct 2020
                      <br />
                      10:30AM
                    </td>
                    <td>18 Oct, 2020</td>
                    <td>$150.00</td>
                    <td>
                      <span className="status confirm">Confirm</span>
                    </td>
                    <td>
                      <button className="action-btn print">Print</button>
                      <button className="action-btn view">View</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="doctor-info">
                        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"} alt="Dr. Paolo Dybala" />
                        <div>
                          <strong>Dr. Paolo Dybala</strong>
                          <span>Dermatology</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      20 Oct 2020
                      <br />
                      10:30AM
                    </td>
                    <td>18 Oct, 2020</td>
                    <td>$150.00</td>
                    <td>
                      <span className="status confirm">Confirm</span>
                    </td>
                    <td>
                      <button className="action-btn print">Print</button>
                      <button className="action-btn view">View</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="doctor-info">
                        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"} alt="Dr. Samuel Daniels" />
                        <div>
                          <strong>Dr. Samuel Daniels</strong>
                          <span>ACL Surgeon</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      20 Oct 2020
                      <br />
                      10:30AM
                    </td>
                    <td>18 Oct, 2020</td>
                    <td>$150.00</td>
                    <td>
                      <span className="status cancelled">Cancelled</span>
                    </td>
                    <td>
                      <button className="action-btn print">Print</button>
                      <button className="action-btn view">View</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="doctor-info">
                        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"} alt="Dr. Julia Jhones" />
                        <div>
                          <strong>Dr. Julia Jhones</strong>
                          <span>Dentist</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      20 Oct 2020
                      <br />
                      10:30AM
                    </td>
                    <td>18 Oct, 2020</td>
                    <td>$150.00</td>
                    <td>
                      <span className="status confirm">Confirm</span>
                    </td>
                    <td>
                      <button className="action-btn print">Print</button>
                      <button className="action-btn view">View</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="doctor-info">
                        <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"} alt="Dr. Mary Astor" />
                        <div>
                          <strong>Dr. Mary Astor</strong>
                          <span>Orthopedic</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      20 Oct 2020
                      <br />
                      10:30AM
                    </td>
                    <td>18 Oct, 2020</td>
                    <td>$150.00</td>
                    <td>
                      <span className="status pending">Pending</span>
                    </td>
                    <td>
                      <button className="action-btn print">Print</button>
                      <button className="action-btn view">View</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* Mobile Table Card */}
          <div className="card-grid">
            <div className="dcard">
              <img
                src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"}
                alt="Doctor Photo"
                className="doctor-photo"
              />
              <div className="dcard-content">
                <h2 className="doctor-name">Dr. John Doe</h2>
                <p className="doctor-degree">MBBS, MD</p>
                <p className="appointment-date">
                  <strong>Appointment Date:</strong> July 15, 2024, 10:00 AM
                </p>
                <p className="booking-date">
                  <strong>Booking Date:</strong> July 10, 2024
                </p>
                <p className="amount">
                  <strong>Amount:</strong>
                  $100
                </p>
                <p className="table-status">
                  <strong>Status:</strong>
                  <span className="status confirm">Confirm</span>
                </p>
                <div className="main-btn-doctor">
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Print</span>
                    </button>
                  </div>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>View</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="dcard">
              <img
                src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"}
                alt="Doctor Photo"
                className="doctor-photo"
              />
              <div className="dcard-content">
                <h2 className="doctor-name">Dr. John Doe</h2>
                <p className="doctor-degree">MBBS, MD</p>
                <p className="appointment-date">
                  <strong>Appointment Date:</strong> July 15, 2024, 10:00 AM
                </p>
                <p className="booking-date">
                  <strong>Booking Date:</strong> July 10, 2024
                </p>
                <p className="amount">
                  <strong>Amount:</strong>
                  $100
                </p>
                <p className="table-status">
                  <strong>Status:</strong>
                  <span className="status confirm">Confirm</span>
                </p>
                <div className="main-btn-doctor">
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Print</span>
                    </button>
                  </div>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>View</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="dcard">
              <img
                src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"}
                alt="Doctor Photo"
                className="doctor-photo"
              />
              <div className="dcard-content">
                <h2 className="doctor-name">Dr. John Doe</h2>
                <p className="doctor-degree">MBBS, MD</p>
                <p className="appointment-date">
                  <strong>Appointment Date:</strong> July 15, 2024, 10:00 AM
                </p>
                <p className="booking-date">
                  <strong>Booking Date:</strong> July 10, 2024
                </p>
                <p className="amount">
                  <strong>Amount:</strong>
                  $100
                </p>
                <p className="table-status">
                  <strong>Status:</strong>
                  <span className="status confirm">Confirm</span>
                </p>
                <div className="main-btn-doctor">
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Print</span>
                    </button>
                  </div>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>View</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="dcard">
              <img
                src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"}
                alt="Doctor Photo"
                className="doctor-photo"
              />
              <div className="dcard-content">
                <h2 className="doctor-name">Dr. John Doe</h2>
                <p className="doctor-degree">MBBS, MD</p>
                <p className="appointment-date">
                  <strong>Appointment Date:</strong> July 15, 2024, 10:00 AM
                </p>
                <p className="booking-date">
                  <strong>Booking Date:</strong> July 10, 2024
                </p>
                <p className="amount">
                  <strong>Amount:</strong>
                  $100
                </p>
                <p className="table-status">
                  <strong>Status:</strong>
                  <span className="status confirm">Confirm</span>
                </p>
                <div className="main-btn-doctor">
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Print</span>
                    </button>
                  </div>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>View</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="dcard">
              <img
                src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"}
                alt="Doctor Photo"
                className="doctor-photo"
              />
              <div className="dcard-content">
                <h2 className="doctor-name">Dr. John Doe</h2>
                <p className="doctor-degree">MBBS, MD</p>
                <p className="appointment-date">
                  <strong>Appointment Date:</strong> July 15, 2024, 10:00 AM
                </p>
                <p className="booking-date">
                  <strong>Booking Date:</strong> July 10, 2024
                </p>
                <p className="amount">
                  <strong>Amount:</strong>
                  $100
                </p>
                <p className="table-status">
                  <strong>Status:</strong>
                  <span className="status confirm">Confirm</span>
                </p>
                <div className="main-btn-doctor">
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Print</span>
                    </button>
                  </div>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>View</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : (activeTab === "tab2" ?
        <div id="tab2" className={activeTab === "tab2" ? "content-tab active" : ""}>
          <div className="fcards-container" id="fcards-container">
            {/* Card Example */}
            <div className="fcard">
              <div className="image-container">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-1.png"} alt="Doctor Image" />
                <button className="favorite-btn" aria-label="Favorite">
                  <svg
                    viewBox="0 0 25 18"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    className="feather feather-heart"
                  >
                    <path d="M20.8 4.6c-1.6-1.6-4.2-1.6-5.8 0l-1 1-1-1c-1.6-1.6-4.2-1.6-5.8 0-1.6 1.6-1.6 4.2 0 5.8l6.8 6.8 6.8-6.8c1.6-1.6 1.6-4.2 0-5.8z" />
                  </svg>
                </button>
              </div>
              <div className="content">
                <h3>Dr. Mary Astor</h3>
                <p>MDS - Periodontology and BDS</p>
                <p>(28)</p>
                <p>G87P, Birmingham, UK</p>
                <div className="availability">
                  <p>24/7 Available</p>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Today</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="fcard">
              <div className="image-container">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-2.png"} alt="Doctor Image" />
                <button className="favorite-btn" aria-label="Favorite">
                  <svg
                    viewBox="0 0 25 18"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    className="feather feather-heart"
                  >
                    <path d="M20.8 4.6c-1.6-1.6-4.2-1.6-5.8 0l-1 1-1-1c-1.6-1.6-4.2-1.6-5.8 0-1.6 1.6-1.6 4.2 0 5.8l6.8 6.8 6.8-6.8c1.6-1.6 1.6-4.2 0-5.8z" />
                  </svg>
                </button>
              </div>
              <div className="content">
                <h3>Dr. Mary Astor</h3>
                <p>MDS - Periodontology and BDS</p>
                <p>(28)</p>
                <p>G87P, Birmingham, UK</p>
                <div className="availability">
                  <p>24/7 Available</p>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Today</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="fcard">
              <div className="image-container">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"} alt="Doctor Image" />
                <button className="favorite-btn" aria-label="Favorite">
                  <svg
                    viewBox="0 0 25 18"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    className="feather feather-heart"
                  >
                    <path d="M20.8 4.6c-1.6-1.6-4.2-1.6-5.8 0l-1 1-1-1c-1.6-1.6-4.2-1.6-5.8 0-1.6 1.6-1.6 4.2 0 5.8l6.8 6.8 6.8-6.8c1.6-1.6 1.6-4.2 0-5.8z" />
                  </svg>
                </button>
              </div>
              <div className="content">
                <h3>Dr. Mary Astor</h3>
                <p>MDS - Periodontology and BDS</p>
                <p>(28)</p>
                <p>G87P, Birmingham, UK</p>
                <div className="availability">
                  <p>24/7 Available</p>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Today</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="fcard">
              <div className="image-container">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-1.png"} alt="Doctor Image" />
                <button className="favorite-btn" aria-label="Favorite">
                  <svg
                    viewBox="0 0 25 18"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    className="feather feather-heart"
                  >
                    <path d="M20.8 4.6c-1.6-1.6-4.2-1.6-5.8 0l-1 1-1-1c-1.6-1.6-4.2-1.6-5.8 0-1.6 1.6-1.6 4.2 0 5.8l6.8 6.8 6.8-6.8c1.6-1.6 1.6-4.2 0-5.8z" />
                  </svg>
                </button>
              </div>
              <div className="content">
                <h3>Dr. Mary Astor</h3>
                <p>MDS - Periodontology and BDS</p>
                <p>(28)</p>
                <p>G87P, Birmingham, UK</p>
                <div className="availability">
                  <p>24/7 Available</p>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Today</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="fcard">
              <div className="image-container">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-1.png"} alt="Doctor Image" />
                <button className="favorite-btn" aria-label="Favorite">
                  <svg
                    viewBox="0 0 25 18"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    className="feather feather-heart"
                  >
                    <path d="M20.8 4.6c-1.6-1.6-4.2-1.6-5.8 0l-1 1-1-1c-1.6-1.6-4.2-1.6-5.8 0-1.6 1.6-1.6 4.2 0 5.8l6.8 6.8 6.8-6.8c1.6-1.6 1.6-4.2 0-5.8z" />
                  </svg>
                </button>
              </div>
              <div className="content">
                <h3>Dr. Mary Astor</h3>
                <p>MDS - Periodontology and BDS</p>
                <p>(28)</p>
                <p>G87P, Birmingham, UK</p>
                <div className="availability">
                  <p>24/7 Available</p>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Today</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="fcard">
              <div className="image-container">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-2.png"} alt="Doctor Image" />
                <button className="favorite-btn" aria-label="Favorite">
                  <svg
                    viewBox="0 0 25 18"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    className="feather feather-heart"
                  >
                    <path d="M20.8 4.6c-1.6-1.6-4.2-1.6-5.8 0l-1 1-1-1c-1.6-1.6-4.2-1.6-5.8 0-1.6 1.6-1.6 4.2 0 5.8l6.8 6.8 6.8-6.8c1.6-1.6 1.6-4.2 0-5.8z" />
                  </svg>
                </button>
              </div>
              <div className="content">
                <h3>Dr. Mary Astor</h3>
                <p>MDS - Periodontology and BDS</p>
                <p>(28)</p>
                <p>G87P, Birmingham, UK</p>
                <div className="availability">
                  <p>24/7 Available</p>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Today</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="fcard">
              <div className="image-container">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-3.png"} alt="Doctor Image" />
                <button className="favorite-btn" aria-label="Favorite">
                  <svg
                    viewBox="0 0 25 18"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    className="feather feather-heart"
                  >
                    <path d="M20.8 4.6c-1.6-1.6-4.2-1.6-5.8 0l-1 1-1-1c-1.6-1.6-4.2-1.6-5.8 0-1.6 1.6-1.6 4.2 0 5.8l6.8 6.8 6.8-6.8c1.6-1.6 1.6-4.2 0-5.8z" />
                  </svg>
                </button>
              </div>
              <div className="content">
                <h3>Dr. Mary Astor</h3>
                <p>MDS - Periodontology and BDS</p>
                <p>(28)</p>
                <p>G87P, Birmingham, UK</p>
                <div className="availability">
                  <p>24/7 Available</p>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Today</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="fcard">
              <div className="image-container">
                <img src={process.env.REACT_APP_BASE_URL1 + "/assets/imgs/dp-1.png"} alt="Doctor Image" />
                <button className="favorite-btn" aria-label="Favorite">
                  <svg
                    viewBox="0 0 25 18"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    className="feather feather-heart"
                  >
                    <path d="M20.8 4.6c-1.6-1.6-4.2-1.6-5.8 0l-1 1-1-1c-1.6-1.6-4.2-1.6-5.8 0-1.6 1.6-1.6 4.2 0 5.8l6.8 6.8 6.8-6.8c1.6-1.6 1.6-4.2 0-5.8z" />
                  </svg>
                </button>
              </div>
              <div className="content">
                <h3>Dr. Mary Astor</h3>
                <p>MDS - Periodontology and BDS</p>
                <p>(28)</p>
                <p>G87P, Birmingham, UK</p>
                <div className="availability">
                  <p>24/7 Available</p>
                  <div className="cbtn-holder">
                    <button className="cbtn btn-3 hover-border-4">
                      <span>Today</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Repeat the card structure for more cards */}
          </div>
          <div className="pagination">
            <button className="prev">Prev</button>
            <button className="next">Next</button>
          </div>
        </div>
        : ( activeTab === "tab3"?
        <div id="schedule-timing" className={activeTab === "tab3" ? "content-tab active" : ""}>
          <h1>Schedule Timing</h1>
          {/* Schedule Timing content here */}
        </div>
        : ( activeTab === "tab4"?
        <div id="profile" className={activeTab === "tab4" ? "content-tab active" : ""}>
          <div className="info-box">
            <div className="profile-container">
              <img
                id="profile-image"
                src="https://via.placeholder.com/150"
                alt="Profile Image"
              />
              <button id="change-image-btn" aria-label="Change Image">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-camera"
                >
                  <path d="M23 19a2 2 0 0 0 2-2v-11a2 2 0 0 0-2-2h-5l-1-1h-6l-1 1h-5a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h18zm-18-14h18v11h-18v-11zm11 5l-3 3l-2-2l-3 3h12l-3-3zm-7 4l2 2h-8v-2z" />
                </svg>
              </button>
              <input
                type="file"
                id="file-input"
                accept="image/*"
                style={{ display: "none" }}
              />
            </div>
            <div className="info-content">
              <h2>Basic Information</h2>
              <p>Name: John Doe</p>
              <p>Email: johndoe@example.com</p>
              <p>Phone: +1234567890</p>
            </div>
          </div>
          <div>
            <div className="details-box">
              <h2>Personal Details</h2>
              <form>
                <div className="input-field">
                  <div className="input-group">
                    <label htmlFor="first-name">First Name</label>
                    <input
                      type="text"
                      id="first-name"
                      placeholder="First Name"
                      name="first-name"
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="last-name">Last Name</label>
                    <input
                      type="text"
                      id="last-name"
                      placeholder="Last Name"
                      name="last-name"
                    />
                  </div>
                </div>
                <div className="input-field">
                  <div className="input-group">
                    <label htmlFor="dob">Date of Birth</label>
                    <input
                      type="date"
                      placeholder="Date Of Birth"
                      id="dob"
                      name="dob"
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="gender">Gender</label>
                    <select id="gender" name="gender">
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="input-field">
                  <div className="input-group">
                    <label htmlFor="age">Age</label>
                    <input type="text" id="age" placeholder="Age" name="age" />
                  </div>
                  <div className="input-group">
                    <label htmlFor="Mobile">Mobile Number</label>
                    <input type="text" id="Mobile Number" name="Mobile Number" />
                  </div>
                </div>
                <div className="input-field">
                  <div className="input-group">
                    <label htmlFor="address">Address</label>
                    <input
                      type="text"
                      id="address"
                      placeholder="Address"
                      name="address"
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="Blood Group">Blood Group</label>
                    <input
                      placeholder="Blood Group"
                      type="text"
                      id="Blood Group"
                      name="Blood Group"
                    />
                  </div>
                </div>
                <div className="single-input-field">
                  <div className="input-group">
                    <label htmlFor="Marital Status">Marital Status</label>
                    <input
                      type="text"
                      id="Marital Status"
                      placeholder="Marital Status"
                      name="Marital Status"
                    />
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="notes">Note to the Doctor (Optional)</label>
                  <textarea
                    id="notes"
                    placeholder="Write Your Notes...."
                    name="notes"
                    defaultValue={""}
                  />
                </div>
              </form>
            </div>
            <div className="social-box">
              <h2>Social Details</h2>
              <form>
                <div className="input-field">
                  <div className="input-group">
                    <label htmlFor="FaceBook">FaceBook URL</label>
                    <input type="text" id="FaceBook" name="FaceBook" />
                  </div>
                  <div className="input-group">
                    <label htmlFor="Google">Google Plus URL</label>
                    <input type="text" id="Google" name="Google" />
                  </div>
                </div>
                <div className="input-field">
                  <div className="input-group">
                    <label htmlFor="Twitter">Twitter URL</label>
                    <input type="text" id="Twitter" name="Twitter" />
                  </div>
                  <div className="input-group">
                    <label htmlFor="Instagram">Instagram URL</label>
                    <input type="text" id="Instagram" name="Instagram" />
                  </div>
                </div>
              </form>
            </div>
            <div className="main-btn">
              <div className="cbtn-holder">
                <button className="cbtn btn-3 hover-border-4">
                  <span>Save Change</span>
                </button>
              </div>
              <div className="cbtn-holder">
                <button className="cbtn btn-3 hover-border-4">
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        : 
        <div id="order" className={activeTab === "tab5" ? "content-tab active" : ""}>
          <div className="main-order-card">
          {
            
            order.map((order1,index) => {
              if(register_id !==0)
                {
                  return(
            <div className="order-card">
              <div className="order-card-image">
                <h3>{order1.product_name}</h3>
              </div>
              <div className="main-order-image-card">
                <img src={order1.pi_name} alt="Product Image" />
                <div className="order-card-details">
                  <p>Qty: {order1.pro_product_qty}</p>
                  <p>Price: ₹{order1.product_price * order1.pro_product_qty}</p>
                </div>
              </div>
              <div className="col-12 col-md-10 hh-grayBox pt45 pb20">
                <div className="row justify-content-between main-tracking-list">
                  <div className="order-tracking completed">
                    <span className="is-complete" />
                    <p>
                      Ordered
                      <br />
                      <span>Mon, June 24</span>
                    </p>
                  </div>
                  <div className="order-tracking ">
                    <span className="is-complete" />
                    <p>
                      Shipped
                      <br />
                      <span>Tue, June 25</span>
                    </p>
                  </div>
                  <div className="order-tracking">
                    <span className="is-complete" />
                    <p>
                      Delivered
                      <br />
                      <span>Fri, June 28</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
                  )
                }
            
              })} 
                
          </div>
          <div className="order-card1">
            <span className="payment-summery">Payment Summery</span>
            <div>
              <div className="subtotal">
                <span>
                  Subtotal <p>(2 items)</p>
                </span>
                <span>₹714</span>
              </div>
              <div className="discount">
                <span>Discount</span>
                <span>₹0</span>
              </div>
              <div className="tax">
                <span>Tax % </span>
                <span>0%</span>
              </div>
              <div className="total">
                <span>Total Paid By Customer</span>
                <span>₹714</span>
              </div>
            </div>
          </div>
          <div id="change-password" className="content-tab">
            <div className="change-password-tab">
              <div className="tab-title">
                <h1>Change Password</h1>
              </div>
              <form>
                <div className="tab-content">
                  <div className="form-group">
                    <label htmlFor="new-password">New Password</label>
                    <input
                      type="password"
                      id="new-password"
                      name="new-password"
                      required=""
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input
                      type="password"
                      id="confirm-password"
                      name="confirm-password"
                      required=""
                    />
                  </div>
                  <div className="main-btn">
                    <div className="cbtn-holder">
                      <button className="cbtn btn-3 hover-border-4">
                        <span>Save Change</span>
                      </button>
                    </div>
                    <div className="cbtn-holder">
                      <button className="cbtn btn-3 hover-border-4">
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* Change Password content here */}
          </div>
          <div id="logout" className="content-tab">
            <h1>Logout</h1>
            {/* Logout content here */}
          </div>
        </div>
      )
    )
  )
    }
      </div>
    </div>
  </div>
  
	   <Footer />
    </>
  );
};
