import React, { useState } from 'react';
const Paginate = ({ postsPerPage, totalPosts,paginate }) => {
   const pageNumbers = [];
 
   for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
      pageNumbers.push(i);
   }
 const [activePage,setActivePage]=useState(1);
 const handlePageClick=(number)=>
 {
   setActivePage(number);
   paginate(number);
 }
   return (
      <div>
         <nav>
         <ul className="pagination">
         <li>
                <button
                    onClick={() => {
                        if (pageNumbers.includes(activePage - 1)) {
                           setActivePage(activePage - 1);
                            paginate(activePage - 1);
                        }
                    }}
                    className="your-custom-styles">
                    Previous
                </button>
            </li>
            {pageNumbers.map((number) => (
               <li key={number} className={`page-item ${activePage === number ? "active":""}`}>
                 <button className='page-link' onClick={()=>{handlePageClick(number);paginate(number);}}>{number}</button> 
               </li>
            ))}
            <li>
                <button
                    onClick={() => {
                        if (pageNumbers.includes(activePage + 1)) {
                           setActivePage(activePage + 1);
                            paginate(activePage + 1);
                        }
                    }}
                    className="your-custom-styles">
                    Next
                </button>
            </li>
         </ul>
      </nav>
      </div>
   );
};
 
export default Paginate;


